import { handleActions } from 'redux-actions';

import * as actions from './actions';

export default handleActions(
  {
    [actions.showBusyIndicator]: () => true,
    [actions.hideBusyIndicator]: () => false
  },
  false
);
