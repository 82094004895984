import React from 'react';
import { importRetry } from '../utils';

const DateBlockView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './date-block/date-block')))
const RepresentativeBypassDateBlockView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './date-block/representative-bypass-date-block')))

const ContactsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './contacts/contacts')))
const ContactNewView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './contacts/contact-new')))

const ClientView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './clients/client')))
const ClientNewView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './clients/client-new')))
const ClientsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './clients/clients')))

const ClientAdminView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './client-admins/client-admin')))
const ClientAdminNewView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './client-admins/client-admin-new')))
const ClientAdminsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './client-admins/client-admins')))

const AdminView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './admins/admin')))
const AdminNewView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './admins/admin-new')))
const AdminsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './admins/admins')))

const ProjectView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './projects/project')))
const ProjectImportView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './projects/project-import')))
const ProjectsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './projects/projects')))
const ProjectCampaignsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './projects/project-campaigns')))
const ProjectCampaignsNewView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './projects/project-campaigns-new')))
const ProjectNewView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './projects/project-new')))
const ProjectExportView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './projects/project-export')))
const ProjectContactLogView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './projects/project-contact-log')))

const CampaignView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './campaigns/campaign')))

const RepresentativesView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './representatives/representatives')))
const RepresentativeNewView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './representatives/representative-new')))
const RepresentativeEditView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './representatives/representative')))

const BookersView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './bookers/bookers')))
const BookerNewView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './bookers/booker-new')))
const BookerEditView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './bookers/booker')))

const BookingBooker = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './booking-booker')))
const BookingView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './booking')))
const CallView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './call')))
const CallNextView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './call-next')))
const ContactView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './contact')))
const CopyBookingView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './copy-booking')))
const CopyBookingCalendarView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './copy-booking-calendar')))
const MoveBookingView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './move-booking')))
const MoveBookingCalendarView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './move-booking-calendar')))
const EditEventView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './edit-event')))
const EventView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './event')))
const ReportsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './reports')))
const RepresentativeView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './representative')))
const RescheduleView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './reschedule')))
const SuggestionsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './suggestions')))
const CalendarsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './calendars')))
const RepresentativeCalendarView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './representative-calendar')))
const ContactSuggestionsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './contact-suggestions')))
const RemoveContactsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './remove-contacts')))
const SmsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './sms')))
const NoShowFiltersView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './noshow-filters')))
const ConfirmationFiltersView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './confirmation-filters')))
const ProposedView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './proposed')))
const TermsOfUseView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './terms-of-use')))

const BlocklistView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './blocklist')))

const CallHistoryView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './call-history')))
const CallHistoryPopupView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './call-history-popup')))

const ReassignView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './reassign')))

const PfizerView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './pfizer/pfizer')))
const PfizerSmsView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './pfizer/pfizer-sms')))

const BackgroundProcessLogView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './background-process-log')))
const BusinessBoosterCallView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './business-booster/business-booster-call')))
const BusinessDbView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './business-db')))

const ApiAccessView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './api-access')))

const NotificationView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './notification')))

const AdminPanelView = React.lazy(() => importRetry(() => import(/* webpackChunkName: "private" */ './admin-panel')))

export {
  BookingBooker,
  BookingView,
  CallView,
  ContactView,
  ContactsView,
  CopyBookingView,
  CopyBookingCalendarView,
  MoveBookingView,
  MoveBookingCalendarView,
  EditEventView,
  EventView,
  ReportsView,
  RepresentativeView,
  RescheduleView,
  SuggestionsView,
  ClientAdminView,
  ClientAdminNewView,
  ClientAdminsView,
  ProjectView,
  ProjectImportView,
  ProjectsView,
  ProjectCampaignsView,
  ProjectCampaignsNewView,
  ProjectNewView,
  ProjectExportView,
  CampaignView,
  RepresentativesView,
  RepresentativeNewView,
  RepresentativeEditView,
  BookersView,
  BookerNewView,
  BookerEditView,
//  AdminView,
  ClientView,
  ClientNewView,
  ClientsView,
  CalendarsView,
  RepresentativeCalendarView,
  ContactSuggestionsView,
  ContactNewView,
  RemoveContactsView,
  ProjectContactLogView,
  DateBlockView,
  RepresentativeBypassDateBlockView,
  SmsView,
  NoShowFiltersView,
  ConfirmationFiltersView,
  ProposedView,
  TermsOfUseView,
  AdminNewView,
  AdminView,
  AdminsView,
  BlocklistView,
  CallHistoryView,
  CallHistoryPopupView,
  ReassignView,
  PfizerView,
  PfizerSmsView,
  BackgroundProcessLogView,
  BusinessBoosterCallView,
  BusinessDbView,
  ApiAccessView,
  NotificationView,
  CallNextView,
  AdminPanelView
};
