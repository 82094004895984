import { handleAction } from 'redux-actions';
import { combineReducers } from 'redux';

import * as actions from './actions';

const notifications = handleAction(
  actions.gotNotifications,
  (state, action) => action.payload,
  []
);

const setNotifications = handleAction(
  actions.setNotificationsDone,
  (state, action) => action.payload,
  []
);

export default combineReducers({
  notifications,
  setNotifications
});