import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as userActions from '../../redux/user/actions';
import { isMfaCodeValid, isPhoneNumberValid } from '../../utils';

import { ErrorBanner } from '../../components';
import BookersLogo from '../../assets/images/bookers.png';
import ElfGroupLogo from '../../assets/images/elfgroup_cybersafe_solution.min.svg';

import { Button } from 'react-bootstrap';

const elf_style = {
  width: "100px",
  height: "100px",
  padding: "0px",
}



class MfaCodeView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      phoneNumberConfirmed: false,
      code: '',
      phoneNumber: props.user.phoneNumber
    }
  }

  componentDidMount() {
    if ( this.inputCode ) {
      this.inputCode.focus();
    }
  }

  handleTextInput(prop, e){
    this.setState({[prop]:e.target.value})
  }

  handleKeyDown(event) {
    console.log(event.target.name)
    // Submit with Enter key
    if (event.keyCode === 13 ) {
      if ( event.target.name === 'phoneNumber' && isPhoneNumberValid(this.state.phoneNumber) ) {
        this.handleClickConfirm();
      }
      if ( event.target.name === 'code' && isMfaCodeValid(this.state.code) ) {
        this.handleClickLogin();
      }
    }
  }

  handleClickConfirm() {
    this.setState({phoneNumberConfirmed: true})
    this.props.sendNewMfaCode(
      this.props.userId,
      this.state.phoneNumber,
      true
    )
  }

  handleClickLogin() {
    this.props.verifyMfaCode(
      this.props.userId, 
      this.state.code
    )
  }

  render() {
    const propsPhoneNumberConfirmed = this.props.user.phoneNumberConfirmed || !!this.props.user.phoneNumberConfirmedAt
    const phoneNumberInputStyle = this.state.phoneNumber && this.state.phoneNumber.length 
      ? { borderColor: isPhoneNumberValid(this.state.phoneNumber) ? '#0a0' : 'red' } : {}
    const mfaCodeInputStyle = this.state.code && this.state.code.length 
      ? { borderColor: isMfaCodeValid(this.state.code) ? '#0a0' : 'red' } : {}
    return (
      <div className="login">
        <ErrorBanner />
        <div style = {{textAlign:'center'}}>
          <div>
            <img src={BookersLogo} alt="Bookers" className="logo" /> <br />
          </div>
          
          <div>
            <img src={ElfGroupLogo} alt="ElfGroup" style={elf_style}/>
          </div>
        </div>
        
        <div className="form-container">
          <div className="form" style={{height: 'auto', paddingBottom: 20 }}>
            { ( !propsPhoneNumberConfirmed && !this.state.phoneNumberConfirmed ) && <>
              <div className="heading" style={{ marginBottom: 10 }}>{ this.props.t('mfaCode.phoneAuthentication') }</div>
              <div className="form-group-collection">
                <div className="form-group">
                  <div style={{ marginBottom: 13 }}>
                    { this.props.t('mfaCode.phoneNumberConfirmation') }
                  </div>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    style={phoneNumberInputStyle}
                    onChange={e => this.setState({ phoneNumber: e.target.value })}
                    onKeyDown={e => this.handleKeyDown(e)}
                    placeholder={this.props.t('phoneNumber')}
                    ref={el => (this.inputCode = el)}
                  />
                </div>
              </div>
              <Button disabled={!isPhoneNumberValid(this.state.phoneNumber)} onClick={() => this.handleClickConfirm()}>
                {this.props.t('confirm')}
              </Button>
              <div style={{marginTop: 20}}>
                <a
                  href="/code"
                  style={{cursor: "pointer"}}
                  onClick={e => {
                    this.props.logout()
                    e.preventDefault()
                  }}
                >
                  { this.props.t('cancel') }
                </a>
              </div>
            </>}
            { ( propsPhoneNumberConfirmed || this.state.phoneNumberConfirmed ) && <>
              <div className="heading" style={{ marginBottom: 10 }}>{ this.props.t('mfaCode.phoneAuthentication') }</div>
              <div className="form-group-collection">
                <div className="form-group">
                  <div style={{ marginBottom: 13 }}>
                  { this.props.t('mfaCode.codeHasBeenSent') }
                  </div>
                  <input
                    type="text"
                    name="code"
                    value={this.state.code}
                    style={mfaCodeInputStyle}
                    onChange={e => this.setState({ code: e.target.value })}
                    onKeyDown={e => this.handleKeyDown(e)}
                    placeholder={this.props.t('confirmationCode')}
                    ref={el => (this.inputCode = el)}
                  />
                </div>
              </div>
              <Button disabled={!isMfaCodeValid(this.state.code)} onClick={() => this.handleClickLogin()}>
                {this.props.t('mfaCode.authenticate')}
              </Button>
              <div style={{marginTop: 20}}>
                <a
                  href="/code"
                  style={{cursor: "pointer", display: 'block', marginBottom: 5}}
                  onClick={e => {
                    this.props.sendNewMfaCode(this.props.userId)
                    e.preventDefault()
                  }}
                >
                  { this.props.t('mfaCode.sendNewCode') }
                </a>
                <a
                  href="/code"
                  style={{cursor: "pointer"}}
                  onClick={e => {
                    if ( !this.props.user.phoneNumberConfirmed ) {
                      this.setState({phoneNumberConfirmed: false})
                    } else {
                      this.props.logout()
                    }
                    e.preventDefault()
                  }}
                >
                  { this.props.t('cancel') }
                </a>
              </div>
            </>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    userId: state.user.id,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
  redirect: url => {
    dispatch(push(url));
  },
  verifyMfaCode: (userId, code) => {
    dispatch(userActions.verifyMfaCode({userId, code}));
  },
  sendNewMfaCode: (userId, phoneNumber=null, suppressAlert=false) => {
    dispatch(userActions.sendNewMfaCode({userId, phoneNumber, suppressAlert}));
  },
  logout: () => {
    dispatch(userActions.logout());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(MfaCodeView)
);
