import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const resolved = handleAction(
  actions.resetPasswordResolved,
  (state, action) => action.payload || state,
  []
);

const resetPasswordDone = handleAction(
  actions.resetPasswordDone,
  (state, action) => action.payload || state,
  []
);

const resetPasswordSent = handleAction(
  actions.resetPasswordSent,
  (state, action) => action.payload || state,
  []
);

export default combineReducers({
  resolved,
  resetPasswordDone,
  resetPasswordSent
});
