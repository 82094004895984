import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Glyphicon,
  Modal,
  Alert
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { 
  BookingStatus, 
  BookingFollowUpStatus, 
  ConfirmationCall, 
  ContactInfo,
  BookingParticipants
} from '../../components';
import * as bookingActions from '../../redux/booking/actions';
import * as eventActions from '../../redux/events/actions';
import * as contactActions from '../../redux/contacts/actions';
import * as userActions from '../../redux/user/actions';
import * as loginRedirectActions from '../../redux/loginRedirect/actions'
import { BOOKING_TYPES } from '../../consts';

import SokLogo from '../../assets/images/S_logo.min.svg'

export class BookingRepresentative extends Component {
  constructor(props) {
    super(props);
    this.narrowLimit = 667;

    if ( props.booking ) {
      if ( props.booking.id != null && this.isBookingAssignedToMe() ) {
        props.getBookingStatuses(props.booking.id);
      }
      if ( props.booking.contactId ) {
        props.getContact(props.booking.contactId);
      }  
    }

    this.state = {
      currentDay: this.getCurrentDay(),
      notes: '',
      shortNote: '',
      showModal: false,
      selectedAction: 'followUp',
      width: window.innerWidth
    };
  }

  canEdit() {
    return !(this.props.user.clientAdmin && this.props.user.clientAdmin.readOnly)
  }

  isBookingAssignedToMe(props=null) {
    props = props || this.props
    return props.user.type !== 'representative' 
      || props.booking.representativeId === props.user.representative.id
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    // Update available statuses if booking or its status changes
    if (
      (this.props.booking == null && nextProps.booking != null) ||
      (nextProps.booking != null &&
        (nextProps.booking.id !== this.props.booking.id ||
          nextProps.booking.statusId !== this.props.booking.statusId))
    ) {
      this.props.getBookingStatuses(nextProps.booking.id);
      this.props.getContact(nextProps.booking.contactId, 
        this.props.booking 
          && this.props.booking.id === nextProps.booking.id
          && this.props.booking.statusId !== nextProps.booking.statusId
      );
    }
    if ( nextProps.booking != null ) {
      this.setState({
        shortNote: nextProps.booking.event
          ? nextProps.booking.event.shortNote
          : '',
        notes: nextProps.booking.notes
      });
    }
  };

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  closeModal = answered => {
    const deleteBooking = this.state.selectedAction === 'delete';
    const mobile = this.state.width < this.narrowLimit;
    const followUp = this.state.selectedAction === 'followUp';

    if (answered === true) {
      if ( deleteBooking ) {
        this.props.deleteBooking(this.props.booking.id);
        if ( this.props.user.representative ) {
          this.props.redirect(`/representative`);
        } else {
          this.props.redirect(`/representative-calendar/${ this.props.booking.representativeId }`);
        }
      } else if (mobile && followUp) {
        const { startsAt, endsAt } = this.props.booking
        this.props.saveFollowUpInfo(startsAt, endsAt);
        this.props.redirect(`/bookings/${this.props.booking.id}/copy`);
      }
      else if (followUp) {
        this.props.redirect(`/bookings/${this.props.booking.id}/copy/calendar`);
      }/*
      else if (mobile && !followUp) {
        const { startsAt, endsAt, representativeId } = this.props.booking
        this.props.saveMoveInfo(startsAt, endsAt, representativeId);
        this.props.redirect(`/bookings/${this.props.booking.id}/move`);
      }*/
      else if (!followUp) {
        this.props.redirect(`/bookings/${this.props.booking.id}/move/calendar`);
      }
    }

    this.setState({ showModal: false });
  };

  getCurrentDay = () => {
    if (this.props.booking !== null) {
      return moment(this.props.booking.startsAt).format('dddd, D.M.');
    }
    return null;
  };

  getSelectedStatusName = () => {
    let selectedStatus = undefined;

    if (
      this.props.statuses !== undefined &&
      this.props.statuses.length > 0 &&
      this.props.booking.statusId !== undefined
    ) {
      selectedStatus = this.props.statuses.find(
        status => status.id === this.props.booking.statusId
      );

      if (selectedStatus !== undefined) {
        return selectedStatus.name;
      }
      return '';
    }

    return '';
  };

  handleChangeStatus = statusId => {
    this.props.setBookingStatus(this.props.booking.id, statusId);
  };

  handleClickCancel = () => {
    this.setState({ showCancelBookingModal: true });
  };

  handleBlurNotes = notes => {
    this.props.saveBooking(
      this.props.booking.id,
      this.props.booking.contactId,
      notes,
      this.props.min
    );
  };

  handleChangeNotes = notes => {
    this.setState({
      notes
    });
  };

  handleChangeShortNote = shortNote => {
    this.setState({
      shortNote
    });
  };

  handleBlurShortNote = shortNote => {
    this.props.saveShortNote(
      this.props.booking.event.id,
      shortNote
    )
  };

  isDeletable = () => {
    return this.props.booking != null && 
      this.props.booking.status != null &&
      this.props.booking.status.superType === 'dealProposed' &&
      this.props.booking.parentId != null 
  }

  isAvailableForFollowUp = (strict=true) => {
    const { booking } = this.props;
    if ( booking == null || booking.status == null ) return false
    return (
      ( ( !strict && booking.followUp ) || booking.status.superType === 'dealProposed' )
      && booking.parentId === null
      && (!strict || !booking.followUp)
    );
  };

  isParent = () => {
    const { booking } = this.props;
    return booking != null 
      && booking.followUp 
      && booking.followUp.startsAt !== booking.startsAt
      && booking.followUp.endsAt !== booking.endsAt
  };

  isMovable = () => {
    const { booking } = this.props;
    if ( booking == null || booking.status == null ) return false
    return (
      booking.status.superType !== 'deal'
      && booking.status.superType !== 'noDeal'
      && booking.status.superType !== 'cancelled'
      && (
        booking.status.superType !== 'dealProposed'
        || booking.parentId !== null
      )
      && 
      
      !(!this.getSelectedStatusRepresentativeCanChange() && !['admin', 'teamLeader'].includes(this.props.user.type))
      /*&& moment().diff(booking.startsAt, 'ms') < 0*/
    );
  };

  logoutAndClose() {
    this.props.clearState()
  }

  redirectToLogin() {
    this.props.setLoginRedirect(`/bookings/${this.props.booking.id}/_`)
    this.props.redirect(`/login/${this.props.user.email}`)
  }

  isMobileUserAgent(){
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  getSelectedStatusRepresentativeCanChange = () => {
    let selectedStatus = undefined;

    if ( !this.props.booking ) return false

    if (
      this.props.statuses !== undefined &&
      this.props.statuses.length > 0 &&
      this.props.booking.statusId !== undefined
    ) {
      selectedStatus = this.props.statuses.find(
        status => status.id === this.props.booking.statusId
      );

      if (selectedStatus !== undefined) {
        return selectedStatus.representativeCanChange
      }
      return true;
    }
  }

  render = () => {
    const {
      booking,
      t,
      goBack
    } = this.props;

    const {
      notes,
      shortNote,
      selectedAction,
      showModal
    } = this.state;

    if (booking !== null) {

      let typeLabel;
      const type = BOOKING_TYPES.find(type => type.id === booking.type)
      if ( type != null ) typeLabel = this.props.t(type.label)
      else typeLabel = ''

      return (
        <div className="row booking-representative">

          { !this.props.min &&
            <div className="navigation">
              <Button bsStyle="link" onClick={() => goBack()}>
                <Glyphicon glyph="chevron-left" />
                <span>{t('back')}</span>
              </Button>
              { this.canEdit() &&
              <div className="actions">
                {this.isDeletable() && (
                  <Button
                  style={{ paddingTop: 6, paddingBottom: 6 }}
                  bsStyle="danger"
                  onClick={() => this.setState({ selectedAction: 'delete', showModal: true })}
                >
                  <span>{t('booking.delete')}</span>
                </Button>
              )}
                {this.isMovable() && (
                  <Button
                    bsStyle="link"
                    onClick={() => this.setState({ selectedAction: 'move', showModal: true })}
                  >
                    <span>{t('booking.move')}</span>
                  </Button>
                )}
                {this.isAvailableForFollowUp() && (
                  <Button
                    bsStyle="link"
                    onClick={() => this.setState({ selectedAction: 'followUp', showModal: true })}
                  >
                    <span>{t('booking.copyAsFollowUp')}</span>
                  </Button>
                )}
              </div>
            }
            </div>
          }
          <div className="header">
            <div className="title" style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center'
            }}>
              { booking.contact && booking.contact.contactBusinessType.knownType === 'sok' &&
                <div style={{ display: 'inline-block', marginRight: 8 }}>
                  <img src={SokLogo} alt="SOK" style={{
                    width: 25,
                    height: 25,
                    borderRadius: '50%'
                  }}/>
                </div>
              }
                {booking.customerName}
            </div>
            <div className="info">
              {moment(booking.startsAt).format('dddd, D.M.YYYY')}
            </div>
            <div className="info">
              {moment(booking.startsAt).format('H:mm')} -{' '}
              {moment(booking.endsAt).format('H:mm')}
            </div>
            { this.props.booking != null &&
              <div className="info" style={{ paddingTop: 0, textTransform: 'none' }}>
                { this.props.t('booking.typeOfBooking') }: { typeLabel } <span className={`event-type-dot ${booking.type}`}/>
              </div>
            }
          </div>

          {!this.props.user.clientAdmin 
            && !booking.isTentative 
            && ( process.env.REACT_APP_DISABLE_CONFIRMATION_CALL_TOOL || !booking.bookerConfirmationCall )
            && moment(booking.startsAt).isAfter(moment()) && (
              <ConfirmationCall isMobile={ this.state.width < this.narrowLimit || this.isMobileUserAgent() }/>
            )}

          <div style={{ borderBottomWidth: this.props.min ? 0 : 1 }} className="details">
            <div className="status">
              { !this.props.min &&
                <div className="short-note">
                  <span>{ this.props.t('shortNote') }</span>
                  <div>
                    <input type="text" 
                      readOnly={!this.canEdit()}
                      value={shortNote || ''}
                      onBlur={event => this.handleBlurShortNote(event.target.value)}
                      onChange={event => this.handleChangeShortNote(event.target.value)}    
                    />
                  </div>
                </div>
              }
              <div className="status">
                <span>{t('realization')}</span>
                <BookingStatus 
                  representativeId={
                    this.props.user.representative ? 
                      this.props.user.representative.id 
                      : this.props.booking.representativeId
                    }
                    disabled={!this.canEdit()}
                />
                {
                  this.isAvailableForFollowUp(false) && !this.isParent() && 
                  <div style={{ marginTop: 10 }}>
                    <span>{t('followUpsRealization')}</span>
                    <BookingFollowUpStatus 
                      representativeId={
                        this.props.user.representative ? 
                          this.props.user.representative.id 
                          : this.props.booking.representativeId
                        }
                        disabled={!this.canEdit()}
                    />
                  </div>                  
                }
                { 
                  this.canEdit() && this.props.min && this.isAvailableForFollowUp() && (
                    <Button
                      bsStyle="link"
                      onClick={() => this.setState({ selectedAction: 'followUp', showModal: true })}
                    >
                      <span>{t('booking.copyAsFollowUp')}</span>
                    </Button>
                  )
                }
              </div>
            </div>
            <div className="notes">
              <span>{t('notes')}</span>
              <textarea
                readOnly={!this.canEdit()}
                onBlur={event => this.handleBlurNotes(event.target.value)}
                onChange={event => this.handleChangeNotes(event.target.value)}
                value={notes}
              />
            </div>
          </div>


          { !this.props.min &&
            <>
              {this.props.booking.project.customUserInterfaceId === 2 && <BookingParticipants />}
              <ContactInfo readOnly={ true } notesReadOnly={ !this.canEdit() }/>
            </>
          }

          { !this.props.min &&
            <div className="created">
              {t('createdAt')}
              {moment(booking.createdAt).format('D.M.YYYY H:mm')}
            </div>
          }

          { this.props.min &&
            <div className="created">
              <Alert>{this.props.t('loginToSeeMoreExplanation')} </Alert>
              <Button bsStyle="primary" onClick={() => this.redirectToLogin()}>
                { this.props.t('loginToSeeMoreButton') }
              </Button>{' '}
              <Button bsStyle="warning" onClick={() => this.logoutAndClose()}>
                { this.props.t('logoutAndClose') }
              </Button>
            </div>
          }

          <Modal show={showModal}>
            <Modal.Header closeButton={false}>
              <Modal.Title>
                {selectedAction === 'delete'
                  ? t('booking.deleteConfirmation') 
                  : ( selectedAction === 'followUp'
                    ? t('booking.copyAsFollowUpConfirmation')
                    : t('booking.moveConfirmation')
                  )
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button onClick={() => this.closeModal(true)}>
                {t('yes')}
              </Button>
              <Button onClick={() => this.closeModal(false)}>
                {t('no')}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return null;
    }
  };
}

BookingRepresentative.propTypes = {
  goBack: PropTypes.func,
  min: PropTypes.bool
};

const mapStateToProps = state => ({
  booking: state.booking.booking,
  contact: state.contacts.contact,
  statuses: state.booking.statuses,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  clearState: () => {
    dispatch(userActions.clearState({close: true}));
  },
  getBookingStatuses: bookingId => {
    dispatch(bookingActions.getBookingStatuses({ bookingId, all: true }));
  },
  getContact: (contactId, bypassReset) => {
    if ( !bypassReset ) {
      dispatch(contactActions.resetContact());
    }
    dispatch(contactActions.getContact({ contactId }));
  },
  redirect: url => {
    dispatch(push(url));
  },
  setLoginRedirect: url => {
    dispatch(loginRedirectActions.setLoginRedirect(url))
  },
  saveBooking: (bookingId, contactId, notes, dontGet) => {
    dispatch(
      bookingActions.saveBooking({
        bookingId,
        contactId,
        notes,
        dontGet
      })
    );
  },
  setBookingStatus: (bookingId, statusId) => {
    dispatch(bookingActions.setBookingStatus({ bookingId, statusId }));
  },
  saveMoveInfo: (startsAt, endsAt, representativeId) => {
    dispatch(
      bookingActions.saveMoveInfo({
        startsAt,
        endsAt,
        representativeId
      })
    );
  },
  saveFollowUpInfo: (startsAt, endsAt) => {
    dispatch(
      bookingActions.saveFollowUpInfo({
        startsAt,
        endsAt
      })
    );
  },
  saveShortNote: (eventId, shortNote) => {
    dispatch(
      eventActions.patchEvent({
        eventId,
        event: {shortNote}
      })
    );
  },
  deleteBooking: (bookingId) => {
    dispatch(
      bookingActions.deleteBooking({bookingId})
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(BookingRepresentative)
);