import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Glyphicon } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import { WEBSOCKET_API_URL } from '../../config';
import * as actions from '../../redux/socket/actions';

export class BottomBar extends Component {
  render() {
    return (
      <div className="bottom-bar">
        <div className="content">
          <div className={classNames('socket-indicator', this.props.socket)}>
            <Glyphicon glyph="signal" />
            {this.props.socket}
          </div>
          <div className="debug-buttons">
            <button
              onClick={() => {
                this.props.connect(this.props.user.token);
              }}
            >
              Connect
            </button>
            <button
              onClick={() => {
                this.props.disconnect();
              }}
            >
              Disconnect
            </button>
            <button
              onClick={() => {
                this.props.send('ping');
              }}
            >
              Ping
            </button>
            <button
              onClick={() => {
                this.props.send('testCalendarUpdate');
              }}
            >
              Cal upd
            </button>
            <button
              onClick={() => {
                this.props.send('testCallEnd');
              }}
            >
              Call end
            </button>
          </div>
        </div>
      </div>
    );
  }
}

BottomBar.propTypes = {
  socket: PropTypes.string,
  t: PropTypes.func
};

const mapStateToProps = state => ({
  socket: state.socket,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  connect: token => {
    const url = `${WEBSOCKET_API_URL}?Authorization=${token}`;
    dispatch(actions.connect({ url }));
  },
  disconnect: () => {
    dispatch(actions.disconnect());
  },
  send: type => {
    dispatch(actions.send({ type }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(BottomBar)
);
