import { createAction } from 'redux-actions';

export const getAvailableRepresentatives = createAction(
  'GET_AVAILABLE_REPRESENTATIVES'
);
export const gotAvailableRepresentatives = createAction(
  'GOT_AVAILABLE_REPRESENTATIVES'
);

export const getSuggestions = createAction('GET_SUGGESTIONS');
export const notGettingSuggestions = createAction('NOT_GETTING_SUGGESTIONS');
export const gotSuggestions = createAction('GOT_SUGGESTIONS');
export const resetSuggestions = createAction('RESET_SUGGESTIONS');

export const startCall = createAction('START_CALL');
export const startingCall = createAction('STARTING_CALL');
export const startedCall = createAction('STARTED_CALL');
export const cancelCall = createAction('CANCEL_CALL');

export const endCall = createAction('END_CALL');
export const endedCall = createAction('ENDED_CALL');
export const resetCall = createAction('RESET_CALL');
export const setCallId = createAction('SET_CALL_ID');

export const setSelectedClient = createAction('SET_SELECTED_CLIENT');
export const setSelectedCampaign = createAction('SET_SELECTED_CAMPAIGN');
export const setSelectedCampaigns = createAction('SET_SELECTED_CAMPAIGNS');
export const setSelectedProject = createAction('SET_SELECTED_PROJECT');
export const setSelectedRepresentatives = createAction(
  'SET_SELECTED_REPRESENTATIVES'
);
export const setSelectedServicePoint = createAction(
  'SET_SELECTED_SERVICE_POINT'
);
export const setSelectedPostalAreas = createAction('SET_SELECTED_POSTAL_AREAS');
export const setSelectedContactBusinessType = createAction('SET_SELECTED_CONTACT_BUSINESS_TYPE');
export const setSelectedLanguage = createAction('SET_SELECTED_LANGUAGE');

export const getCalls = createAction('GET_CALLS');
export const gotCalls = createAction('GOT_CALLS');
export const _gotCalls = createAction('_GOT_CALLS');
export const appendCalls = createAction('APPEND_CALLS');

export const getRecording = createAction('GET_RECORDING');

export const decrementCallDelayTimer = createAction('DECREMENT_CALL_DELAY_TIMER');
export const setCallDelayTimer = createAction('SET_CALL_DELAY_TIMER');
export const setCallDelayTimerTimeout = createAction('SET_CALL_DELAY_TIMER_TIMEOUT');

export const incrementCallDuration = createAction('INCREMENT_CALL_DURATION');
export const setCallDuration = createAction('SET_CALL_DURATION');
export const setCallDurationTimeout = createAction('SET_CALL_DURATION_TIMEOUT');
