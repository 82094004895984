import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as userActions from '../../redux/user/actions';
import * as resetPasswordActions from '../../redux/reset-password/actions';

import { ErrorBanner } from '../../components';

import BookersLogo from '../../assets/images/bookers.png';
import ElfGroupLogo from '../../assets/images/elfgroup_cybersafe_solution.min.svg';

const elf_style = {
  width: "100px",
  height: "100px",
  padding: "0px",
}

export class LoginView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetEmailOkDisabled: false,
      showModal: false,
      modalContent: () => null,
      modalOk: callback => callback(),
      email: this.props.match.params.email || "",
      password: '',
      modalOkText: null,
      unauthorized: false
    };
  }

  resetEmail = ''

  componentDidMount() {
    if ( this.props.match.path === '/login' ) {
      this.props.tokenLogin()
    }
    this.props.match.params.email
      ? this.inputPassword.focus()
      : this.inputEmail.focus();
  }

  handleClickLogin() {
    if (this.state.email.length > 0 && this.state.password.length > 0) {
      this.props.login(this.state.email, this.state.password);
    }
  }

  handleKeyDown(event) {
    if ( this.state.unauthorized ) {
      this.setState({unauthorized: false})
    }
    // Submit with Enter key
    if (event.keyCode === 13) {
      if ( this.state.showModal ) {
        this.state.modalOk(() => {
          this.setState({showModal:false})
        })
      } else this.handleClickLogin();
    }
  }

  handleForgotPasswordClick() {
    this.setState({
      unauthorized: false,
      resetEmailOkDisabled: false,
      resetEmail: this.state.email,
      modalOk: callback => {
        this.props.resetPasswordSend(this.state.resetEmail)
      },
      modalOkText: this.props.t('send'),
      modalContent: () => {
      return (
        <div>
          <div style={{ fontWeight: "bold", marginBottom: "15px", fontSize: "16px"}}>
            { this.props.t("login.forgotPassword") }
          </div>
          <div style={{ marginBottom: "15px", fontSize: "16px"}}>
            { this.props.t("login.emailForRecovery") }
          </div>
            <input
              disabled={this.state.resetEmailOkDisabled}
              style={{ width: "100%"}}
              type="text"
              value={this.state.resetEmail}
              onChange={e => {
                this.setState({resetEmail: e.target.value})
              }}
              onKeyDown={e => {
                this.handleKeyDown(e)
              }}
              autoFocus
              placeholder={this.props.t('login.email')}
            />
        </div>
      )},
      showModal: true
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.user !== nextProps.user ) {
      if ( nextProps.user 
        && nextProps.user.data 
        && nextProps.user.data.errorKey === 'userAccountNotReady' ) {
          this.setState({
            showModal: true,
            resetEmailOkDisabled: false,
            resetEmailNotFound: false,
            modalOk: callback => callback(),
            modalOkText: this.props.t('ok'),
            modalContent: () => (
              <div>
                <div style={{ fontSize: "16px"}}>
                  { this.props.t("login.userAccountNotReady") } <a href="mailto:bookerskalenteri@bookers.fi">bookerskalenteri@bookers.fi</a>.
                </div>
              </div>
            )
          })
      } else {
        this.setState({
          unauthorized: nextProps.user.status === 401
        })
      }
    }
    if ( this.props.resetPasswordSent !== nextProps.resetPasswordSent 
        && nextProps.resetPasswordSent.status === 200 ) {
      this.setState({
        showModal: true,
        resetEmailOkDisabled: false,
        modalOk: callback => callback(),
        modalOkText: this.props.t('ok'),
        modalContent: () => (
          <div>
            <div style={{fontSize: "16px", marginBottom: "15px"}}>
              {this.props.t("login.resetEmailSent")} <b>{ this.state.resetEmail }</b>{this.props.t("login.resetEmailSent2")}.
            </div>
            <div style={{ fontSize: "16px"}}>
              { this.props.t("login.expiresInFive") }
            </div>
          </div>
        )
      })
    }
  }

  render() {
    const unauthStyle = {
      color: 'red',
      borderColor: 'red',
      outlineColor: 'red'
    }
    return (
      <div className="login">
        <ErrorBanner />
        <div style = {{textAlign:'center'}}>
          <div>
            <img src={BookersLogo} alt="Bookers" className="logo" /> <br />
          </div>
          
          <div>
            <img src={ElfGroupLogo} alt="ElfGroup" style={elf_style}/>
          </div>
        </div>
        
        <div className="form-container">
          <div className="form">
            <div className="heading">{this.props.t('login.login')}</div>
            <div className="form-group-collection">
              <div className="form-group">
                <input
                  style={ this.state.unauthorized ? unauthStyle : {} }
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  onKeyDown={e => this.handleKeyDown(e)}
                  placeholder={this.props.t('login.email')}
                  ref={el => (this.inputEmail = el)}
                />
              </div>
              <div className="form-group">
                <input
                  style={ this.state.unauthorized ? unauthStyle : {} }
                  type="password"
                  name="password"
                  onChange={e => this.setState({ password: e.target.value })}
                  onKeyDown={e => this.handleKeyDown(e)}
                  placeholder={this.props.t('login.password')}
                  ref={el => (this.inputPassword = el)}
                />
              </div>
            </div>

            <Button onClick={() => this.handleClickLogin()}>
              {this.props.t('login.login')}
            </Button>

            <div style={{marginTop: "20px"}}>
              <a
                style={{cursor: "pointer"}}
                onClick={() => this.handleForgotPasswordClick()}
              >
                { this.props.t('login.forgotPassword') }
              </a>
            </div>

          </div>
        </div>

        <Modal show={this.state.showModal} onHide={() => this.setState({showModal:false})}>
          <Modal.Header closeButton={false}>
            <Modal.Title>
              { this.state.modalContent() }
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              disabled={ this.state.resetEmailOkDisabled }
              onClick={() => {
              this.state.modalOk(() => {
                this.setState({showModal:false})
              });
            }}>
              {this.state.modalOkText }
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resetPasswordSent: state.resetPassword.resetPasswordSent,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  resetPasswordSend: email => {
    dispatch(
      resetPasswordActions.resetPasswordSend({email})
    );
  },
  login: (email, password) => {
    dispatch(
      userActions.login({
        email,
        password
      })
    );
  },
  tokenLogin: () => {
    dispatch(userActions.tokenLogin());
  },
  redirect: url => {
    dispatch(push(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(LoginView)
);
