import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const clientAdmin = handleAction(
  actions.gotClientAdmin,
  (state, action) => action.payload || state,
  []
);

const patchedClientAdmin = handleAction(
  actions.patchedClientAdmin,
  (state, action) => action.payload || state,
  []
);

export default combineReducers({
  clientAdmin,
  patchedClientAdmin
});
