import { all } from 'redux-saga/effects';

import {
  user,
  resetPassword,
  errorHandling,
  socket,
  loginRedirect
} from './redux/public';

function* rootSaga() {
  yield all([
    resetPassword.sagas.watchResetPassword,
    resetPassword.sagas.watchResetPasswordResolve,
    resetPassword.sagas.watchResetPasswordSend,
    user.sagas.watchLogin,
    user.sagas.watchTokenLogin,
    user.sagas.watchLoginInit,
    user.sagas.watchLoginInitContinue,
    user.sagas.watchLoginRepresentativeBooking,
    user.sagas.watchLogout,
    user.sagas.watchRegister,
    user.sagas.watchAcceptTermsOfUse,
    user.sagas.watchVerifyMfaCode,
    user.sagas.watchSendNewMfaCode,
    errorHandling.sagas.watchRequestFailed,
    socket.sagas.watchSocketOnClose,
    socket.sagas.watchSocketOnMessage,
    socket.sagas.watchSocketOnOpen,
    loginRedirect.sagas.watchLoginRedirect
  ]);
}

export default rootSaga;
