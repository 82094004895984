import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';
import moment from 'moment';

import * as actions from './actions';

const report = handleAction(
  actions.gotReport,
  (state, action) => action.payload || state,
  []
);

const reportList = handleAction(
  actions.gotReportList,
  (state, action) => action.payload || state,
  []
);

const reportIdFilter = handleAction(
  actions.gotReportIdFilter,
  (state, action) => action.payload || state,
  []
);

const opts = handleAction(
  actions.setReportOpts,
  (state, action) => ({...state, ...action.payload}) || state,
  {
    datetime: {
      startsAt: moment()
        .startOf('isoWeek')
        .utc()
        .format(),
      endsAt: moment()
        .endOf('isoWeek')
        .utc()
        .format()
    }
  }
);

export default combineReducers({
  opts,
  report,
  reportList,
  reportIdFilter
});
