import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as userActions from '../../redux/reset-password/actions';

import { ErrorBanner } from '../../components';

import { isPasswordValid } from '../../utils';

import BookersLogo from '../../assets/images/bookers.png';
import ElfGroupLogo from '../../assets/images/elfgroup_cybersafe_solution.min.svg';

const elf_style = {
  width: "100px",
  height: "100px",
  padding: "0px",
}

export class ResetPasswordView extends Component {
  constructor(props) {
    super(props);
    this.props.resetPasswordResolve(this.props.match.params.token)
    this.state = {
      email: '',
      password: ''
    };
  }

  componentDidMount() {
    this.inputPassword.focus();
  }

  handleClickSend() {
    this.props.resetPasswordSend(this.state.email)
  }

  handleClickSet() {
    this.props.resetPassword({
      token: this.props.match.params.token,
      password: this.state.password
    })
  }

  handleKeyDown(event) {
    // Submit with Enter key
    if (event.keyCode === 13) {
      this.handleClickSet();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.resolved !== nextProps.resolved ) {
      if ( nextProps.resolved.status === 200 ) {
        this.setState({email: nextProps.resolved.data.email})
      } else {
        this.props.redirect('/');
      }
    } else if ( this.props.resetPasswordDone !== nextProps.resetPasswordDone ) {
      this.props.redirect('/login/' + this.state.email)
    }
  }

  render() {
    const passwordInputStyle = this.state.password && this.state.password.length 
      ? { borderColor: isPasswordValid(this.state.password) ? '#0a0' : 'red' } : {}
    return (
      <div className="login">
        <ErrorBanner />
        <div style = {{textAlign:'center'}}>
          <div>
            <img src={BookersLogo} alt="Bookers" className="logo" /> <br />
          </div>
          
          <div>
            <img src={ElfGroupLogo} alt="ElfGroup" style={elf_style}/>
          </div>                  
        </div>
        
        <div className="form-container">
          <div className="form" style={{height: "360px"}}>
            <div className="heading">{this.props.t('setPassword.setPassword')}</div>
            <div className="form-group-collection">
              <div className="form-group">
                <span style={{ fontWeight: "bold" }}>{ this.state.email }</span>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  style={passwordInputStyle}
                  onChange={e => this.setState({ password: e.target.value })}
                  onKeyDown={e => this.handleKeyDown(e)}
                  placeholder={this.props.t('login.password')}
                  ref={el => (this.inputPassword = el)}
                />
              <div>
                { this.props.t('setPassword.requirements') }
              </div>

              </div>
            </div>
            <Button  disabled={!isPasswordValid(this.state.password)}
              style={{marginTop: 0}} onClick={() => this.handleClickSet()}>
              {this.props.t('setPassword.setPassword')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resetPasswordDone: state.resetPassword.resetPasswordDone,
  resolved: state.resetPassword.resolved
});

const mapDispatchToProps = dispatch => ({
  resetPasswordResolve: token => {
    dispatch(
      userActions.resetPasswordResolve(
        {token: token, scope: 'reset'}
      )
    );
  },
  resetPassword: obj => {
    dispatch(
      userActions.resetPassword(obj)
    );
  },
  redirect: url => {
    dispatch(push(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ResetPasswordView)
);
