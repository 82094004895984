import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const loginRedirect = handleAction(
  actions.setLoginRedirectDone,
  (state, action) => action.payload || '',
  ''
);

const mfaRedirect = handleAction(
  actions.setMfaRedirect,
  (state, action) => action.payload,
  null
);

export default combineReducers({
  loginRedirect,
  mfaRedirect
});