import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as userActions from '../../redux/user/actions';
import * as representativeActions from '../../redux/representatives/actions';

import { ErrorBanner } from '../../components';

import { isPasswordValid, isPhoneNumberValid, isMfaCodeValid } from '../../utils';

import BookersLogo from '../../assets/images/bookers.png';
import ElfGroupLogo from '../../assets/images/elfgroup_cybersafe_solution.min.svg';

const elf_style = {
  width: "100px",
  height: "100px",
  padding: "0px",
}

export class InitAccountView extends Component {
  constructor(props) {
    super(props);
    if ( this.props.match.params.token ) {
      this.props.loginInit(this.props.match.params.token)
    } else if ( this.props.user == null ) {
      this.props.redirect('/');
    } else if ( this.props.user.type === 'representative' ) {
      this.props.getRepresentative(this.props.user.representative.id)
    }
    this.state = {
      email: this.props.user ? this.props.user.email : '',
      password: '',
      outlook: 0,
      type: this.props.user ? this.props.user.type : '',
      mfaDisabled: this.props.user ? this.props.user.mfaDisabled : true,
      phoneNumber: this.props.user ? this.props.user.phoneNumber : '',
      code: ''
    };
  }

  componentDidMount() {
    this.inputPassword.focus();
  }

  handleClickContinue() {
    this.props.continue({
      newPassword: this.state.password,
      user: this.props.user,
      code: this.state.code
    })
  }

  checkPhoneNumber() {
    return this.props.user 
      && !this.props.user.mfaDisabled 
  }

  isContinueEnabled() {
    if ( this.checkPhoneNumber() ) {
      return isPasswordValid(this.state.password) 
        && isPhoneNumberValid(this.state.phoneNumber)
        && isMfaCodeValid(this.state.code)
    } else return isPasswordValid(this.state.password)
  }

  handleKeyDown(event) {
    // Submit with Enter key
    if (event.keyCode === 13 && this.isContinueEnabled()) {
      this.handleClickContinue();
    }
  }

  setOutlook() {
    this.props.startMsAuthFlow({
      ...this.state,
      representativeId: this.props.user.representative.id
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.user !== nextProps.user ) {
      if ( nextProps.user.status === 401 ) {
        this.props.redirect('/');
      } else {
        this.setState({
          email: nextProps.user.email,
          type: nextProps.user.type,
          mfaDisabled: nextProps.user.mfaDisabled,
          phoneNumber: nextProps.user.phoneNumber
        })
        if ( nextProps.user.type === 'representative' ) {
          this.setState({
            outlook: nextProps.user.representative.outlook
          })
        }
      }
    } else if ( this.props.representative !== nextProps.representative ) {
      this.setState({
        outlook: nextProps.representative.outlook
      })
    }
  }

  render() {
    const passwordInputStyle = this.state.password && this.state.password.length 
      ? { borderColor: isPasswordValid(this.state.password) ? '#0a0' : 'red' } : {}
    const phoneNumberInputStyle = this.state.phoneNumber && this.state.phoneNumber.length 
      ? { borderColor: isPhoneNumberValid(this.state.phoneNumber) ? '#0a0' : 'red' } : {}
    const mfaCodeInputStyle = this.state.code && this.state.code.length 
      ? { borderColor: isMfaCodeValid(this.state.code) ? '#0a0' : 'red' } : {}
    return (
      <div className="login">
        <ErrorBanner />
        <div style = {{textAlign:'center'}}>
          <div>
            <img src={BookersLogo} alt="Bookers" className="logo" /> <br />
          </div>
          
          <div>
            <img src={ElfGroupLogo} alt="ElfGroup" style={elf_style}/>
          </div>
        </div>

        <div className="form-container">
          <div className="form" style={{height: 'auto' /*this.state.type === 'representative' ? "375px" : "350px"*/}}>
            <div className="heading">{this.props.t('login.login')}</div>
            <div className="form-group-collection">
              <div className="form-group">
                <span style={{ fontWeight: "bold" }}>{ this.state.email }</span>
              </div>
              { this.state.type === 'representative' &&
              <div className="form-group">
              { ( this.state.outlook === 0 &&
                <a
                  style={{cursor: "pointer"}}
                  onClick={() => this.setOutlook()}
                >
                  { this.props.t('connectToOutlook') }
                </a> ) ||
                <span>
                  { this.props.t('connectedToOutlook') }
                </span>
              }
              </div>
            }
              <div className="form-group">
                <input
                  style={passwordInputStyle}
                  type="password"
                  name="password"
                  onChange={e => this.setState({ password: e.target.value })}
                  onKeyDown={e => this.handleKeyDown(e)}
                  placeholder={this.props.t('setPassword.setPassword')}
                  ref={el => (this.inputPassword = el)}
                />
              <div>
                { this.props.t('setPassword.requirements') }
              </div>
              {
                this.checkPhoneNumber() &&
                <React.Fragment>
                  <div style={{marginTop: 35}}></div>
                  <input
                      type="text"
                      name="phoneNumber"
                      style={phoneNumberInputStyle}
                      value={this.state.phoneNumber}
                      onChange={e => this.setState({ phoneNumber: e.target.value })}
                      onKeyDown={e => this.handleKeyDown(e)}
                      placeholder={this.props.t('phoneNumber')}
                    />
                  <div>
                    <a
                      style={{cursor: "pointer"}}
                      onClick={() => this.props.sendNewMfaCode(
                        this.props.user.id, this.state.phoneNumber
                      )}
                    >
                      { this.props.t('mfaCode.sendConfirmationCode') }
                    </a>
                  </div>
                  <div style={{marginTop: 15}}></div>
                  <input
                      type="text"
                      name="code"
                      value={this.state.code}
                      style={mfaCodeInputStyle}
                      onChange={e => this.setState({ code: e.target.value })}
                      onKeyDown={e => this.handleKeyDown(e)}
                      placeholder={this.props.t('confirmationCode')}
                    />
                  <div>
                  { this.props.t('mfaCode.confirmationRequired') }
                  </div>

                </React.Fragment>
              }
              </div>
            </div>
            <Button disabled={!this.isContinueEnabled()} 
              style={{marginTop: 0}} onClick={() => this.handleClickContinue()}>
              {this.props.t('continue')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  representative: state.representatives.representative
});

const mapDispatchToProps = dispatch => ({
  loginInit: token => {
    dispatch(
      userActions.loginInit(
        {token: token}
      )
    );
  },
  startMsAuthFlow: representative => {
    dispatch(representativeActions.startMsAuthFlow(representative))
  },
  detachOutlook: representative => {
    dispatch(representativeActions.detachOutlook(representative))
  },
  getRepresentative: representativeId => {
    dispatch(representativeActions.getRepresentative({representativeId:representativeId}))
  },
  continue: data => {
    dispatch(
      userActions.loginInitContinue(data)
    );
  },
  sendNewMfaCode: (userId, phoneNumber) => {
    dispatch(userActions.sendNewMfaCode({userId, phoneNumber}));
  },
  redirect: url => {
    dispatch(push(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(InitAccountView)
);
