import { createAction } from 'redux-actions';

export const getPostalAreas = createAction('GET_POSTAL_AREAS');
export const gotPostalAreas = createAction('GOT_POSTAL_AREAS');

export const getServicePoints = createAction('GET_SERVICEPOINTS');
export const gotServicePoints = createAction('GOT_SERVICEPOINTS');

export const getServicePointsForSubArea = createAction('GET_SERVICEPOINTS_FOR_SUBAREA');
export const gotSubAreaServicePoints = createAction('GOT_SERVICEPOINTS_FOR_SUBAREA');

export const postServicePoint = createAction('POST_SERVICEPOINT');
export const postedServicePoint = createAction('POSTED_SERVICEPOINT');

export const deleteServicePoint = createAction('DELETE_SERVICEPOINT');
export const deletedServicePoint = createAction('DELETED_SERVICEPOINT');

export const setServicePoints = createAction('SET_SERVICEPOINTS');
export const setServicePointsDone = createAction('SET_SERVICEPOINTS_DONE');
