import moment from 'moment';
import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

export class BookerStats extends Component {
  getTimeString = time => {
    const hours = moment.duration(time, 'minutes').hours();
    const minutes = moment.duration(time, 'minutes').minutes();

    const date = moment()
      .hours(hours)
      .minutes(minutes);

    return date.format('H:mm');
  };

  getStatistic = (title, statistic, best, time = false, percentile = false) => {
    if (statistic !== undefined) {
      let bsStyle = statistic.current >= statistic.target ? 'success' : 'warning'
      if ( statistic.statRanges ) {
        for ( const statRange of statistic.statRanges ) {
          if ( statistic.current < statRange.min ) break
          else bsStyle = statRange.class
        }
      }
      return (
        <div className="statistic">
          <div className="progress-container">
            <span className="current">
              {time ? this.getTimeString(statistic.current) : statistic.current}
              {percentile && '%'}
            </span>
            <div>
              <span>
                {time
                  ? this.getTimeString(statistic.minimum)
                  : (percentile ? statistic.target : statistic.minimum)}
                {percentile && '%'}
              </span>
              <ProgressBar
                bsStyle={bsStyle}
                min={percentile ? statistic.target : statistic.minimum}
                max={percentile ? statistic.minimum : statistic.target}
                now={statistic.current}
              />
              <span>
                {time
                  ? this.getTimeString(statistic.target)
                  : (percentile ? statistic.minimum : statistic.target)}
                  {percentile && '%'}
              </span>
            </div>
            <span>{title}</span>
          </div>
          {!percentile && (
            <div className="best-container">
              <div className="value">
                {best !== undefined ? ( time ? this.getTimeString(best.value) : best.value ) : '-'}
              </div>
              <div className="date">
                {best !== undefined
                  ? moment(best.timestamp).format('D.M.YYYY')
                  : '-'}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <div className="booker-stats">
        <div className="time-frame">
          <div>{this.props.t('today')}</div>
          <div>{this.props.t('bestOfMonth')}</div>
        </div>
        {this.props.statistics.current !== undefined &&
          this.props.statistics.best !== undefined && (
            <div className="statistics">
              {this.getStatistic(
                this.props.t('dealsBookings'),
                this.props.statistics.current.bookings,
                this.props.statistics.best.bookings
              )}
              {this.getStatistic(
                this.props.t('callTime'),
                this.props.statistics.current.callTime,
                this.props.statistics.best.callTime,
                true
              )}
              {this.getStatistic(
                this.props.t('contacted'),
                this.props.statistics.current.contacts,
                this.props.statistics.best.contacts
              )}
              {this.getStatistic(
                this.props.t('numberOfCalls'),
                this.props.statistics.current.numberOfCalls,
                this.props.statistics.best.numberOfCalls
              )}
              {this.getStatistic(
                this.props.t('reschedules'),
                this.props.statistics.current.reschedules,
                this.props.statistics.best.reschedules
              )}
              {/*this.getStatistic(
                this.props.t('noShow'),
                this.props.statistics.current.noShow,
                this.props.statistics.best.noShow,
                false,
                true
              )*/}
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  statistics: state.statistics
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(BookerStats)
);
