import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import * as contactActions from '../../redux/contacts/actions';
import * as servicePointActions from '../../redux/service-points/actions';
import * as projectActions from '../../redux/projects/actions';

import { ALL_SELECTED } from '../../consts';

import FenniaLogo from '../../assets/images/fennia.gif';

export class RepresentativeInfoReschedule extends Component {

  dataKey = 'contact'

  constructor(props){
    super(props)
    if ( this.doMergeServicePoints(props) ) {
      props.getSubAreas(props.contacts[this.dataKey].projectId)
      if ( !props.contacts[this.dataKey].subAreaId ) {
        const subAreaId = this.getSelectedSubArea(false)?.id
        props.setContactSubArea(subAreaId);
      }  
    }
    props.getServicePoints(props.contacts[this.dataKey].projectId)
  }

  doMergeServicePoints(props) {
    return (props || this.props).contacts[this.dataKey]?.project?.mergeServicePoints
  }

  hasCurrentServicePoint(props){
    return props.servicePoints.find(
      sp => sp.id === props.contacts[this.dataKey].servicePointId
    ) != null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( nextProps.contacts[this.dataKey] !== undefined && 
      nextProps.contacts[this.dataKey].servicePoint !== undefined &&
      nextProps.contacts[this.dataKey].servicePoint !== null &&
      !this.hasCurrentServicePoint(nextProps)) {
      nextProps.getServicePoints(nextProps.contacts[this.dataKey].projectId)
    }

  }

  handleSelectRepresentative(selectedRepresentativeId) {
    this.props.setContactRepresentative(selectedRepresentativeId);
    if ( this.doMergeServicePoints(this.props) ) {
      const servicePoint = this.getSelectedServicePoint(null, selectedRepresentativeId)
      if ( servicePoint ) {
        this.props.setContactServicePoint(servicePoint.id);
      }
    }
  }

  getServicePointByProps(props){
    if ( props.contacts[this.dataKey].servicePointId ) {
      return props.servicePoints.find(
        sp => sp.id === ( this.dataKey
          ? props.contacts[this.dataKey].servicePointId
          : props.call.selectedServicePoint.id
        )
      )
    } else {
      const representativeId = props.contacts[this.dataKey].representativeId
      const servicePoint = props.servicePoints.find(servicePoint => 
        servicePoint.representatives.find(representative => 
          representative.id === representativeId
        )
      )
      return servicePoint
    }
  }

  getAvailableRepresentatives(props){
    const byProps = this.doMergeServicePoints(props)
      ? this.getSelectedSubArea(props.contacts[this.dataKey].subAreaId)
      : this.getServicePointByProps(props)
    return byProps != null ? byProps.representatives : []  
  }

  getSelectedRepresentative(props) {
    return this.getAvailableRepresentatives(props).find(
      rep => rep.id === props.contacts[this.dataKey].representativeId
    )
  }

  handleSelectServicePoint(selectedServicePointId) {
    this.props.setContactServicePoint(selectedServicePointId);
    this.handleSelectRepresentative(ALL_SELECTED)
  }

  handleSelectSubArea(selectedSubAreaId) {
    this.props.setContactSubArea(selectedSubAreaId);
    this.handleSelectRepresentative(ALL_SELECTED)
  }

  getSelectedRepresentativeName(representative) {
    if (representative !== undefined) {
      return representative.name || `${representative.user?.firstName} ${representative.user?.lastName}`;
    }
    return this.props.t('representativeInfo.allRepresentatives');
  }

  getSelectedServicePoint(servicePointId, _representativeId) {
    if ( servicePointId ) {
      return this.props.servicePoints.find(
        servicePoint => servicePoint.id === servicePointId
      );
    } else {
      const representativeId = _representativeId || this.props.contacts[this.dataKey].representativeId
      const servicePoint = this.props.servicePoints.find(servicePoint => 
        servicePoint.representatives.find(representative => 
          representative.id === representativeId
        )
      )
      return servicePoint
    }
  }

  getSelectedServicePointAddress(servicePointId) {
    if (servicePointId !== undefined) {
      const servicePoint = this.getSelectedServicePoint(servicePointId);
      if (servicePoint !== undefined) {
        return `${servicePoint.location.streetAddress} ${
          servicePoint.location.postalCode
        } ${servicePoint.location.postalArea}`;
      }
    }
  }

  getSelectedServicePointTravelInstructions(servicePointId) {
    if (servicePointId !== undefined) {
      const servicePoint = this.getSelectedServicePoint(servicePointId);
      if (servicePoint !== undefined) {
        return servicePoint.guide;
      }
    }
  }

  getSelectedServicePointName(servicePointId) {
    const servicePoint = this.getSelectedServicePoint(servicePointId);
    return servicePoint
      ? servicePoint.name
      : this.props.t('postalArea');
  }

  getSelectedSubArea(subAreaId) {
    if ( subAreaId ) {
      return this.props.subAreas.find(
        subArea => subArea.id === subAreaId
      );
    } else {
      const representativeId = this.props.contacts[this.dataKey].representativeId
      const subArea = this.props.subAreas.find(subArea => {
        if ( subArea.representatives.find(representative => representative.id === representativeId) ) {
          return subArea
        }
      })
      return subArea
    }
  }

  getSelectedSubAreaName(subAreaId) {
    const subArea = this.getSelectedSubArea(subAreaId);
    return subArea
      ? subArea.name
      : this.props.t('subArea');
  }

  render() {
    return (
      <div className="representative-info-reschedule">
        <div className="rep-info-row">
          <div className="rep-info-row-column">
            {
              this.doMergeServicePoints(this.props) &&
                <DropdownButton
                  id="select-subArea"
                  title={this.getSelectedSubAreaName(
                    this.props.contacts[this.dataKey].subAreaId
                  )}
                >
                {this.props.subAreas.map(subArea => {
                  return (
                    <MenuItem
                      active={
                        subArea.id ===
                        (this.getSelectedSubArea(this.props.contacts[this.dataKey].subAreaId) || {}).id
                      }
                      eventKey={subArea.id}
                      key={subArea.id}
                      onSelect={eventKey => 
                        this.handleSelectSubArea(eventKey)
                      }
                    >
                      { subArea.name }
                    </MenuItem>
                  );
                })}
                </DropdownButton>
            }
            {
              !this.doMergeServicePoints(this.props) &&
                <DropdownButton
                  id="select-servicePoint"
                  title={this.getSelectedServicePointName(
                    this.props.contacts[this.dataKey].servicePointId
                  )}
                >
                  {this.props.servicePoints.map(servicePoint => {
                    return (
                      <MenuItem
                        active={
                          servicePoint.id ===
                          (this.getSelectedServicePoint(this.props.contacts[this.dataKey].servicePointId) || {}).id
                        }
                        eventKey={servicePoint.id}
                        key={servicePoint.id}
                        onSelect={eventKey =>
                          this.handleSelectServicePoint(eventKey)
                        }
                      >
                        { servicePoint.name }
                      </MenuItem>
                    );
                  })}
                </DropdownButton>
            }
          </div>
          <div className="rep-info-row-column">
            <DropdownButton
              id="select-representative"
              title={this.getSelectedRepresentativeName(
                this.getSelectedRepresentative(this.props)
              )}
            >
              <MenuItem
                active={this.props.contacts[this.dataKey].representativeId === ALL_SELECTED}
                eventKey={ALL_SELECTED}
                key={ALL_SELECTED}
                onSelect={eventKey => this.handleSelectRepresentative(eventKey)}
              >
                {this.props.t('representativeInfo.allRepresentatives')}
              </MenuItem>
              <MenuItem divider />
              {this.getAvailableRepresentatives(this.props).map(representative => {
                return (
                  <MenuItem
                    active={
                      representative.id ===
                      this.props.contacts[this.dataKey].representativeId
                    }
                    eventKey={representative.id}
                    key={representative.id}
                    onSelect={eventKey =>
                      this.handleSelectRepresentative(eventKey)
                    }
                  >
                    {this.getSelectedRepresentativeName(representative)}
                  </MenuItem>
                );
              })}
            </DropdownButton>
            <div style={{ fontSize: 12, paddingLeft: 5 }}>
              {(() => {
                const representative = this.getSelectedRepresentative(this.props)
                if ( representative ) return representative.phoneNumber
              })()}
            </div>
          </div>
          <div className="rep-info-row-column">
            <div>
              <img style={{ display: 'none' }} src={FenniaLogo} alt="Fennia" />
            </div>
          </div>
        </div>
        <div className="rep-info-row">
          <div className="rep-info-row-column">
            <span>{this.props.t('servicePoint')}</span>
            <span>
              {this.getSelectedServicePointName(
                this.props.contacts[this.dataKey].servicePointId
              )}
            </span>
          </div>
          <div className="rep-info-row-column">
            <span>{this.props.t('streetAddress')}</span>
            <span>
              {this.getSelectedServicePointAddress(
                this.props.contacts[this.dataKey].servicePointId
              )}
            </span>
          </div>
          <div className="rep-info-row-column">
            <span>{this.props.t('travelInstructions')}</span>
            <span>
              {this.getSelectedServicePointTravelInstructions(
                this.props.contacts[this.dataKey].servicePointId
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contacts: state.contacts,
  servicePoints: state.servicePoints.servicePoints,
  suggestionsPreviews: state.call.suggestionsPreviews,
  subAreas: state.projects.subAreas
});

const mapDispatchToProps = dispatch => ({
  setContactRepresentative: representativeId => {
    dispatch(
      contactActions.setContactRepresentative({
        representativeId
      })
    );
  },
  setContactServicePoint: servicePointId => {
    dispatch(
      contactActions.setContactServicePoint({
        servicePointId
      })
    );
  },
  getServicePoints: projectId => {
    dispatch(
      servicePointActions.getServicePoints({
        projectId
      })
    );
  },
  setContactSubArea: subAreaId => {
    dispatch(
      contactActions.setContactSubArea({
        subAreaId
      })
    );
  },
  getSubAreas: projectId => {
    dispatch(
      projectActions.getSubAreas({
        projectId,
        withRepresentatives: true
      })
    )
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(RepresentativeInfoReschedule)
);
