import { put, takeEvery } from 'redux-saga/effects';

import {
  authorizedPOST,
  composeErrorMessage
} from '../utils/utils.saga';

import * as errorHandling from '../error-handling/actions';
import * as userActions from './actions';

function* sagaResetPasswordResolve(action){
  const endpoint = `reset-password/resolve`,
    response = yield authorizedPOST(
      endpoint, action.payload
    );

  if (response.status === 401) {
    const message = composeErrorMessage(
      'POST', endpoint, response.data.error
    );
    yield put(errorHandling.requestFailed({
      message, data: response.data
    }));
    return;
  }

  yield put(userActions.resetPasswordResolved(response));

}


function* sagaResetPassword(action){
  const endpoint = `reset-password`,
    response = yield authorizedPOST(
      endpoint, action.payload
    );

  if (response.status !== 200) {
    const message = response.data && response.data.errorKey === 'recentPassword'
      ? "Salasana on kolmen edellisen käyttämäsi salasanan joukossa. Valitse uusi salasana."
      : composeErrorMessage('POST', endpoint, response.data.error);
    console.error(response);
    yield put(errorHandling.requestFailed(message));
    return;
  }

  yield put(userActions.resetPasswordDone(response));

}


function* sagaResetPasswordSend(action){

  const endpoint = `reset-password/send`,
    response = yield authorizedPOST(endpoint, action.payload);
/*
  if (response.status !== 200) {
    const message = composeErrorMessage(
      'POST', endpoint, response.data.error
    );
    console.error(response);
    yield put(errorHandling.requestFailed(message));
    return;
  }
  */
  yield put(userActions.resetPasswordSent(response));

}

export const watchResetPasswordSend = takeEvery(userActions.resetPasswordSend().type, sagaResetPasswordSend);
export const watchResetPasswordResolve = takeEvery(userActions.resetPasswordResolve().type, sagaResetPasswordResolve);
export const watchResetPassword = takeEvery(userActions.resetPassword().type, sagaResetPassword);
