import React, { Component } from 'react';

import { ReportExtraFilter, ReportIdFilter } from '../../components';

export class ReportIdFilters extends Component {
  constructor(props) {
    super(props);

    this.state = this.getFiltersInitialState();

    if (this.props.notifyFiltersChanged !== undefined) {
      this.props.notifyFiltersChanged(
        this.state.filterValues,
        this.areRequiredFiltersSelected()
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reportId !== this.props.reportId) {
      const newState = this.getFiltersInitialState();
      this.setState(newState);
      this.props.notifyFiltersChanged(
        newState.filterValues,
        newState.requiredFiltersSelected
      );
    }
  }

  getFiltersInitialState() {
    return {
      filterValues: new Map(),
      lastChangedFilter: {
        name: '',
        value: undefined
      },
      requiredFiltersSelected: false
    };
  }

  handleFilterValueSelect(name, value = undefined) {
    const filterValues = this.state.filterValues;
    if (value) {
      filterValues.set(name, value);
    } else {
      filterValues.delete(name);
    }
    this.setState({
      filterValues: filterValues,
      lastChangedFilter: {
        name: name,
        value: value
      }
    });
    this.props.notifyFiltersChanged(
      filterValues,
      this.areRequiredFiltersSelected()
    );
  }

  areRequiredFiltersSelected() {
    return !this.props.idFilters.some(filter => {
      return filter.mandatory && !this.state.filterValues.has(filter.name);
    });
  }

  render() {
    return this.props.idFilters.map(filter => {
      if (filter.hasOwnProperty('options') && (filter.options.length > 0)) {
        return (
          <ReportExtraFilter
            filter={filter}
            key={filter.name}
            notifySelection={(name, value) =>
              this.handleFilterValueSelect(name, value)
            }
            reportId={this.props.reportId}
          />
        );
      }
      return (
        <ReportIdFilter
          filter={filter}
          key={filter.name}
          lastChangedFilter={this.state.lastChangedFilter}
          notifySelection={(name, value) =>
            this.handleFilterValueSelect(name, value)
          }
          reportId={this.props.reportId}
        />
      );
    });
  }
}

export default ReportIdFilters;
