import moment from 'moment';
import { push } from 'react-router-redux';
import { put, select, takeEvery } from 'redux-saga/effects';

import {
  authorizedGET,
  authorizedPATCH,
  authorizedPOST,
  composeErrorMessage
} from '../utils/utils.saga';

import * as bookingActions from '../booking/actions';
import * as busyActions from '../busy/actions';
import * as contactActions from '../contacts/actions';
import * as errorHandling from '../error-handling/actions';
import * as eventActions from '../events/actions';
import * as userActions from './actions';
import * as socketActions from '../socket/actions';
import * as worktimeActions from '../worktime/actions';
import * as loginRedirectActions from '../loginRedirect/actions'
//import * as bgprocActions from '../background-process-log/actions';

import { DEFAULT_ROUTES } from '../../config';
import { BOOKER, REPRESENTATIVE, TEAMLEADER/*, ADMIN*/ } from '../../consts';

export const getWorkStarted = state => state.worktime.workStarted;
export const getLoginRedirect = state => state.loginRedirect.loginRedirect

const NOT_FROM_EU = "Yrität kirjautua järjestelmään EU-alueen ulkopuolelta. Kirjautuminen on sallittu vain EU-alueelta.";

function* acceptTermsOfUse(action){

  const endpoint = `users/${action.payload.userId}/accept-terms-of-use`,
    response = yield authorizedPATCH(endpoint, {});

  if (response.status !== 200) {
    const message = composeErrorMessage(
      'PATCH', endpoint, response.data.error
    );
    yield put(errorHandling.requestFailed(message));
    return;
  }

  if ( response.data && response.data.acceptedTermsOfUseAt != null ) {
    yield put(userActions.acceptedTermsOfUse(response.data));
    const redirectUrl = DEFAULT_ROUTES[response.data.type];
    yield put(push({pathname: redirectUrl, redirect: redirectUrl}));
  }

}

function* sagaLoginInitContinue(action){

  const { newPassword, code } = action.payload

  const endpoint = `change-password`,
    response = yield authorizedPOST(
      endpoint, {newPassword, code}
    );

  if (response.status !== 200) {
    const message = composeErrorMessage(
      'POST', endpoint, response.data.error
    );
    console.error(response);
    yield put(errorHandling.requestFailed(message));
    return;
  }

  const role = action.payload.user.type
  if (role === BOOKER || role === TEAMLEADER) {
    yield put(worktimeActions.resetElapsedBreak());

    // Use previously set work starting time if it's the same day.
    // Otherwise use current time.
    const workStarted = yield select(getWorkStarted);

    if (moment(workStarted).isSame(moment(), 'day')) {
      yield put(worktimeActions.startWork(workStarted));
    } else {
      yield put(worktimeActions.startWork(moment().toDate()));
    }
  } else if (role === REPRESENTATIVE) {
    // Open calendar for this day
    yield put(eventActions.setCurrentDay(moment().toDate()));
  }

  yield put(bookingActions.resetBooking());
  yield put(contactActions.resetContact());
  yield put(errorHandling.clearError());
  yield put(userActions.loggedIn(action.payload.user));
  yield put(worktimeActions.endBreak());
/*
  if (role === ADMIN) {
    yield put(bgprocActions.getEvents())
  }
*/
  const redirectUrl = DEFAULT_ROUTES[action.payload.user.type];
  yield put(
    push({
      pathname: redirectUrl,
      redirect: redirectUrl
    })
  );

}

function* sagaRegister(action){

  const endpoint = `register`,
    response = yield authorizedPOST(
      endpoint, action.payload
    );

  if (response.status !== 200) {
    const message = composeErrorMessage(
      'POST', endpoint, response.data.error
    );
    console.error(response);
    yield put(errorHandling.requestFailed(message));
    return;
  }

  yield put(userActions.registered());

}

function* sagaLoginRepresentativeBooking(action) {
  const response = yield authorizedPOST('login/representative-booking', {
    token: action.payload.token
  })
  if (response.status !== 200) {
    if ( response.status === 401 ) {
      if ( response.data?.errorKey === "notFromEU" ) {
        yield put(errorHandling.requestFailed(NOT_FROM_EU));
      }
      yield put(userActions.loginUnauthorized(response))
    }
    return;
  }
  response.data.min = !response.data.preventTokenOverride
  yield put(userActions.loggedIn(response.data));
}

function* sagaLoginInit(action) {
  const response = yield authorizedPOST('login/init', {
    token: action.payload.token
  })
  if (response.status !== 200) {
    if ( response.status === 401 ) {
      if ( response.data?.errorKey === "notFromEU" ) {
        yield put(errorHandling.requestFailed(NOT_FROM_EU));
      }
      yield put(userActions.loginUnauthorized(response))
    }
    return;
  }
  yield put(userActions.loggedIn(response.data));
}

function* sagaLogin(action) {
  const { email, password } = action.payload;
  const response = yield authorizedPOST('login/credentials', {
    email,
    password
  });
  if (response.status !== 200) {
    if ( response.status === 401 ) {
      if ( response.data?.errorKey === "notFromEU" ) {
        yield put(errorHandling.requestFailed(NOT_FROM_EU));
      }
    }
    yield put(userActions.loginUnauthorized(response))
    return;
  }
  yield put(userActions.loggedIn(response.data));
  yield put(userActions.tokenLogin());
}

function* sagaTokenLogin(action) {
  const response = yield authorizedPOST('login');

  const role = response.data.type;
  if (response.status !== 200) {
    if ( response.status === 401 ) {
      if ( response.data.errorKey === "expiredPassword" && response.data.error.token ) {
        const token = response.data.error.token
        window.location = '/reset-password/' + token
        yield put(userActions.loginUnauthorized(response))
      } else if ( response.data.errorKey === "invalidMfaCode" || response.data.errorKey === "invalidMfaCode_" ) {
        const codePath = '/code'
        yield put(loginRedirectActions.setMfaRedirect('/login'))
        yield put(push({
          pathname: codePath,
          redirect: codePath
        }));      
      } else if ( response.data.errorKey === "userAccountNotReady" ) {
        yield put(userActions.loginUnauthorized(response))
      }
    }
    return;
  }

  if (role === BOOKER || role === TEAMLEADER) {
    yield put(worktimeActions.resetElapsedBreak());

    // Use previously set work starting time if it's the same day.
    // Otherwise use current time.
    const workStarted = yield select(getWorkStarted);

    if (moment(workStarted).isSame(moment(), 'day')) {
      yield put(worktimeActions.startWork(workStarted));
    } else {
      yield put(worktimeActions.startWork(moment().toDate()));
    }
  } else if (role === REPRESENTATIVE) {
    // Open calendar for this day
    yield put(eventActions.setCurrentDay(moment().toDate()));
  }

  yield put(bookingActions.resetBooking());
  yield put(contactActions.resetContact());
  yield put(errorHandling.clearError());
  yield put(userActions.loggedIn(response.data));
  yield put(worktimeActions.endBreak());
  let loginRedirect = yield select(getLoginRedirect)
  const redirectUrl = DEFAULT_ROUTES[response.data.type];
/*
  if (role === ADMIN) {
    yield put(bgprocActions.getEvents())
  }
*/
  yield put(
    push({
      pathname: loginRedirect || redirectUrl,
      redirect: loginRedirect || redirectUrl
    })
  );
  yield put(loginRedirectActions.setLoginRedirect(''))
}

function* verifyMfaCode(action) {
  const { userId, code } = action.payload;
  const endpoint = 'verify-mfa-code'
  const response = yield authorizedPOST(endpoint, {userId, code});
  if (response.status !== 200) {
    const message = composeErrorMessage('POST', endpoint, response.data.error);
    console.error(response);
    yield put(errorHandling.requestFailed(message));
    return;
  }
  const redirectPath = yield select(
    state => state.loginRedirect.mfaRedirect
  )
  yield put(
    push({
      pathname: redirectPath,
      redirect: redirectPath
    })
  );
}

function* sendNewMfaCode(action) {
  
  const { userId, phoneNumber, suppressAlert } = action.payload;
  const response = yield authorizedPOST('send-new-mfa-code', {userId, phoneNumber});
  if (response.status === 200) {
    if ( !suppressAlert ) {
      alert('Vahvistuskoodi lähetetty.')
    }
  }

}

function* sagaLogout(action) {

  const { duration, breakDuration, role } = action.payload || {};

  yield put(busyActions.showBusyIndicator());

  // Report work time for booker
  if (role && ( role === BOOKER || role === TEAMLEADER ) ) {
    yield authorizedPATCH('workday', {
      duration,
      pauseDuration: breakDuration
    });
  }

  const response = yield authorizedGET('logout');

  if (response.data.error) {
    const message = composeErrorMessage('GET', 'logout', response.data.error);
    yield put(errorHandling.requestFailed(message));
    return;
  }

  yield put(socketActions.disconnect());
  yield put(userActions.loggedOut());
  yield put(worktimeActions.endBreak());

  yield put(
    push({
      pathname: '/',
      redirect: '/'
    })
  );

  yield put(busyActions.hideBusyIndicator());
  yield put(userActions.clearState());
}

export const watchAcceptTermsOfUse = takeEvery(userActions.acceptTermsOfUse().type, acceptTermsOfUse);
export const watchLogin = takeEvery(userActions.login().type, sagaLogin);
export const watchTokenLogin = takeEvery(userActions.tokenLogin().type, sagaTokenLogin);
export const watchLoginInit = takeEvery(userActions.loginInit().type, sagaLoginInit);
export const watchLoginRepresentativeBooking = takeEvery(
  userActions.loginRepresentativeBooking().type,
  sagaLoginRepresentativeBooking
);
export const watchLogout = takeEvery(userActions.logout().type, sagaLogout);
export const watchRegister = takeEvery(userActions.register().type, sagaRegister);
export const watchLoginInitContinue = takeEvery(userActions.loginInitContinue().type, sagaLoginInitContinue);
export const watchVerifyMfaCode = takeEvery(userActions.verifyMfaCode().type, verifyMfaCode);
export const watchSendNewMfaCode = takeEvery(userActions.sendNewMfaCode().type, sendNewMfaCode);
