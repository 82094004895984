import { createAction } from 'redux-actions';

export const getReport = createAction('GET_REPORT');
export const gotReport = createAction('GOT_REPORT');

export const getReportList = createAction('GET_REPORT_LIST');
export const gotReportList = createAction('GOT_REPORT_LIST');

export const getReportIdFilter = createAction('GET_REPORT_ID_FILTER');
export const gotReportIdFilter = createAction('GOT_REPORT_ID_FILTER');

export const setReportOpts = createAction('SET_REPORT_OPTS');