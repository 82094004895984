import { createAction } from 'redux-actions';

export const getBookers = createAction('GET_BOOKERS');
export const gotBookers = createAction('GOT_BOOKERS');
export const gotCallHistoryBookers = createAction('GOT_CALL_HISTORY_BOOKERS');
export const setBookersLoading = createAction('SET_BOOKERS_LOADING');

export const postBooker = createAction('POST_BOOKER');
export const postedBooker = createAction('POSTED_BOOKER');

export const getBooker = createAction('GET_BOOKER');
export const gotBooker = createAction('GOT_BOOKER');

export const patchBooker = createAction('PATCH_BOOKER');
export const patchedBooker = createAction('PATCHED_BOOKER');

export const getCallSession = createAction('GET_CALL_SESSION');
export const gotCallSession = createAction('GOT_CALL_SESSION');

export const patchConfirmationCallParameters = createAction('PATCH_CONFIRMATION_CALL_PARAMETERS');

export const detachBooker = createAction('DETACH_BOOKER');
