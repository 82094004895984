import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import * as bookingActions from '../../redux/booking/actions';

moment.locale('fi');

class Suggestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      reserved: this.props.reserved
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.reserved && nextProps.reservation === null) {
      this.setState({
        active: false,
        reserved: false
      });
    } else if ( this.props.reserved !== nextProps.reserved ) {
      this.setState({
        reserved: nextProps.reserved
      })
    }
  }

  handleBlur() {
    this.setState({
      active: false
    });
  }

  handleClick() {
    // Do not reserve time if there was no answer to the call,
    // or if the contact status is booked
    if (
      /*(this.props.call.finished && !this.props.call.answered) ||*/
      (this.props.reservation && !this.state.reserved)
    ) {
      // Do not allow multiple reservations
      return;
    }

    if (this.state.active && !this.state.reserved) {
      // Not reserved, reserve it
      if ( !this.props.onReserve ) {
        this.props.reserveBooking(
          this.props.contacts.contact.campaignId,
          this.props.suggestion.duration,
          moment(this.props.suggestion.endsAt)
            .utcOffset(120)
            .format(),
          this.props.suggestion.representativeId,
          this.props.useSuggestionServicePointId
            ? this.props.suggestion.servicePointId
            : this.props.selectedServicePoint.id,
          moment(this.props.suggestion.startsAt)
            .utcOffset(120)
            .format(),
          this.props.type ? this.props.type : 'servicePoint',
          this.props.contacts.contact.id,
          this.props.contacts.contact.projectId,
          !this.props.noId && this.props.booking !== null
            ? this.props.booking.id
            : null
        );
      } else this.props.onReserve(this)

      this.setState({
        active: false,
        reserved: true
      });
    } else if (this.state.active && this.state.reserved) {
      // Reserved, release it
      if ( !this.props.onReserve ) {
          if (this.props.suggestion.bookingId !== undefined) {
          this.props.releaseReservedBooking(
            this.props.suggestion.bookingId
          );
        }
      } else this.props.onReserve(null)
      
      this.setState({
        active: false,
        reserved: false
      });
    } else {
      // Activate button
      this.setState({
        active: true
      });
    }
  }

  handleKeyUp(event) {
    // Esc key
    if (event.keyCode === 27) {
      this.setState({
        active: false
      });
    }
  }

  render() {
    return (
      <div
        className={classNames('suggestion', {
          active: this.state.active,
          reserved: this.state.reserved
        })}
      >
        <Button
          disabled={this.props.disabled ? true : false}
          onBlur={() => this.handleBlur()}
          onClick={() => this.handleClick()}
          onKeyUp={event => this.handleKeyUp(event)}
          key={this.props.key}
        >
          {this.state.active &&
            !this.state.reserved && (
              <div>{this.props.t('suggestions.reserve')}</div>
            )}
          {this.state.active &&
            this.state.reserved && (
              <div>{this.props.t('suggestions.cancel')}</div>
            )}

          {(this.state.active || this.state.reserved) && (
            <div className="day-and-date">
              {moment(this.props.suggestion.startsAt).format('dddd D.M')}
            </div>
          )}

          <div className="time">
            {`${moment(this.props.suggestion.startsAt).format(
              'H:mm'
            )} - ${moment(this.props.suggestion.endsAt).format('H:mm')}`}
          </div>

          {this.props.showRepresentative &&
            !this.state.active && (
              <div className="representative">
                {`${this.props.suggestion.firstName} ${
                  this.props.suggestion.lastName
                }`}
              </div>
            )}
        </Button>
      </div>
    );
  }
}

Suggestion.propTypes = {
  key: PropTypes.number,
  showRepresentative: PropTypes.bool,
  suggestion: PropTypes.object,
  disabled: PropTypes.bool,
  reserved: PropTypes.bool
};

const mapStateToProps = state => ({
  booking: state.booking.booking,
  call: state.call.call,
  contacts: state.contacts,
  reservation: state.booking.reservation,
  selectedServicePoint: state.call.selectedServicePoint
});

const mapDispatchToProps = dispatch => ({
  releaseReservedBooking: (
    bookingId
  ) => {
    dispatch(
      bookingActions.releaseReservedBooking({
        bookingId
      })
    );
  },
  reserveBooking: (
    campaignId,
    duration,
    endsAt,
    representativeId,
    servicePointId,
    startsAt,
    type,
    contactId,
    projectId,
    bookingId
  ) => {
    dispatch(
      bookingActions.reserveBooking({
        campaignId,
        duration,
        endsAt,
        representativeId,
        servicePointId,
        startsAt,
        type,
        contactId,
        projectId,
        bookingId
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(Suggestion)
);
