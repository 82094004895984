import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';

import * as actions from './actions';

const importContactList = handleAction(
  actions.importedContactList,
  (state, action) => action.payload || state,
  []
);

const getContactLists = handleAction(
  actions.gotContactLists,
  (state, action) => action.payload || state,
  []
);

const removeOpenContacts = handleActions(
  new Map([
    [
      actions.removedOpenContacts,
      (state, action) => ({
        toBeDeletedCount: 0,
        deletedCount: action.payload,
      })
    ],
    [
      actions.gotOpenContactsCount,
      (state, action) => ({
        toBeDeletedCount: action.payload,
        deletedCount: state.deletedCount
      })
    ],
    [
      actions.clearOpenContacts,
      (state, action) => ({
        toBeDeletedCount: 0,
        deletedCount: null
      })
    ]
  ]),
  {
    toBeDeletedCount: 0,
    deletedCount: null
  }
);

const campaign = handleAction(
  actions.gotCampaign,
  (state, action) => action.payload || state,
  []
);

const patchCampaign = handleAction(
  actions.patchedCampaign,
  (state, action) => action.payload || state,
  []
);

const representatives = handleAction(
  actions.gotRepresentatives,
  (state, action) => action.payload || state,
  []
);

const bookers = handleAction(
  actions.gotBookers,
  (state, action) => action.payload || state,
  []
);

export default combineReducers({
  importContactList,
  getContactLists,
  removeOpenContacts,
  campaign,
  patchCampaign,
  representatives,
  bookers
});
