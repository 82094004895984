import { createAction } from 'redux-actions';

export const getCallbacks = createAction('GET_CALLBACKS');
export const gotCallbacks = createAction('GOT_CALLBACKS');

export const getNoShowBookings = createAction('GET_NO-SHOW_BOOKINGS');
export const gotNoShowBookings = createAction('GOT_NO-SHOW_BOOKINGS');

export const getTentatives = createAction('GET_TENTATIVES');
export const gotTentatives = createAction('GOT_TENTATIVES');

export const getConfirmations = createAction('GET_CONFIRMATIONS');
export const gotConfirmations = createAction('GOT_CONFIRMATIONS');

export const resetCurrentCallType = createAction('RESET_CURRENT_CALL_TYPE');
export const setCurrentCallType = createAction('SET_CURRENT_CALL_TYPE');

export const setIncludeNotInterested = createAction('SET_INCLUDE_NOT_INTERESTED');
export const setIncludeNotInterestedWithValue = createAction('SET_INCLUDE_NOT_INTERESTED_WITH_VALUE');
export const setOnlyOwn = createAction('SET_ONLY_OWN');
export const setOnlyOthers = createAction('SET_ONLY_OTHERS');

export const setNoShowFilters = createAction('SET_NO-SHOW_FILTERS');
export const gotNoShowPostalAreas = createAction('GOT_NO-SHOW_POSTAL_AREAS');
export const setNoShowSokContacts = createAction('SET_NO-SHOW_SOK_CONTACTS');
export const setSelectedNoShowServicePoint = createAction('SET_SELECTED_NO-SHOW_SERVICE_POINT');
export const setSelectedNoShowPostalAreas = createAction('SET_SELECTED_NO-SHOW_POSTAL_AREAS');
export const setSelectedNoShowRepresentatives = createAction('SET_SELECTED_NO-SHOW_REPRESENTATIVES');
export const setSelectedNoShowYear = createAction('SET_SELECTED_NO-SHOW_YEAR');
export const setSelectedNoShowMonth = createAction('SET_SELECTED_NO-SHOW_MONTH');
export const setSelectedNoShowStatus = createAction('SET_SELECTED_NO-SHOW_STATUS');
export const setSelectedNoShowBooker = createAction('SET_SELECTED_NO-SHOW_BOOKER');

export const setSelectedConfirmationServicePoint = createAction('SET_SELECTED_CONFIRMATION_SERVICE_POINT');
export const setSelectedConfirmationRepresentatives = createAction('SET_SELECTED_CONFIRMATION_REPRESENTATIVES');
export const setSelectedConfirmationBooker = createAction('SET_SELECTED_CONFIRMATION_BOOKER');
export const setConfirmationOnlyOwn = createAction('SET_CONFIRMATION_ONLY_OWN');
export const setConfirmationOnlyOthers = createAction('SET_CONFIRMATION_ONLY_OTHERS');
export const setConfirmationScheduled = createAction('SET_CONFIRMATION_SCHEDULED');

export const setStartConfirmation = createAction('SET_START_CONFIRMATION');
export const setStartNoShowModal = createAction('SET_START_NO-SHOW_MODAL');

export const setAdditionalContactInfoFilter = createAction('SET_ADDITIONAL_CONTACT_INFO_FILTER');

export const setIsLoading = createAction('SET_CALL_LIST_IS_LOADING');