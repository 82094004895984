// https://github.com/giantmachines/redux-websocket
// This repo is not available with npm, otherwise it would have
// just been imported.

import partial from 'lodash/fp/partial';
import partialRight from 'lodash/fp/partialRight';
import { compose } from 'redux';

import * as actions from './redux/socket/actions';

// Format args for creating a websocket instance
const extractArgs = config => {
  if (config.args) {
    return config.args;
  }

  if (config.url) {
    return [config.url];
  }

  return [];
};

// Create a websocket object from the incoming config
const createWebsocket = payload => {
  const args = extractArgs(payload);
  const Websocket = payload.websocket ? payload.websocket : WebSocket;

  return new Websocket(...args);
};

let pingInterval;

const createSocketMiddleware = () => {
  // Hold a reference to the WebSocket instance in use.
  let websocket;

  function ping() {
    if ( websocket && websocket.readyState === 1 ) {
      websocket.send('{"type": "ping"}')
    }
  }
  clearInterval(pingInterval)
  pingInterval = setInterval(ping, 30 * 1000)

  // A function to create the WebSocket object and attach the standard callbacks.
  const initialize = ({ dispatch }, config) => {
    // Instantiate the websocket.'
    console.log('instantiating new websocket');
    websocket = createWebsocket(config);

    // Function will dispatch actions returned from action creators.
    const dispatchAction = partial(compose, [dispatch]);

    websocket.onopen = dispatchAction(actions.open);
    websocket.onclose = dispatchAction(actions.closed);
    websocket.onmessage = dispatchAction(actions.message);

    // An optimistic callback assignment for WebSocket objects that support this
    const onConnecting = dispatchAction(actions.connecting);

    // Add the websocket as the 2nd argument (after the event).
    websocket.onconnecting = partialRight(onConnecting, [websocket]);
  };

  //  Close the WebSocket connection and cleanup
  const close = () => {
    if (websocket && websocket.readyState === 1) {
      console.log('websocket socket is open, closing');
      websocket.close(1000, '', {
        keepClosed: true,
        fastClose: true,
        delay: 0
      });
    }
    websocket = null;
  };

  const waitForConnection = (callback, interval) => {
    if (websocket && websocket.readyState === 1) {
      callback();
    } else {
      setTimeout(() => {
        waitForConnection(callback, interval);
      }, interval);
    }
  };

  // The primary Redux middleware function.
  // Each of the actions handled are user-dispatched.
  return store => next => action => {
    switch (action.type) {
      // User request to connect
      case actions.connect().type:
        console.log('websocket on reconnect: ' + websocket);
        if (!websocket || websocket.readyState === 3) {
          close();
          initialize(store, action.payload);
          next(action);
        } else {
          console.log('websocket connect asked, but connection is already open');
        }
        break;

      // User request to disconnect
      case actions.disconnect().type:
        close();
        next(action);
        break;

      // User request to send a message
      case actions.send().type:
        if (websocket) {
          waitForConnection(callback => {
            websocket.send(JSON.stringify(action.payload));
            if (typeof callback !== 'undefined') {
              callback();
            }
          }, 50);
        }
        next(action);
        break;

      default:
        next(action);
    }
  };
};

export default createSocketMiddleware();
