import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';

import * as actions from './actions';
import * as userActions from '../user/actions';

const availableRepresentatives = handleAction(
  actions.gotAvailableRepresentatives,
  (state, action) => action.payload,
  []
);

const call = handleActions(
  {
    [actions.startedCall]: (state, action) => {
      return Object.assign({}, state, {
        active: true,
        pending: false,
        origin: action.payload.origin,
        id: action.payload.id
      });
    },
    [actions.startingCall]: (state, action) => {
      return Object.assign({}, state, {
        pending: true
      });
    },
    [actions.cancelCall]: (state, action) => {
      return Object.assign({}, state, {
        pending: false
      });
    },
    [actions.endedCall]: (state, action) => {
      let o = {
        active: false,
        pending: false,
        answered: action.payload.answered,
      }
      if ( state.origin == null || (state.origin && state.origin.startsWith('decision-maker-')) ) {
        o.finished = true
      }
      return Object.assign({}, state, o);
    },
    [userActions.logout]: (state, action) => {
      return Object.assign({}, state, {
        active: false,
        pending: false,
        answered: false,
        finished: false
      });
    },
    [actions.resetCall]: (state, action) => {
      return Object.assign({}, state, {
        active: false,
        pending: false,
        answered: false,
        finished: false
      });
    },
    [actions.setCallId]: (state, action) => {
      return Object.assign({}, state, {
        id: action.payload
      });
    }
  },
  {
    active: false,
    pending: false,
    answered: false,
    finished: false
  }
);

const selectedCampaign = handleAction(
  actions.setSelectedCampaign,
  (state, action) => action.payload || state,
  {}
);

const selectedCampaigns = handleAction(
  actions.setSelectedCampaigns,
  (state, action) => action.payload,
  []
);

const calls = handleActions(
  {
    [actions.gotCalls]: (state, action) => action.payload,
    [actions.appendCalls]: (state, action) => [...state, ...action.payload]
  },
  []
);

const _calls = handleAction(
  actions._gotCalls,
  (state, action) => action.payload,
  []
);

const selectedClient = handleAction(
  actions.setSelectedClient,
  (state, action) => action.payload || state,
  {}
);

const selectedProject = handleAction(
  actions.setSelectedProject,
  (state, action) => action.payload || state,
  {}
);

const selectedRepresentatives = handleAction(
  actions.setSelectedRepresentatives,
  (state, action) => action.payload || state,
  []
);

const selectedServicePoint = handleAction(
  actions.setSelectedServicePoint,
  (state, action) => action.payload || state,
  {}
);

const selectedPostalAreas = handleAction(
  actions.setSelectedPostalAreas,
  (state, action) => action.payload || state,
  []
);

const selectedContactBusinessType = handleAction(
  actions.setSelectedContactBusinessType,
  (state, action) => action.payload,
  null
);

const selectedLanguage = handleAction(
  actions.setSelectedLanguage,
  (state, action) => action.payload,
  null
);

const suggestions = handleActions(
  {
    [actions.gotSuggestions]: (state, action) => action.payload,
    [actions.resetSuggestions]: () => []
  },
  []
);

const gettingSuggestions = handleActions(
  {
    [actions.getSuggestions]: () => true,
    [actions.notGettingSuggestions]: () => false
  },
  false
);

const delayTimer = handleActions(
  {
    [actions.decrementCallDelayTimer]: (state, action) => Math.max(state - 1, 0),
    [actions.setCallDelayTimer]: (state, action) => {
      window.enableCallDelayTimer = action.payload > 0
      return action.payload
    }
  },
  0
);

const callDuration = handleActions(
  {
    [actions.incrementCallDuration]: (state, action) => Math.max(state + 1, 0),
    [actions.setCallDuration]: (state, action) => {
      return action.payload
    }
  },
  0
);

const delayTimerTimeout = handleAction(
  actions.setCallDelayTimerTimeout,
  (state, action) => action.payload,
  []
);

const callDurationTimeout = handleAction(
  actions.setCallDurationTimeout,
  (state, action) => action.payload,
  []
);

export default combineReducers({
  availableRepresentatives,
  call,
  selectedCampaign,
  selectedCampaigns,
  selectedClient,
  selectedProject,
  selectedRepresentatives,
  selectedServicePoint,
  selectedPostalAreas,
  selectedContactBusinessType,
  selectedLanguage,
  suggestions,
  calls,
  _calls,
  gettingSuggestions,
  delayTimer,
  delayTimerTimeout,
  callDuration,
  callDurationTimeout
});
