import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { eventTypeToFaIcon } from '../../utils';

const editTitle = (title, small) => {
  if ( !title ) title = ''
  const limit = small ? 18 : 21;

  let newTitle = title.slice(0, limit);
  if (title.length > limit) {
    newTitle += '...';
  }
  return newTitle;
}

const Event = ({ event, onMouseDown, t }) => {
  const start = moment(event.startsAt);
  const end = moment(event.endsAt);
  const small = end.diff(start, 'minutes') <= 30;
  let icon = eventTypeToFaIcon(event.styleType)
  let doubleIcon = null
  if ( event.styleType === 'notConfirmed' && event.rescheduled ) {
    doubleIcon = eventTypeToFaIcon('rescheduled')
  } else if ( event.booking && event.booking.followUp && event.styleType !== 'dealProposed' ) {
    if ( icon ) doubleIcon = eventTypeToFaIcon('dealProposed')
    else icon = eventTypeToFaIcon('dealProposed')
  } else if ( event.booking && event.booking.parentId && event.styleType !== 'followUp' ) {
    if ( icon ) doubleIcon = eventTypeToFaIcon('followUp')
    else icon = eventTypeToFaIcon('followUp')
  }
  const title = event.type ? event.title: editTitle(event.title, small);
  const marginRight = doubleIcon ? 28 : 16
  const eventType = event.booking ? event.booking.type : 'null'

  const tooltip = (
    <Tooltip id={`eventColorDescription.${eventType}`}>
      {t(`eventColorDescription.${eventType}`)}
    </Tooltip>
  )

  return (
    <div
      className={classNames('rbc-event-content-wrap', { small }, event.type || 'booking')}
      onMouseDown={() => onMouseDown(event)}
    >
      <OverlayTrigger placement="top" overlay={tooltip}>
        <div
          className={classNames('rbc-event-type-indicator', eventType )}
        />
      </OverlayTrigger>
      <div className="rbc-event-time" style={{ paddingRight: marginRight }}>
        { event.booking && event.booking.contact && (event.booking.contact.contactBusinessType || {}).knownType === 'sok' &&
          <div className="sok-event" />
        }
        { !event.allDay && moment(event.startsAt).format('H:mm')} { event.shortNote || '' }
      </div>
      <div className="rbc-event-title" style={ !event.allDay ? { marginRight: doubleIcon ? 28 : 16} : { marginRight: 0 } }>
        {title}
      </div>
      {doubleIcon && 
        <FontAwesomeIcon icon={doubleIcon} className="rbc-event-icon-extra" style={{
          position: 'absolute',
          width: '12px',
          top: '12px',
          right: '24px'
        }}/>
      }
      {icon && 
        <FontAwesomeIcon icon={icon} className="rbc-event-icon" />
      }
    </div>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(Event)
);
