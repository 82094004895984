import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const entries = handleAction(
  actions.addEntry,
  (state, action) => {
    const path = action?.payload?.startsWith('/call') && !action.payload.startsWith('/call-')
      ? '/call/next'
      : action.payload
    return path !== state[0] 
      ? [path, ...state].splice(0,50)
      : state
  }, []
);

export default combineReducers({
  entries
});
