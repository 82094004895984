import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import moment from 'moment'
import { push } from 'react-router-redux';

import * as callActions from '../../redux/call/actions';
import * as callListActions from '../../redux/call-lists/actions';
import * as contactActions from '../../redux/contacts/actions';
import * as businessActions from '../../redux/business-db/actions';
import { BusinessDbTags } from '../business-db-tags'

export class AdditionalContactInfoFilter extends Component {

  emptyState = {
    postalArea: [],
    postalCode: [],
    form: [],
    businessLine: [],
    personnelSize: [],
    revenueClass: [],
    filterByRegistrationDate: false,
    registeredStartsAt: moment().year() - 50,
    registeredEndsAt: moment().year()
  }

  constructor(props) {
    super(props)
    this.state = this.getState(props)
    this.reloadValues()
  }

  getState(props) {
    let state = props.contactData != null
      ? {...props.contactData}
      : this.emptyState
    state.postalArea = this.props.selectedPostalAreas.map(postalArea => ({
      id: postalArea, name: postalArea
    }))
    return state
  }

  reloadValues() {
    this.props.getValues(
      this.state.postalCode, 
      this.state.postalArea, 
      this.state.form, 
      this.state.businessLine, 
      this.state.personnelSize, 
      this.state.revenueClass,
      true, false, true,
      this.state.filterByRegistrationDate,
      this.state.registeredStartsAt,
      this.state.registeredEndsAt,
      this.props.selectedRepresentatives?.map(rep => rep.id)
    )
  }

  render() {
    const n = this.props.values
      ? this.props.values.count 
      : 0
    return (
        <div style={{
          borderBottom: 'solid 1px #d6d6d6', 
          textAlign: 'left', 
          padding: 10 
        }} className="additional-info-filter business-export">
          <BusinessDbTags
            exclude={['postalCode']}
            includeRegisteredAt={true} 
            style={{ width: '100%' }}
            values={this.props.values} 
            state={this.state} 
            setStateNoReload={state => {
              this.setState(state)
            }}
            setState={state => {
              this.setState(state, () => {
                this.reloadValues()
              })
            }}
          />
        <Button bsStyle='primary' 
          disabled={this.props.requestState.values || !n}
          style={{ width: '100%' }} 
          onClick={() => this.props.setAdditionalContactInfoFilter(this.state)}>
            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
              <div>{this.props.t('search')} ({n})</div>
              {
                this.props.requestState.values &&
                <div style={{ position: 'absolute', right: -2, top: 12}}>
                  <div className="spinner-container" style={{ lineHeight: '26px' }}>
                    <span className="spinner">
                      <span className="bottom" style={{
                        borderTopColor: '#fff',
                        borderWidth: 3
                      }}></span>
                    </span>
                    <span className="loading"></span>
                  </div>
                </div>
              }
            </div>

        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedRepresentatives: state.call.selectedRepresentatives,
  project: state.call.selectedProject,
  contactData: state.callLists.additionalContactInfoFilter,
  values: state.businessDb.values,
  selectedPostalAreas: state.call.selectedPostalAreas.map(
    postalArea => postalArea.name
  ),
  requestState: state.businessDb.requestState
});
const mapDispatchToProps = dispatch => ({
  setAdditionalContactInfoFilter: contactData => {
    dispatch(callListActions.setCurrentCallType('booking'));
    const postalArea = [...contactData.postalArea]
    delete contactData.postalArea
    dispatch(callActions.setSelectedPostalAreas(postalArea));
    dispatch(callListActions.setAdditionalContactInfoFilter(contactData))
    dispatch(contactActions.getContactCard({
      lastCallDone: true, 
      postalArea: postalArea.map(value => value.id)
    }))
    dispatch(push('/ui/2/call'));
  },
  getValues: (postalCode, postalArea, form, businessLine, personnelSize, revenueClass, hasPhoneNumber=true, hasDecisionMaker=false, onlyActive=true, filterByRegistrationDate=false, registeredStartsAt, registeredEndsAt, representativeIds) => {
    dispatch(businessActions.getValues(
      {
        local: true,
        postalCode: postalCode ? postalCode.map(o => o.id) : [], 
        postalArea: postalArea ? postalArea.map(o => o.id) : [],
        businessLine: businessLine ? businessLine.map(o => o.id) : [], 
        form: form ? form.map(o => o.id) : [],
        personnelSize: personnelSize ? personnelSize.map(o => o.id) : [],
        revenueClass: revenueClass ? revenueClass.map(o => o.id) : [],
        hasPhoneNumber, 
        hasDecisionMaker, 
        onlyActive,
        filterByRegistrationDate,
        registeredStartsAt,
        registeredEndsAt,
        representativeIds
      }
    ))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(AdditionalContactInfoFilter)
);
