import { createAction } from 'redux-actions';

export const getCalendarsClients = createAction('GET_CALENDARS_CLIENTS');
export const gotCalendarsClients = createAction('GOT_CALENDARS_CLIENTS');

export const getCalendarsProjects = createAction('GET_CALENDARS_PROJECTS');
export const gotCalendarsProjects = createAction('GOT_CALENDARS_PROJECTS');

export const getCalendarsSubAreas = createAction('GET_CALENDARS_SUBAREAS');
export const getCalendarsSubAreasForClientAdmin = createAction('GET_CALENDARS_SUBAREAS_FOR_CLIENT_ADMIN');
export const gotCalendarsSubAreas = createAction('GOT_CALENDARS_SUBAREAS');

export const getCalendarsServicePoints = createAction('GET_CALENDARS_SERVICEPOINTS');
export const getCalendarsServicePointsForClientAdmin = createAction('GET_CALENDARS_SERVICEPOINTS_FOR_CLIENT_ADMIN');
export const gotCalendarsServicePoints = createAction('GOT_CALENDARS_SERVICEPOINTS');

export const getCalendarsRepresentative = createAction('GET_CALENDARS_REPRESENTATIVE');
export const getCalendarsRepresentatives = createAction('GET_CALENDARS_REPRESENTATIVES');
export const getCalendarsRepresentativesForClientAdmin = createAction('GET_CALENDARS_REPRESENTATIVES_FOR_CLIENT_ADMIN');
export const gotCalendarsRepresentatives = createAction('GOT_CALENDARS_REPRESENTATIVES');

export const setShowFreeSlots = createAction('SET_SHOW_FREE_SLOTS');
export const getShowFreeSlots = createAction('GET_SHOW_FREE_SLOTS');
export const gotShowFreeSlots = createAction('GOT_SHOW_FREE_SLOTS');

export const getDateBlocks = createAction('GET_DATE_BLOCKS');
export const gotDateBlocks = createAction('GOT_DATE_BLOCKS');
export const setDateBlocks = createAction('SET_DATE_BLOCKS');
export const setDateBlocksDone = createAction('SET_DATE_BLOCKS_DONE');