import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';

import * as actions from './actions';

const representative = handleAction(
  actions.gotRepresentative,
  (state, action) => action.payload || state,
  []
);

const representatives = handleActions(
  {
    [actions.gotRepresentativesForServicePoint]: (state, action) => action.payload || state,
    [actions.gotRepresentativesThatShareServicePoint]: (state, action) => action.payload || state
  },
  []
);

const patchedRepresentative = handleAction(
  actions.patchedRepresentative,
  (state, action) => action.payload || state,
  []
);

const notConfirmedCount = handleAction(
  actions.gotNotConfirmedCount,
  (state, action) => action.payload,
  null
);

const notConfirmedCountSeen = handleAction(
  actions.setNotConfirmedCountSeen,
  (state, action) => action.payload,
  false
);

export default combineReducers({
  representative,
  representatives,
  patchedRepresentative,
  notConfirmedCount,
  notConfirmedCountSeen
});
