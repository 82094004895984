import React, { Component } from 'react';
import {
  Button,
  Modal,
  DropdownButton,
  MenuItem
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import {
  BookerStats,
  BookingInfo,
  BookingStatus,
  CallType,
  ContactHeader,
  ContactInfo,
  RepresentativeInfo,
  CallHistory
} from '../../components';

import * as bookingActions from '../../redux/booking/actions';
import * as callListActions from '../../redux/call-lists/actions';
import * as contactActions from '../../redux/contacts/actions';
import * as statisticsActions from '../../redux/statistics/actions';

import { fillSmsInfo, fillEmailInfo, fillEmailTitleInfo } from '../../utils';
import { BOOKER, BOOKING_TYPES } from '../../consts';

export class Reservation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: '',
      sendTextMessage: props.selectedProject 
        ? !props.selectedProject.disableSendSmsByDefault 
        : true,
      showModal: false,
      textMessage: '',
      isSavePending: false,
      sendEmail: false,
      emailTitle: '',
      emailBody: '',
      shortNote: '',
      contactStatus: null,
      isSaveStatusPending: false,
    };

  }

  hasChanges = () => {
    return this.props.booking && ( 
      this.props.booking.notes !== this.state.notes 
        || this.props.booking.type !== this.state.type
        || this.isConfirmationCase() 
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if ( this.state.isSaveStatusPending && 
      nextProps.contacts?.contact?.id === this.props.contacts?.contact?.id && 
      nextProps.contacts?.contact?.statusId !== this.props.contacts?.contact?.statusId ) {
        this.setState({isSaveStatusPending: false}, () => {
          this.contactHeader.handleClickNext(undefined, true)
        })
    }

    if ( nextProps.currentCallType === 'confirmation' && 
      ( !this.state.contactStatus || nextProps.contacts?.contact?.id !== this.props.contacts?.contact?.id ) 
    ) {
      this.setState({contactStatus: nextProps.contacts?.contact?.status})
      if ( nextProps.contacts?.contact?.id ) {
        this.props.getContactStatuses(nextProps.contacts.contact.id, false)
      }
    }

    if ( this.props.currentCallType === 'confirmation' &&
      this.props.currentCallType !== nextProps.currentCallType ) {
        this.props.redirect('/call');
    }

    if (nextProps.reservation === null
      && this.state.isSavePending
      && (!this.contactHeader.isNextDisabled() || this.isConfirmationCase()) ) {
      this.setState({isSavePending: false})
      this.contactHeader.handleClickNext()
    }
    if (this.props.booking !== nextProps.booking && nextProps.booking !== null) {
      const s = {
        textMessage: this.getTextMessage(nextProps.booking),
        emailTitle: this.getEmailTitle(nextProps.booking),
        emailBody: this.getEmailBody(nextProps.booking),
      }
      if ( nextProps.booking.event && nextProps.booking.event.shortNote ) { 
        s.shortNote = nextProps.booking.event.shortNote
      }
      if ( nextProps.booking.notes ) s.notes = nextProps.booking.notes
      if ( nextProps.reservation === null && nextProps.booking.type ) {
        s.type = nextProps.booking.type
      }
      this.setState(s);
    }
  }

  handleClickCancel() {
    this.setState({ showModal: true });
  }

  closeModal(answered) {
    this.setState({ showModal: false });
    if (answered) {
      if (this.props.reservation !== null) {
        this.props.releaseReservedBooking(
          this.props.reservation.bookingId
        );
        this.props.redirect('/call/suggestions');
      } else {
        if (
          this.props.booking.status.superType === 'created' ||
          this.props.booking.status.superType === 'rescheduled'
        ) {
          this.props.cancelBooking(this.props.booking.id, 'noInterest');

          // If doing tentative calls, proceed to the next contact
          // after canceling a tentative booking.
          if (
            this.props.currentCallType === 'tentative' &&
            this.props.tentatives.length > 1
          ) {
            this.props.getStatistics(BOOKER);
            this.props.getTentatives();
            this.props.getContactCardById(
              this.props.tentatives[1].id,
              'tentative'
            );
          }
        }
      }
    }
  }

  handleClickSave() {
    if ( this.props.reservation !== null ) {
      this.setState({isSavePending: true})
      this.props.saveBooking(
        this.props.reservation.bookingId,
        this.props.contacts.contact.id,
        this.state.notes,
        this.state.sendTextMessage,
        this.state.textMessage,
        this.state.type,
        this.state.sendEmail,
        this.state.emailTitle,
        this.state.emailBody,
        this.state.shortNote
      );
    } else if ( this.hasChanges() ) {
      if ( this.isConfirmationCase() ) {
        this.setState({isSavePending: true})
      }
      this.props.saveBookingMin(
        this.props.booking.id,
        this.state.type,
        this.state.notes,
        this.isConfirmationCase() 
          || ( !this.isTextMessageDisabled() && this.state.sendTextMessage ),
        this.state.textMessage
      )
    }
  }

  getEmailTitle(booking) {
    return fillEmailTitleInfo(
      booking, 
      this.props.selectedProject,
      this.props.selectedClient
    )
  }

  getEmailBody(booking) {
    return fillEmailInfo(
      booking, 
      this.props.selectedProject,
      this.props.selectedClient
    )
  }

  getTextMessage(booking) {
    return fillSmsInfo(
      booking, 
      this.props.selectedProject,
      this.props.selectedClient
    )
  }

  isSaveDisabled() {
    return this.props.reservation === null 
      || !this.state.notes
      || this.state.notes.length === 0 
      || !this.state.type;
  }

  isConfirmationCase() {
    return this.props.currentCallType === 'confirmation'
      && this.props.booking
      && this.props.booking.confirmationCallAttempts
      && this.props.booking.confirmationCallAttempts.length >= 2
      && this.props.call.finished 
      && !this.props.call.answered
  }

  isTextMessageDisabled() {
    return this.props.reservation === null 
      && !this.isConfirmationCase()
  }

  handleSelectType(id){
    this.setState({type: id})
  }

  render() {

    let calendar = null;
    if ( this.props.selectedProject 
      && this.props.selectedProject.id 
      && this.props.booking 
      && this.props.booking.representative 
      && this.props.booking.representative.calendars ) {
      calendar = this.props.booking.representative.calendars.find(
        calendar => calendar.projectId === this.props.selectedProject.id
      )
    }

    const superType = this.state.contactStatus?.superType
    const isTentative = superType === 'tentative';
    const noNewBookings = superType === 'noNewBookings'
    const statusDropdown = this.props.contacts?.contact?.project?.noShowExceptions &&
      this.props.currentCallType === 'confirmation' &&
      ( isTentative || noNewBookings)

    return (
      <div className="row reservation">
        <div className="side-bar">
          <CallType />
          <BookerStats />
          <CallHistory />
        </div>
        <div style={{ width: '100%' }}>
          <RepresentativeInfo />
          <ContactHeader onRef={ref => (this.contactHeader = ref)} 
            onClickNext={
              statusDropdown && 
              this.props.booking?.id && 
              this.state.contactStatus.id !== this.props.contacts?.contact?.status?.id 
                ? ref => {
                  this.setState({isSaveStatusPending: true}, () => {
                    this.props.setContactStatus(
                      this.props.contacts?.contact?.id,
                      null,
                      null,
                      this.state.contactStatus?.id,
                      this.state.contactStatus?.superType,
                      false,
                      null,
                      noNewBookings 
                        ? this.props.booking.id 
                        : null,
                      null,
                      false
                    );
                  })
                } : undefined
            }
          />
          <BookingInfo />
          {
            statusDropdown &&
            <div className="info-row">
              <div className="info-row-column double">
                <div>
                  <span>{this.props.t('status.status')}</span>
                </div>
                <DropdownButton
                  id="dropdown-button"
                  onSelect={eventKey => {
                    this.setState({
                      contactStatus: this.props.contactStatuses?.find(
                        status => status.superType === eventKey
                      )
                    })
                  }}
                  title={this.props.contactStatuses?.find(
                    status => status.superType === this.state.contactStatus?.superType
                  )?.name || ''}
                >
                  {
                    this.props.contactStatuses?.map(status => 
                      <MenuItem active={this.state.contactStatus?.id === status.id}  
                        eventKey={status.superType} 
                        key={status.id}>
                          {status.name}
                      </MenuItem>
                    )
                  }
                </DropdownButton>
              </div>
            </div>
          }
          <div className="info-row">
            <div className="info-row-column double">
              <div className="short-note">
                <span>{ this.props.t('shortNote') }</span>
                <div>
                  <input type="text" 
                    disabled={this.props.reservation === null}
                    onChange={event => this.setState({ shortNote: event.target.value })}
                    style={{
                      border: '1px solid #ccc',
                      padding: 5,
                      borderRadius: 4
                    }}
                    value={this.state.shortNote || ''}
                  />
                </div>
              </div>
            </div>
            { this.props.currentCallType === 'confirmation' &&
              <div className="info-row-column double details">
                <div className="status">
                  <span>{this.props.t('realization')}</span>
                  <BookingStatus disabled={this.props.booking == null} 
                    filter={status => 
                      status.superType === 'created'
                      || ( 
                        status.superType === 'cancelled' 
                        && ['callLater', 'noInterest'].includes(status.subType) 
                      )
                    }
                  />
                </div>
              </div>
            }
          </div>
          <ContactInfo closeContactNotes={ true }/>

          <div className="info-row" style={{ borderBottom: "none" }}>
            <div className="info-row-column double">
            <div className="heading">{this.props.t('booking.typeOfBooking')}</div>
              <div className="heading" style={{ overflow: "visible"}}>
                <DropdownButton
                  style={{ width: "100%"}}
                  id="type-of-booking"
                  title={
                    this.state.type
                      ? this.props.t((BOOKING_TYPES.find(type => type.id === this.state.type) || {}).label)
                      : this.props.t('booking.selectTypeOfBooking')
                  }
                >
                  {BOOKING_TYPES
                    .filter(type => calendar && (
                      ( calendar.enableServicePoint && type.id === 'servicePoint' ) ||
                      ( calendar.enableRemote && type.id === 'remote' ) ||
                      ( calendar.enableCall && type.id === 'call' ) ||
                      ( calendar.enableOnline && type.id === 'online' )
                    )).map(type => {
                    return (
                      <MenuItem
                        active={this.state.type === type.id}
                        eventKey={type.id}
                        key={type.id}
                        onSelect={eventKey => this.handleSelectType(eventKey)}
                      >
                        { this.props.t(type.label) }
                      </MenuItem>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>
            { this.props.selectedProject.enableEmailInvitations &&
              <div className="info-row-column double">
                <div className="heading">
                  <input
                    name="sendEmail"
                    type="checkbox"
                    disabled={ this.props.reservation === null }
                    checked={ this.props.reservation !== null && this.state.sendEmail }
                    onChange={() => this.setState({
                      sendEmail: !this.state.sendEmail
                    })}
                  />
                  <span style={{cursor: this.props.reservation === null ? 'not-allowed' : 'pointer'}} onClick={() => {
                    if ( this.props.reservation !== null ) {
                      this.setState({sendEmail: !this.state.sendEmail})
                    }
                  }}>{this.props.t('booking.sendEmail')}</span>
                </div>
                <input 
                  disabled={this.props.reservation === null || !this.state.sendEmail}
                  style={{ fontSize: '13px', width: '95%', border: '1px solid #ccc', padding: 5, borderRadius: 2, marginBottom: 2 }} 
                  onChange={event => this.setState({ emailTitle: event.target.value })}
                  value={this.state.emailTitle ? this.state.emailTitle : ''}
                />
                <textarea
                  disabled={this.props.reservation === null || !this.state.sendEmail}
                  onChange={event => this.setState({ emailBody: event.target.value })}
                  placeholder={this.state.sendEmail ? '' : this.state.emailBody}
                  value={this.state.emailBody ? this.state.emailBody : ''}
                />
            </div>
          }
          </div>

          <div className="info-row">
            <div className="info-row-column double">
              <div className="heading">{this.props.t('notes')}</div>
              <textarea
                className="notes"
                onChange={event => this.setState({ notes: event.target.value })}
                placeholder={this.props.t('booking.notesPlaceholder')}
                value={this.state.notes}
              />
            </div>
            <div className="info-row-column double">
              <div className="heading">
                <input
                  name="sendTextMessage"
                  disabled={this.isConfirmationCase() || this.isTextMessageDisabled()}
                  type="checkbox"
                  checked={ this.isConfirmationCase() || ( !this.isTextMessageDisabled() && this.state.sendTextMessage ) }
                  onChange={() => this.setState({sendTextMessage: !this.state.sendTextMessage})}
                />
                <span style={{cursor: ( this.isConfirmationCase() || this.isTextMessageDisabled() ) ? 'not-allowed' : 'pointer'}} onClick={() => {
                  if ( !this.isConfirmationCase() && !this.isTextMessageDisabled() ) {
                    this.setState({sendTextMessage: !this.state.sendTextMessage})
                  }
                }}>{this.props.t('booking.sendTextMessage')}</span>
              </div>
              <textarea
                disabled={!this.isConfirmationCase() && ( this.isTextMessageDisabled() || !this.state.sendTextMessage )}
                onChange={event => this.setState({ textMessage: event.target.value })}
                placeholder={this.state.sendTextMessage ? '' : this.state.textMessage}
                value={this.state.sendTextMessage ? this.state.textMessage : ''}
              />
            </div>
          </div> 

          <div className="booking-actions">
            <Button
              bsStyle="success"
              disabled={ this.props.reservation === null 
                ? !this.hasChanges()
                : this.isSaveDisabled() }
              onClick={() => this.handleClickSave()}
            >
              {this.props.t('save')}
            </Button>
            <Button
              bsStyle="link"
              disabled={ this.props.reservation === null }
              onClick={() => this.handleClickCancel()}
            >
              {this.props.t('booking.cancel')}
            </Button>
          </div>
        </div>

        <Modal show={this.state.showModal}>
          <Modal.Header closeButton={false}>
            <Modal.Title>
              {this.props.t('booking.cancelConfirmation')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={() => this.closeModal(true)}>
              {this.props.t('yes')}
            </Button>
            <Button onClick={() => this.closeModal(false)}>
              {this.props.t('no')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  booking: state.booking.booking,
  call: state.call.call,
  contacts: state.contacts,
  currentCallType: state.callLists.current,
  reservation: state.booking.reservation,
  selectedProject: state.contacts.contact?.project || {},
  selectedClient: state.call.selectedClient,
  contactStatuses: state.contacts.contactStatuses,
});

const mapDispatchToProps = dispatch => ({
  cancelBooking: (bookingId, cancelReason) => {
    dispatch(bookingActions.cancelBooking({ bookingId, cancelReason }));
  },
  getContactCardById: (contactId, type) => {
    dispatch(contactActions.getContactCard({ contactId, type }));
    dispatch(push('/call'));
  },
  getStatistics: role => {
    dispatch(statisticsActions.getStatistics({ role }));
  },
  getTentatives: () => {
    dispatch(callListActions.getTentatives());
  },
  releaseReservedBooking: (
    bookingId
  ) => {
    dispatch(
      bookingActions.releaseReservedBooking({
        bookingId
      })
    );
  },
  redirect: url => {
    dispatch(push(url));
  },
  saveBooking: (bookingId, contactId, notes, sendTextMessage, textMessage, type, sendEmail, emailTitle, emailBody, shortNote) => {
    dispatch(
      bookingActions.saveBooking({
        bookingId,
        contactId,
        notes,
        sendTextMessage,
        textMessage,
        type,
        sendEmail,
        emailTitle,
        emailBody,
        shortNote
      })
    );
  },
  saveBookingMin: (bookingId, type, notes, sendTextMessage, textMessage) => {
    dispatch(
      bookingActions.saveBooking({
        bookingId,
        type,
        notes,
        sendTextMessage,
        textMessage,
        dontGet: true
      })
    );
  },
  getContactStatuses: (contactId, withBooking=false) => {
    dispatch(
      contactActions.getContactStatuses({
        contactId,
        withBooking
      })
    );
  },
  setContactStatus: (
    contactId, 
    publicNotes, 
    startsAt, 
    statusId, 
    superType, 
    noNextAfterBlock=false, 
    contact=null, 
    confirmationBookingId=null, 
    participantIds=null,
    switchChecked=false
  ) => {
    dispatch(
      contactActions.setContactStatus({
        contactId,
        publicNotes,
        startsAt,
        statusId,
        superType,
        noNextAfterBlock,
        contact,
        confirmationBookingId,
        participantIds,
        switchChecked
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(Reservation)
);
