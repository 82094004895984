import { Component } from 'react';
import { importRetry } from './utils';

const withImportPrivateSagas = (importComponent, sagaMiddleware) => {
    return class extends Component {
        state = {
            component: false
        }

        componentDidMount() {
            importRetry(() => import(/* webpackChunkName: "private" */ './sagas-private')
                .then(module => {
                    sagaMiddleware.run(module.default)
                    this.setState({component: true});
                }));
        }

        render() {
            const C = this.state.component;
            return C ? importComponent : null;
        }
    }
}

export default withImportPrivateSagas;