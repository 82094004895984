import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const setSubAreasDone = handleAction(
  actions.setSubAreasDone,
  (state, action) => action.payload || state,
  []
);

const setLocationsDone = handleAction(
  actions.setLocationsDone,
  (state, action) => action.payload || state,
  []
);

const setRepresentativesDone = handleAction(
  actions.setRepresentativesDone,
  (state, action) => action.payload || state,
  []
);

export default combineReducers({
  setSubAreasDone,
  setLocationsDone,
  setRepresentativesDone
});
