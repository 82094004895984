import { createAction } from 'redux-actions';

export const setSubAreas = createAction('SET_SUBAREAS');
export const setSubAreasDone = createAction('SET_SUBAREAS_DONE');

export const setLocations = createAction('SET_SUBAREA_LOCATIONS');
export const setLocationsDone = createAction('SET_SUBAREA_LOCATIONS_DONE');

export const postLocation = createAction('POST_SUBAREA_LOCATION');
export const postedLocation = createAction('POSTED_SUBAREA_LOCATION');

export const deleteLocation = createAction('DELETE_SUBAREA_LOCATION');
export const deletedLocation = createAction('DELETED_SUBAREA_LOCATION');

export const setRepresentatives = createAction('SET_SUBAREA_REPRESENTATIVES');
export const setRepresentativesDone = createAction('SET_SUBAREA_REPRESENTATIVES_DONE');

export const postRepresentative = createAction('POST_SUBAREA_REPRESENTATIVE');
export const postedRepresentative = createAction('POSTED_SUBAREA_REPRESENTATIVE');

export const deleteRepresentative = createAction('DELETE_SUBAREA_REPRESENTATIVE');
export const deletedRepresentative = createAction('DELETED_SUBAREA_REPRESENTATIVE');
