import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const uploadProgress = handleAction(
  actions.setUploadProgressDone,
  (state, action) => action.payload || '',
  ''
);

export default combineReducers({
  uploadProgress
});