import moment from 'moment';
import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';
import { Button } from 'react-bootstrap';

import Event from './event';

import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import '../../assets/images/font-awesome/fa-solid.min.js';
import '../../assets/images/font-awesome/fontawesome.min.js';

import * as eventActions from '../../redux/events/actions';
import * as statisticsActions from '../../redux/statistics/actions';
import * as calendarActions from '../../redux/calendars/actions';
import * as representativeActions from '../../redux/representatives/actions';
import * as contactActions from '../../redux/contacts/actions';

import { REPRESENTATIVE } from '../../consts';

import { RepresentativeStats } from '../';

moment.locale('fi');
BigCalendar.momentLocalizer(moment);

class Week extends Component {
  constructor(props) {
    super(props);

    this.props.getShowFreeSlots()

    this.state = {
      distance: 0,
      startX: 0,
      startY: 0,
      clickedEvent: {},
      timeout: null
    };

  }

  getStatistics(day){
    if ( this.isSelf() ) {
      this.props.getStatisticsOf(
        REPRESENTATIVE,
        moment(day)
          .startOf('week')
          .format(),
        moment(day)
          .endOf('week')
          .format(),
        this.props.user.representative.id,
        this.props.projectId
      );
    } else {
      this.props.getStatisticsOf(
        REPRESENTATIVE,
        moment(day)
          .startOf('week')
          .format(),
        moment(day)
          .endOf('week')
          .format(),
        this.props.representative,
        this.props.projectId
      );
    }  
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.lastCancelledOrDeleted !== nextProps.lastCancelledOrDeleted || 
      this.props.booking !== nextProps.booking ) {
      const day =
        this.props.events.currentDay !== null
          ? moment(this.props.events.currentDay)
          : moment();
      this.getWeekEvents(day);
      this.getStatistics(day);
    }
  }

  isAdmin() {
    return this.props.user.type === 'admin'
  }
  
  isTeamLeader() {
    return this.props.user.type === 'teamLeader'
  }

  isBooker() {
    return this.props.user.type === 'booker'
  }

  isClientAdmin() {
    return this.props.user.type === 'clientAdmin'
  }

  isClientAdminWithWriteAccess() {
    return this.props.user.clientAdmin && !this.props.user.clientAdmin.readOnly
  }

  canEdit() {
    return this.isSelf() || this.isAdmin() || this.isTeamLeader() || this.isClientAdminWithWriteAccess()
  }

  componentDidMount() {
    const day =
      this.props.events.currentDay !== null
        ? moment(this.props.events.currentDay)
        : moment();

    this.getWeekEvents(day);
    this.getStatistics(day);

    // Fix some actions not updating events on server fast
    // enough for reload after redirect to fetch them

    const timeout = setTimeout(() => {
      const day =
      this.props.events.currentDay !== null
        ? moment(this.props.events.currentDay)
        : moment();

      this.getWeekEvents(day);
      this.getStatistics(day);
    }, 300);
    this.setState({ timeout });


    if ( this.canEdit() ) {

      const content = document.querySelector('.rbc-time-content');

      // Select event fix (prevent <10px movement from selecting slot)
      content.addEventListener('mousedown', e => {
        this.setState({
          startX: e.clientX,
          startY: e.clientY
        });
      });
      content.addEventListener('mouseup', e => {
        this.setState((prevState, props) => {
          const sqrt1 = Math.pow(prevState.startY - e.clientY, 2);
          const sqrt2 = Math.pow(prevState.startX - e.clientX, 2);
          const distance = Math.round(Math.sqrt(sqrt1 + sqrt2));
          return {
            distance
          };
        });
      });

    }

    this.props.getRepresentative({
      representativeId: Number(this.getRepresentativeId()) 
    });
    this.props.getDateBlocks({
      representativeId: Number(this.getRepresentativeId())
    })

    Event.defaultProps = {
      onMouseDown: this.handleClick
    };
  }

  isSelf(){
    return this.props.representative === undefined
  }

  getRepresentativeId(){
    return this.isSelf()
      ? this.props.user.representative.id
      : this.props.representative
  }

  componentWillUnmount() {
    if (!!this.state.timeout) {
      clearTimeout(this.state.timeout);
      this.setState({ timeout: null });
    }
  }

  handleNavigate = day => {
    this.getWeekEvents(moment(day));
    this.getStatistics(day);
  };

  handleSelectEvent = event => {
    if ( event.external ) return;
    if (event.bookingId === null) {
      this.props.goToEvent(
        event.id,
        this.isSelf() ? null
          : { representativeId: this.props.representative }
      );
    } else if (!isEmpty(event) && event.bookingId !== null) {
      this.props.goToBooking(
        event.bookingId,
        this.isSelf() || this.isClientAdmin() ? null
          : {
              representativeId: this.props.representative,
              contactId: event.booking.contactId
          },
        this.props.project && ( this.isBooker() || this.isTeamLeader() )
          && this.props.project.customUserInterfaceId === 2
      );
    }
  };

  handleSelectSlot = slot => {
    if (this.state.distance > 10) {
      this.props.setEvent(slot.start, slot.end);
      const path = this.isSelf()
        ? '/new-event'
        : `/representative-calendar/${this.getRepresentativeId()}/new-event`
      this.props.redirect(path);
    } else {
      this.handleSelectEvent(this.state.clickedEvent);
    }
  };

  getWeekEvents = day => {
    if ( !this.isSelf() || this.props.user.representative !== undefined) {
      this.props.getEvents(
        this.getRepresentativeId(),
        day.startOf('week').format(),
        day.endOf('week').format()
      );
    }
  };

  parseEvents = events => {
    if ( !this.props.showFreeSlots ) {
      events = events.filter(event => event.styleType !== 'free' )
    }
    const notes = this.parseNotes()
    const holidays = this.parseHolidays()
    events = events
      .filter(event => !event.booking || event.booking.contactId)
      .map(event => ({
        ...event,
        endsAt: moment(event.endsAt).toDate(),
        startsAt: moment(event.startsAt).toDate(),
        title:
          event.styleType === 'free'
            ? this.props.t('representative.bookable')
            : event.title
      }))
    return events.concat(holidays, notes)
  };

  handleClick = event => {
    event && !event.external && this.setState({ clickedEvent: event });
  };

  parseNotes = () => {
    const now = this.props.events.currentDay !== null
      ? moment(this.props.events.currentDay)
      : moment();

    if (this.props.repCalendarNotes) {
      return this.props.repCalendarNotes.map(note => {
        return {
          endsAt: moment(now).add(note.day, 'day'),
          startsAt: moment(now).add(note.day, 'day'),
          title: note.note,
          allDay: true,
          type: 'note',
          external: true
        }
      })
    }
  }

  parseHolidays = () => {
    if (this.props.dateBlocks) {
      return this.props.dateBlocks.map(block => {
        return {
          endsAt: moment(block.date),
          startsAt: moment(block.date),
          title: block.description,
          allDay: true,
          type: 'block',
          external: true
        }
      })
    }
  }

  render() {
    // Hours shown
    const minTime = moment().hours(6).minutes(0).seconds(0).toDate();
    const maxTime = moment().hours(21).minutes(0).seconds(0).toDate();

    // Views that user can choose. 'week', 'work_week', 'day', 'agenda'
    const views = ['work_week'];
    return (
      <div className="week">
        <RepresentativeStats />
        <BigCalendar
          date={moment(this.props.events.currentDay).utcOffset(0, true).toDate()}
          defaultView="work_week"
          endAccessor="endsAt"
          eventPropGetter={(event, start, end, isSelected) => {
            return {
              className: event.styleType
            };
          }}
          events={this.parseEvents(this.props.events.events)}
          formats={{
            dayFormat: 'dd D.M',
            dayRangeHeaderFormat: ({ start }) =>
              `${this.props.t('week')} ${moment(start).format('W')}, ${moment(start).format('MMMM YYYY')}`,
            eventTimeRangeFormat: ({ start }) => moment(start).format('H:mm'),
            timeGutterFormat: 'H'
          }}
          max={maxTime}
          messages={{
            allDay: '',
            next: this.props.t('next'),
            previous: this.props.t('previous'),
            today: this.props.t('today')
          }}
          min={minTime}
          onNavigate={event => this.handleNavigate(event)}
          onSelectEvent={event => this.handleSelectEvent(event)}
          onSelectSlot={slot => this.handleSelectSlot(slot)}
          selectable={this.isTeamLeader() || this.isSelf() || this.isClientAdminWithWriteAccess() || this.isAdmin()}
          startAccessor="startsAt"
          step={30}
          views={views}
          components={{
            event: Event
          }}
        />
        <div style={{ margin: '1.2em 0', padding: 10, paddingLeft: 45, textAlign: 'left' }}>
          <Button style={{ outline: 0, padding: 0, margin: 0 }} bsStyle="link"
            onClick={() => {
              this.props.setShowFreeSlots(
                !this.props.showFreeSlots
              )
            }}
          >
            { 
              this.props.showFreeSlots 
                ? this.props.t('hideFreeSlots') 
                : this.props.t('showFreeSlots')
            }
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  events: state.events,
  user: state.user,
  booking: state.booking.booking,
  project: state.call.selectedProject,
  repCalendarNotes: state.representatives.representative.calendarNotes,
  dateBlocks: (state.calendars.dateBlocks || [])
    .filter(dateBlock => !dateBlock.bypass),
  showFreeSlots: state.calendars.showFreeSlots !== null
    ? state.calendars.showFreeSlots
    : true,
  lastCancelledOrDeleted: state.booking.lastCancelledOrDeleted
});

const mapDispatchToProps = dispatch => ({
  getEvents: (representativeId, startsAtOrAfter, endsBefore) => {
    dispatch(
      eventActions.getEvents({
        representativeId,
        startsAtOrAfter,
        endsBefore
      })
    );
  },
  getShowFreeSlots: () => {
    dispatch(calendarActions.getShowFreeSlots());
  },
  setShowFreeSlots: value => {
    dispatch(calendarActions.setShowFreeSlots(value));
  },
  getDateBlocks: (id) => {
    dispatch(calendarActions.getDateBlocks(id))
  },
  getStatistics: (role, start, end, projectId) => {
    dispatch(statisticsActions.getStatistics({role, start, end, projectId}));
  },
  getStatisticsOf: (role, start, end, representativeId, projectId) => {
    dispatch(statisticsActions.getStatistics({role, start, end, representativeId, projectId}));
  },
  getRepresentative: (id) => {
    dispatch(representativeActions.getRepresentative(id))
  },
  goToBooking: (bookingId, e=null, ui2=false) => {
    if ( ui2 ) {
      dispatch(contactActions.getContactCard({contactId: e.contactId}))
      dispatch(push(`/ui/2/call/${e.contactId}`));
    } else if ( e !== null ) {
      dispatch(push(`/representative-calendar/${e.representativeId}/contacts/${e.contactId}/bookings/${bookingId}`));
    } else {
      dispatch(push(`/bookings/${bookingId}`));
    }
  },
  goToEvent: (eventId, e=null) => {
    if ( e !== null ) {
      dispatch(push(`/representative-calendar/${e.representativeId}/events/${eventId}`));
    } else {
      dispatch(push(`/events/${eventId}`));
    }
  },
  redirect: url => {
    dispatch(push(url));
  },
  setEvent: (startsAt, endsAt) => {
    dispatch(
      eventActions.setEvent({
        startsAt,
        endsAt
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(Week)
);
