import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import * as actions from '../../redux/reports/actions';

import { ALL_SELECTED } from '../../consts';

export class ReportIdFilter extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialFilterState(props);

    if (!props.filter.dependency) {
      this.fetchFilterOptions(props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reportId && nextProps.reportId !== this.props.reportId) {
      // Report changed, filter component is being re-used
      this.setState(this.getInitialFilterState(nextProps));
      if (!nextProps.filter.dependency) {
        this.fetchFilterOptions(nextProps);
      }
    } else if (
      nextProps.reportIdFilter &&
      nextProps.reportIdFilter.name === this.props.filter.name
    ) {
      // Filter received filter options
      this.setState({
        filterOptions: nextProps.reportIdFilter,
        updateInitialized: false
      });
    }
    if (
      this.props.filter.dependency &&
      nextProps.lastChangedFilter.name === this.props.filter.dependency &&
      nextProps.lastChangedFilter.value !== this.state.lastDependencyValue
    ) {
      // Filter's dependency has changed its value
      this.setState({
        filterOptions: [],
        lastDependencyValue: nextProps.lastChangedFilter.value,
        selectedFilterOptionId: undefined,
        updateInitialized: nextProps.lastChangedFilter.value
      });
      if (nextProps.lastChangedFilter.value) {
        this.fetchFilterOptions(nextProps, nextProps.lastChangedFilter.value);
      }
      this.props.notifySelection(this.props.filter.name);
    }
  }

  getInitialFilterState(props) {
    let selectedFilterOptionId = undefined
    if ( ['booker', 'teamLeader'].includes(props.user.type) ) {
      if ( props.filter.name === 'clientId' && props.selectedClient ) {
        selectedFilterOptionId = props.selectedClient.id
        props.notifySelection(
          props.filter.name, 
          selectedFilterOptionId
        )
      } else if ( props.filter.name === 'projectId' && props.selectedProject ) {
        selectedFilterOptionId = props.selectedProject.id
        props.notifySelection(
          props.filter.name, 
          selectedFilterOptionId
        )
      }
    }
    return {
      filterOptions: [],
      lastDependencyValue: undefined,
      notifySelection: props.notifySelection,
      selectedFilterOptionId: selectedFilterOptionId,
      updateInitialized: props.filter.dependency ? false : true
    };
  }

  fetchFilterOptions(props, dependencyValue = undefined) {
    this.props.getReportIdFilter(
      props.filter.name,
      props.filter.path,
      dependencyValue
    );
  }

  handleFilterOptionSelect(eventKey) {
    this.setState({
      selectedFilterOptionId: eventKey
    });
    const value = eventKey === ALL_SELECTED ? undefined : eventKey;
    this.props.notifySelection(this.props.filter.name, value);
  }

  filterTitle() {
    if (this.state.selectedFilterOptionId !== undefined) {
      if (this.state.selectedFilterOptionId === ALL_SELECTED) {
        return this.props.t(
          `reports.filterTitles.${this.props.filter.name}All`
        );
      } else {
        return (this.state.filterOptions.find(
          filter => filter.id === this.state.selectedFilterOptionId
        ) || {}).name;
      }
    }
    return this.props.t(`reports.filterTitles.${this.props.filter.name}`);
  }

  filterItems() {
    let items = [];
    if (this.state.filterOptions) {
      if (!this.props.filter.mandatory) {
        items.push(
          <MenuItem
            active={this.state.selectedFilterOptionId === ALL_SELECTED}
            eventKey={ALL_SELECTED}
            key={ALL_SELECTED}
            onSelect={eventKey => this.handleFilterOptionSelect(eventKey)}
          >
            {this.props.t(`reports.filterTitles.${this.props.filter.name}All`)}
          </MenuItem>
        );
        items.push(<MenuItem divider={true} key={'divider'} />);
      }
      items = items.concat(
        this.state.filterOptions.map(filterOption => {

          /* A hasty exception, cleanup recommended. */
          if ( this.props.user.type === 'representative' 
            && this.state.filterOptions.name === 'representativeId' 
            && filterOption.id !== this.props.user.representative.id ) {
              return null
          }

          return (
            <MenuItem
              active={filterOption.id === this.state.selectedFilterOptionId}
              eventKey={filterOption.id}
              key={filterOption.id}
              onSelect={eventKey => this.handleFilterOptionSelect(eventKey)}
            >
              {filterOption.name}
            </MenuItem>
          );
        })
      );
      if (this.props.filter.mandatory && 
        this.props.filter.selectFirstAndHideFilterOnLessThanTwoOptions && 
        this.state.filterOptions.length && 
        this.state.selectedFilterOptionId === undefined ) {
        this.handleFilterOptionSelect(this.state.filterOptions[0].id)
      }
    }
    return items;
  }

  render() {
    const style = ( ['teamLeader', 'booker'].includes(this.props.user.type) &&  ['clientId', 'projectId'].includes(this.props.filter.name) )
      || ( this.props.filter.selectFirstAndHideFilterOnLessThanTwoOptions && ( !this.state.filterOptions || this.state.filterOptions.length < 2 ) )
        ? {display: 'none'}
        : {}
    return (
      <div className="id-filter" style={style}>
        <DropdownButton
          id="select-filter"
          title={this.filterTitle() || ''}
          disabled={this.state.filterOptions.length === 0}
        >
          {this.filterItems()}
        </DropdownButton>
      </div>
    );
  }
}

ReportIdFilter.propTypes = {
  getReportIdFilter: PropTypes.func,
  reportIdFilter: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => ({
  reportIdFilter: state.reports.reportIdFilter,
  user: state.user,
  selectedClient: state.call.selectedClient,
  selectedProject: state.call.selectedProject
});

const mapDispatchToProps = dispatch => ({
  getReportIdFilter: (name, path, value) => {
    dispatch(
      actions.getReportIdFilter({
        name,
        path,
        value
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ReportIdFilter)
);
