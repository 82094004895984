import moment from 'moment';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import * as bookingActions from '../../redux/booking/actions';

export class ConfirmationCall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastConfirmationCallTime: this.getLastConfirmationCallTime(props)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      lastConfirmationCallTime: this.getLastConfirmationCallTime(nextProps)
    })
  }

  getLastConfirmationCallTime(props) {
    if (
      props.booking.booking.confirmationCallAttempts !== undefined &&
      props.booking.booking.confirmationCallAttempts.length > 0
    ) {
      const latest = props.booking.booking.confirmationCallAttempts.slice(-1)[0]
      return moment(latest.timestamp).format('D.M.YYYY H:mm');
    } else {
      return null;
    }
  }

  handleClickAnswered(answer) {
    this.props.setConfirmationCallAttempt(
      this.props.booking.booking.id,
      answer
    );
  }

  render() {
    return (
      <div className="confirmation-call">
        <div className="info-row">
          <div className="info-row-column">
            <div className="title">
              {this.props.booking.booking.confirmationCallDone
                ? this.props.t('confirmationCall.confirmationCallDone')
                : this.props.t('confirmationCall.confirmationCallNotDone')}
            </div>
          </div>

          <div className="info-row-column">
            <div className="detail">{this.state.lastConfirmationCallTime}</div>
          </div>

          { this.props.isMobile && !this.props.booking.booking.confirmationCallDone && (
            <div className="info-row-column">
              <div>
                <a href={`tel:${this.props.contact.phoneNumber}`}>
                  <Button bsStyle="success">
                    {this.props.t('confirmationCall.call')}
                  </Button>
                </a>
              </div>
            </div>
          )}

          {!this.props.booking.booking.confirmationCallDone && (
            <div className="info-row-column" style={{ width: "100%"}}>
              <div className="detail">
                {this.props.t('confirmationCall.attempts')}
                :{' '}
                {this.props.booking.booking.confirmationCallAttempts !==
                undefined
                  ? this.props.booking.booking.confirmationCallAttempts.length
                  : 0}{' '}
                / 3
              </div>
            </div>
          )}

          {!this.props.booking.booking.confirmationCallDone && (
            <div className="info-row-column" style={{ width: "100%"}}>
              <div>
                <Button
                  bsStyle="default"
                  onClick={() => this.handleClickAnswered(true)}
                >
                  {this.props.t('confirmationCall.answered')}
                </Button>
                <Button
                  bsStyle="default"
                  onClick={() => this.handleClickAnswered(false)}
                >
                  {this.props.t('confirmationCall.didNotAnswer')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  booking: state.booking,
  contact: state.contacts.contact
});

const mapDispatchToProps = dispatch => ({
  setConfirmationCallAttempt: (bookingId, success) => {
    dispatch(
      bookingActions.setConfirmationCallAttempt({
        bookingId,
        success
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ConfirmationCall)
);
