import React, { Component } from 'react';
import moment from 'moment';
import {
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as bookingActions from '../../redux/booking/actions';

export class BookingFollowUpStatus extends Component {

    render() {

        const {
            booking,
            statuses
          } = this.props;

        return (
        <DropdownButton
            id="dropdown-button"
            noCaret
            onSelect={eventKey => {
                this.handleChangeStatus(eventKey);
            }}
            disabled={
              this.props.disabled || 
                (!this.getSelectedStatusRepresentativeCanChange() 
                && !['admin', 'teamLeader'].includes(this.props.user.type)) 
            }
            title={this.getSelectedStatusName() || ''}
        >
            {statuses.length > 0 &&
            statuses.filter(status => status.userSelectable)
              .filter(status => status.availableForFollowUp)
              .filter(status => !this.props.filter || this.props.filter(status))
              .map(status => (
                <MenuItem
                    active={booking 
                      ? (status.id === booking.statusId) 
                      : status.superType === 'dealProposed'
                    }
                    eventKey={status.id}
                    key={status.id}
                >
                    {status.name}
                </MenuItem>
            ))}
        </DropdownButton>
        )
    }

    getSelectedStatusName = () => {
        let selectedStatus = undefined;
    
        if ( !this.props.booking ) return (this.props.statuses.find(
          status => status.superType === 'dealProposed'
        ) || {}).name

        if (
          this.props.statuses !== undefined &&
          this.props.statuses.length > 0 &&
          this.props.booking.statusId !== undefined
        ) {
          selectedStatus = this.props.statuses.find(
            status => status.id === this.props.booking.statusId
          );
    
          if (selectedStatus !== undefined) {
            return selectedStatus.name;
          }
          return '';
        }
    }

    getSelectedStatusRepresentativeCanChange = () => {
      return true
    }

    handleChangeStatus = statusId => {
      if ( this.props.booking ) {
        this.props.setBookingStatus(
          this.props.booking.id, 
          statusId, 
          this.props.parent.id
        );
      } else {
        const startsAt = moment(this.props.parent.startsAt)
        const endsAt = moment(this.props.parent.endsAt)
        const duration = moment.duration(endsAt.diff(startsAt)).asMinutes()
        this.props.copyBookingAsFollowUp(
          this.props.parent.id,
          this.props.parent.startsAt,
          this.props.parent.endsAt,
          duration,
          true,
          statusId
        );
      }
    };

}

const mapDispatchToProps = dispatch => ({
    setBookingStatus: (bookingId, statusId, parentId) => {
      dispatch(bookingActions.setBookingStatus({
        bookingId, 
        statusId, 
        parentId, 
        getBooking: true
      }));
    },
    copyBookingAsFollowUp: (bookingId, startsAt, endsAt, duration, noEvent, statusId) => {
      dispatch(
        bookingActions.copyBookingAsFollowUp({
          bookingId,
          startsAt,
          endsAt,
          duration,
          noEvent,
          statusId
        })
      );
    },  
  });

const mapStateToProps = state => ({
    parent: state.booking.booking,
    booking: state.booking.booking.followUp,
    statuses: state.booking.statuses,
    user: state.user
  });

  
export default connect(mapStateToProps, mapDispatchToProps)(
    translate('translations')(BookingFollowUpStatus)
  );
  