import React from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment';

import { ContactHeaderName } from '../../components';

export const ContactHeaderReschedule = (props) => {

  const {
    representative, created, lastModified,
    booking, contact, pathname, user,
    hideAdditionalInfo, redirect, t                            
  } = props

  const nameFromAction = action => {
    const obj = action.triggeredBy || action.booker || action.representative
    return `${obj.user.firstName} ${obj.user.lastName}`
  }

  let ConfirmationCallUser = null;
  
  const successfulConfirmationCall = booking 
    && booking.representativeActions 
    && booking.representativeActions.find(
      ra => ra.type === 'confirmationCall' && ra.callSuccess
    )

  if(successfulConfirmationCall) {
    try {
      ConfirmationCallUser =
        successfulConfirmationCall.triggeredBy 
          ? successfulConfirmationCall.triggeredBy.user
          : successfulConfirmationCall.representative.user
    } catch ( e ) {}
  }

  return (
    <div className="contact-header" style={{"padding": 0, "display": "flex", "alignItems": "start" }}>
      <div style={{ "flex": 1 }}>
        {pathname.indexOf('reschedule') < 0 && (
          <Button
            style={{ "margin": 0, "paddingTop": 0 }}
            bsStyle="link"
            onClick={() => {
              const redirectTo = props.historyEntries.find(entry => 
                !entry.startsWith('/reschedule') &&
                !entry.startsWith(`/contacts/${contact.id}/bookings/${booking.id}`) &&
                ( !representative || 
                  ( !entry.startsWith(`/representative-calendar/${representative}/reschedule`) &&
                  !entry.startsWith(`/representative-calendar/${representative}/contacts/${contact.id}/bookings/${booking.id}`) )
                )
              )
              redirect(redirectTo)
            }}
          >
            <Glyphicon glyph="chevron-left" />
            <span className="back">{t('back')}</span>
          </Button>
        )}
        <div style={{ padding: '0 0 12px 6px' }}>
          <ContactHeaderName />
        </div>
        <div style={{ paddingTop: 0 }}>
        { !hideAdditionalInfo && 
            (user.type === 'admin' || user.type === 'teamLeader') &&
            <div style={{ padding: 0, fontSize: 12 }}><span><b>Buukkauksen ID: </b></span>{booking.id}</div>
        }
        { !hideAdditionalInfo && created != null &&
          <div style={{
            padding: 0,
            fontSize: 12 }}
              ><span><b>{t('booking.booked')}:</b></span>
              <span>
                <span> { moment(created.timestamp).format('H:mm D.M.YYYY') }</span>
                <span> ({ nameFromAction(created) })</span>
              </span>
            </div>
        }
        { !hideAdditionalInfo 
          && !process.env.REACT_APP_DISABLE_BOOKING_CONFIRMATION_CALL_DONE_INFO 
          && booking && booking.contact && booking.contact.contactBusinessType 
          && booking.bookerConfirmationCall
          && booking.confirmationCallDone 
          && <div style={{
            padding: 0,
            fontSize: 12 }}
          >
            <span><b>{t('booking.confirmationCall')}:</b></span>
            { ((successfulConfirmationCall && ConfirmationCallUser) &&
            <span>
              <span> { moment(successfulConfirmationCall.timestamp).format('H:mm D.M.YYYY') }</span>
              <span> ({
                  ConfirmationCallUser.firstName +' '+
                  ConfirmationCallUser.lastName
                })
              </span>
            </span>
            ) || <span> { props.t('booking.markedAsConfirmed')}</span>}
          </div>
        }
        { !hideAdditionalInfo && lastModified != null &&
          <div style={{
            padding: 0,
            fontSize: 12 }}
              ><span><b>{t('booking.modified')}:</b></span>
              <span>
                <span> { moment(lastModified.timestamp).format('H:mm D.M.YYYY') }</span>
                <span> ({ nameFromAction(lastModified) })</span>
              </span>
            </div>
        }
        </div>
      </div>

      <div>
        {pathname.indexOf('reschedule') < 0 && (
          <div>
            <Button
              style={{ "marginTop": 0 }}
              bsStyle="primary"
              onClick={() => {
                if ( representative !== undefined ) {
                  redirect(`/representative-calendar/${representative}/reschedule/${booking.id}`)
                } else redirect(`/reschedule/${booking.id}`)
              }}
            >
              {t('booking.reschedule')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  booking: state.booking.booking,
  contact: state.contacts.contact,
  pathname: state.router.location.pathname,
  user: state.user,
  historyEntries: state.history.entries
});

const mapDispatchToProps = dispatch => ({
  redirect: url => {
    dispatch(push(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ContactHeaderReschedule)
);
