import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const template = handleAction(
  actions.gotContactImportTemplate,
  (state, action) => action.payload || state,
  []
);

export default combineReducers({
  template
});
