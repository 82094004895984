import { createAction } from 'redux-actions';

export const importBusinesses = createAction('IMPORT_BUSINESSES');
export const importedBusinesses = createAction('IMPORTED_BUSINESSES');

export const removeBusinesses = createAction('REMOVE_BUSINESSES');
export const removedBusinesses = createAction('REMOVED_BUSINESSES');

export const exportBusinesses = createAction('EXPORT_BUSINESSES');

export const getValues = createAction('GET_BUSINESS_VALUES');
export const gotValues = createAction('GOT_BUSINESS_VALUES');

export const getQueries = createAction('GET_QUERIES');
export const gotQueries = createAction('GOT_QUERIES');

export const saveQuery = createAction('SAVE_QUERY');
export const savedQuery = createAction('SAVED_QUERY');
export const removeQuery = createAction('REMOVE_QUERY');

export const getImportFailureSummary = createAction('GET_IMPORT_FAILURE_SUMMARY');

export const getBusinessLines = createAction('GET_BUSINESS_LINES');
export const gotBusinessLines = createAction('GOT_BUSINESS_LINES');

export const setRequestState = createAction('SET_REQUEST_STATE');

export const getSingleRequest = createAction('GET_SINGLE_REQUEST');
export const gotSingleRequest = createAction('GOT_SINGLE_REQUEST');

export const setSingleRequestError = createAction('SET_SINGLE_REQUEST_ERROR');