export const ALL_SELECTED = 'all_selected';

// Roles
export const TEAMLEADER = 'teamLeader';
export const BOOKER = 'booker';
export const REPRESENTATIVE = 'representative';
export const CLIENTADMIN = 'clientAdmin';
export const ADMIN = 'admin';

export const NOSHOW_ONLY = {
  disabled: false,
  own: 'own',
  others: 'others'
}

export const NOSHOW_TYPE = {
  sok: 1, 
  other: 2
}

// Search types
export const SEARCH_TYPES = {
  BOOKING: 'booking',
  CONTACT: 'contact'
};

export const BOOKING_TYPES = [
  {
    id: 'servicePoint',
    label: 'booking.typeServicePoint',
  },
  {
    id: 'remote',
    label: 'booking.typeRemote',
  },
  {
    id: 'call',
    label: 'booking.typeCall',
  },
  {
    id: 'online',
    label: 'booking.typeOnline',
  }
]
