import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as userActions from '../../redux/user/actions';

import { ErrorBanner } from '../../components';

import BookersLogo from '../../assets/images/bookers.png';

export class ConnectOutlookDone extends Component {

  handleClickContinue() {
    this.props.continue({
      newPassword: this.state.password,
      user: this.props.user
    })
  }

  render() {
    return (
      <div className="login">
        <ErrorBanner />
        <div>
          <img src={BookersLogo} alt="Bookers" className="logo" /> <br />
        </div>
        <div className="form" style={{height: "280px"}}>
          <div className="heading">{this.props.t('connectedToOutlook')}</div>
          <Button style={{marginTop: 0}} onClick={() => this.props.redirect('/representative')}>
            {this.props.t('openCalendar')}
          </Button>
          <Button onClick={() => this.props.logout()}>
            {this.props.t('logout')}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  redirect: url => {
    dispatch(push(url));
  },
  logout: () => {
    dispatch(userActions.logout({}));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ConnectOutlookDone)
);
