import { combineReducers } from 'redux';
import {
  handleAction,
  handleActions
} from 'redux-actions';

import * as actions from './actions';

const clients = handleAction(
  actions.gotCalendarsClients, (state, action) => action.payload || state,
  []
);

const projects = handleActions(
  {
    [actions.gotCalendarsProjects]: (state, action) => action.payload || state,
    [actions.gotCalendarsClients]: (state, action) => []
  },
  []
);

const subAreas = handleActions(
  {
    [actions.gotCalendarsSubAreas]: (state, action) => action.payload || state,
    [actions.gotCalendarsProjects]: (state, action) => [],
    [actions.gotCalendarsClients]: (state, action) => []
  },
  []
);

const servicePoints = handleActions(
  {
    [actions.gotCalendarsServicePoints]: (state, action) => action.payload || state,
    [actions.gotCalendarsSubAreas]: (state, action) => [],
    [actions.gotCalendarsProjects]: (state, action) => [],
    [actions.gotCalendarsClients]: (state, action) => []
  },
  []
);

const representatives = handleActions(
  {
    [actions.gotCalendarsRepresentatives]: (state, action) => action.payload || state,
    [actions.gotCalendarsProjects]: (state, action) => [],
    [actions.gotCalendarsClients]: (state, action) => []
  },
  []
);

const showFreeSlots = handleAction(
  actions.gotShowFreeSlots,
  (state, action) => action.payload,
  null
);

const dateBlocks = handleAction(
  actions.gotDateBlocks,
  (state, action) => action.payload,
  []
);

const setDateBlocks = handleAction(
  actions.setDateBlocksDone,
  (state, action) => action.payload,
  []
);

export default combineReducers({
  clients,
  projects,
  subAreas,
  servicePoints,
  representatives,
  showFreeSlots,
  dateBlocks,
  setDateBlocks
});
