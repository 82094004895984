import moment from 'moment';
import { useIdleTimer } from 'react-idle-timer'
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as worktimeActions from '../../redux/worktime/actions';
import { Button, Modal } from 'react-bootstrap'
import { BOOKER, TEAMLEADER } from '../../consts';
import { BREAK_TIMEOUT } from '../../config';

const IdleTimer = props => {

    useIdleTimer({
        timeout: BREAK_TIMEOUT,
        onIdle: () => {startBreak()},
    })

    const startBreak = () => {
        if (
            !props.call.active &&
            (props.user.type === BOOKER || props.user.type === TEAMLEADER) &&
            props.worktime.breakStarted === null
        ) {
            const startsAt = moment().subtract(BREAK_TIMEOUT, 'millisecond')
            props.startBreak(startsAt.toDate());
        }
    }
    
    const closeBreakModal = () => {
        if (props.worktime.breakStarted !== null) {
            const elapsedBreak = moment().diff(
                moment(props.worktime.breakStarted),
                'seconds'
            );
            props.setElapsedBreak(elapsedBreak);
            props.endBreak();
        }
    }

    return <Modal show={props.worktime.breakStarted !== null}>
        <Modal.Header closeButton={false}>
            <Modal.Title>{props.t('onBreak')}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
            <Button onClick={() => closeBreakModal()}>
                {props.t('endBreak')}
            </Button>
        </Modal.Footer>
    </Modal>

}

const mapStateToProps = state => ({
    call: state.call.call,
    user: state.user,
    worktime: state.worktime,
})

const mapDispatchToProps = dispatch => ({
    endBreak: () => {
        dispatch(worktimeActions.endBreak());
    },
    setElapsedBreak: elapsedBreak => {
        dispatch(
            worktimeActions.setElapsedBreak({
                elapsedBreak
            })
        );
    },
    startBreak: startedAt => {
        dispatch(
            worktimeActions.startBreak({
                startedAt
            })
        )
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(
    translate('translations')(IdleTimer)
)