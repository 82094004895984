import axios from 'axios';

import { store } from '../../index';
import { REST_API_BASE_URL } from '../../config';

export const getPath = path => {
  const ui = store.getState().call.selectedProject.customUserInterfaceId
  if ( !ui ) return path
  else return '/ui/' + ui + path
}

const browserSignature = _ => {

  const windowObj =  window || global;

  // Count Browser window object keys
  const windowObjCount = _ => {
    return 1/*
      const keys = [];
      for (let i in windowObj) {
        keys.push(i)
      }
      return keys.length.toString(36);*/
  }  
  // window obj and navigator aggregate
  const pad = (str, size) => {
      return (new Array(size + 1).join('0') + str).slice(-size);
  };

  // Browser mimiTypes and User Agent count
  const navi = (navigator.mimeTypes.length + navigator.userAgent.length).toString(36);
  const padString = pad(navi + windowObjCount(), 4);
  // Browser screen specific properties
  const width = windowObj.screen.width.toString(36)
  const height = windowObj.screen.height.toString(36)
  const availWidth = windowObj.screen.availWidth.toString(36)
  const availHeight = windowObj.screen.availHeight.toString(36)
  const colorDepth = windowObj.screen.colorDepth.toString(36)
  const pixelDepth = windowObj.screen.pixelDepth.toString(36)
  // Base64 encode
  return btoa(padString + width + height + availWidth + availHeight + colorDepth + pixelDepth);
};

// Function to compose an error message to be shown to the user
// in a noticification popup.
export function composeErrorMessage(operation, endpoint, error) {

  /*
    TODO: A temporary exception. Replace with a proper solution.
  */
  if ( error.errorKey === 'invalidCode' ) return 'Vahvistuskoodi on virheellinen.'

  return `Failed to ${operation} ${endpoint} (${error.statusCode}) (${
    error.errorKey
  })`;
}

export function authorizedGET(path, params, additionalHeaders={}, cancelTokenSource) {
  const getParams = {
    headers: {
      authorization: store.getState().user.token,
      Device: browserSignature(),
      ...additionalHeaders,
    },
    ...params
  }

  if ( cancelTokenSource ) {
    getParams.cancelToken = cancelTokenSource.token
  } 

  const response = axios
    .get(`${REST_API_BASE_URL}/${path}`, getParams)
    .then(resolved => resolved)
    .catch(error => {
      if ( axios.isCancel(error) ) {
        // TODO: Add cancel handling here
      }
      return error.response
    });

  return response;
}

/**
 * Makes authorize post call to backend with given configuration
 * @param {String} path path where to post
 * @param {Object} data data to post 
 * @param {Object} axiosConf axios configuration object please don't give headers they are already set
 */
export function authorizedPOST(path, data, axiosConf={}, cancelTokenSource) {
  if ( cancelTokenSource ) {
    axiosConf.cancelToken = cancelTokenSource.token
  } 
  const response = axios
    .post(`${REST_API_BASE_URL}/${path}`, data, {
      headers: {
        authorization: store.getState().user.token,
        Device: browserSignature()
      },
      ...axiosConf
    })
    .then(resolved => resolved)
    .catch(error => error.response);

  return response;
}

export function authorizedPATCH(path, data) {
  const response = axios
    .patch(`${REST_API_BASE_URL}/${path}`, data, {
      headers: {
        authorization: store.getState().user.token,
        Device: browserSignature()
      }
    })
    .then(resolved => resolved)
    .catch(error => error.response);

  return response;
}

export function authorizedDELETE(path, params) {
  const response = axios
    .delete(`${REST_API_BASE_URL}/${path}`, {
      headers: {
        authorization: store.getState().user.token,
        Device: browserSignature()
      },
      ...params
    })
    .then(resolved => resolved)
    .catch(error => error.response);

  return response;
}

export function isSokProject(project){
  return project && project.contactBusinessTypes &&
    project.contactBusinessTypes.find(
      contactBusinessType => contactBusinessType.knownType === 'sok'
    ) != null
}