import moment from 'moment';
import 'moment/locale/fi';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class TimePicker extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      hour: moment(this.props.date).format('HH'),
      minute: moment(this.props.date).format('mm')
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!moment(nextProps.date).isSame(moment(this.props.date))) {
      this.setState({
        hour: moment(nextProps.date).format('HH'),
        minute: moment(nextProps.date).format('mm')
      });
      this.input.value = moment(nextProps.date).format('HH:mm');
    }
  }

  componentDidMount() {
    if ( this.props.onRef ) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if ( this.props.onRef ) {
      this.props.onRef(undefined)
    }
  }

  handleChange(event) {
    const time = event.target.value.split(':');
    // Valid time given
    if (time.length === 2) {
      this.setState({
        hour: time[0],
        minute: time[1]
      });
    } else {
      this.input.value = moment(this.props.date).format('HH:mm');
    }
  }

  handleInputBlur(e) {
    this.props.onTimeChange({
      hour: this.state.hour,
      minute: this.state.minute
    });
    if ( this.props.onBlur ) {
      this.props.onBlur(e)
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13 || event.keyCode === 27) {
      this.input.blur();
    }
  }

  render() {
    return (
      <input
        style={{ ...(this.props.style ? this.props.style : {}) }}
        className="time-picker"
        defaultValue={moment(this.props.date).format('HH:mm')}
        onFocus={() => {
          if ( this.props.onFocus ) {
            this.props.onFocus()
          }
        }}
        onBlur={e => { this.handleInputBlur(e) }}
        onChange={event => this.handleChange(event)}
        onKeyDown={event => this.handleKeyDown(event)}
        pattern={'^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'}
        ref={(component) => { this.input = component }}
        type="time"
        required={this.props.required}
      />);
  }
}

TimePicker.defaultProps = {
  required: false
}

TimePicker.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onTimeChange: PropTypes.func.isRequired
};

export default TimePicker;
