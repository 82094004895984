import { createAction } from 'redux-actions';
import moment from 'moment';

// Actions to be dispatched by the user.
export const connect = createAction('SOCKET_CONNECT');
export const disconnect = createAction('SOCKET_DISCONNECT');
export const send = createAction('SOCKET_SEND');

// These actions are more concerned with connection state
// and are triggeredd async by the WebSocketMiddleware

export const connecting = createAction(
  'SOCKET_CONNECTING',
  (event, websocket) => ({
    timestamp: moment().toDate(),
    event,
    websocket
  })
);

export const open = createAction('SOCKET_OPEN', event => ({
  timestamp: moment().toDate(),
  event
}));

export const closed = createAction('SOCKET_CLOSED', event => ({
  timestamp: moment().toDate(),
  event
}));

export const message = createAction('SOCKET_MESSAGE', event => ({
  timestamp: moment().toDate(),
  data: event.data,
  event
}));
