import { createAction } from 'redux-actions';

export const cancelBooking = createAction('CANCEL_BOOKING');
export const cancelledBooking = createAction('CANCELLED_BOOKING');

export const copyBookingAsFollowUp = createAction('COPY_BOOKING_AS_FOLLOW-UP');

export const getBookingStatuses = createAction('GET_BOOKING_STATUSES');
export const gotBookingStatuses = createAction('GOT_BOOKING_STATUSES');

export const getBooking = createAction('GET_BOOKING');
export const gotBooking = createAction('GOT_BOOKING');

export const saveFollowUpInfo = createAction('SAVE_FOLLOW_UP_INFO');

export const saveMoveInfo = createAction('SAVE_MOVING_INFO');
export const moveBooking = createAction('MOVE_BOOKING');

export const releaseReservedBooking = createAction('RELEASE_RESERVED_BOOKING');
export const releasedReservedBooking = createAction(
  'RELEASED_RESERVED_BOOKING'
);

export const reserveBooking = createAction('RESERVE_BOOKING');
export const reservedBooking = createAction('RESERVED_BOOKING');

export const resetBooking = createAction('RESET_BOOKING');
export const resetReservation = createAction('RESET_RESERVATION');

export const saveBooking = createAction('SAVE_BOOKING');
export const savedBooking = createAction('SAVED_BOOKING');

export const setBookingStatus = createAction('SET_BOOKING_STATUS');
export const setBookingBooker = createAction('SET_BOOKING_BOOKER');
export const setConfirmationCallAttempt = createAction(
  'SET_CONFIRMATION_CALL_ATTEMPT'
);

export const deleteBooking = createAction('DELETE_BOOKING');
export const deletedBooking = createAction('DELETED_BOOKING');

export const sendSMS = createAction('SEND_SMS');
export const sentSMS = createAction('SENT_SMS');

export const getProposed = createAction('GET_PROPOSED')
export const getBookings = createAction('GET_BOOKINGS')
export const gotBookings = createAction('GOT_BOOKINGS')
export const setBookingsLoading = createAction('SET_BOOKINGS_LOADING')

export const setPatchedConfirmation = createAction('SET_PATCHED_CONFIRMATION');

export const disableNext = createAction('DISABLE_NEXT')
export const enableNext = createAction('ENABLE_NEXT')
