import { createAction } from 'redux-actions';

export const getRepresentative = createAction('GET_REPRESENTATIVE');
export const gotRepresentative = createAction('GOT_REPRESENTATIVE');

export const getRepresentativesForServicePoint = createAction('GET_REPRESENTATIVES_FOR_SERVICE_POINT');
export const gotRepresentativesForServicePoint = createAction('GOT_REPRESENTATIVES_FOR_SERVICE_POINT');

export const getRepresentativesThatShareServicePoint = createAction('GET_REPRESENTATIVES_THAT_SHARE_SERVICE_POINT');
export const gotRepresentativesThatShareServicePoint = createAction('GOT_REPRESENTATIVES_THAT_SHARE_SERVICE_POINT');

export const patchRepresentative = createAction('PATCH_REPRESENTATIVE');
export const patchedRepresentative = createAction('PATCHED_REPRESENTATIVE');

export const startMsAuthFlow = createAction('START_MS_AUTH_FLOW');
export const detachOutlook = createAction('DETACH_OUTLOOK');

export const detachRepresentative = createAction('DETACH_REPRESENTATIVE');

export const getNotConfirmedCount = createAction('GET_REPRESENTATIVE_NOT_CONFIRMED_COUNT');
export const gotNotConfirmedCount = createAction('GOT_REPRESENTATIVE_NOT_CONFIRMED_COUNT');
export const setNotConfirmedCountSeen = createAction('SET_REPRESENTATIVE_NOT_CONFIRMED_COUNT_SEEN');