import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Nav, NavItem } from 'react-bootstrap';
import { BusyIndicator } from '../../components'
import DecisionMakerInfoCard from './card'

import * as contactActions from '../../redux/contacts/actions';

export class DecisionMakerInfoExt extends Component {

  constructor(props) {
      super(props)
      this.state = {
          tab: props.defaultTab,
      }
      if ( props.defaultTab ) {
          this.selectTab(props.defaultTab)
      } else {
          this.selectTab(null, true)
      }
  }

  tabs = [
    {title: "Bookers", key: 'import'},
    {title: "Inaktiiviset", key: 'inactive'}
  ]

  UNSAFE_componentWillReceiveProps(nextProps) {
      if ( this.props.modifiedDecisionMaker !== nextProps.modifiedDecisionMaker ) {
          this.selectTab(null)
      }
  }

  selectTab(tab) {
    if ( this.props.onChange(tab) ) {
        this.props.getDecisionMakers(
            tab || 'bookers', 
            this.props.contact.businessId,
            this.props.project.id,
            this.props.project.businessPerDecisionMaker
                ? this.props.contact.id 
                : null
        )
        this.setState({tab})
    }
  }

  render() {
    const selectedStyle = {
        borderRadius: '5px 5px 0 0', 
        border: '1px solid #ccc', 
        borderWidth: '1px 1px 0 1px', 
        overflow: 'hidden'
    }
    const nonSelectedStyle = {
        border: '1px solid #ccc', 
        borderWidth: '0 0 1px 0', 
        overflow: 'hidden', 
        borderRadius: '5px 5px 0 0'
    }
    return (
        <div style={{ paddingTop: 20 }} className='decision-maker-info-ext'>
            <div style={{ display: 'flex', flexFlow: 'row' }}>
                <Nav variant="tabs" style={{ display: 'flex' }}>
                    <div style={{ borderBottom: '1px solid #ccc', flex: 1, width: 10 }}></div>
                    <NavItem onClick={() => {this.selectTab(null)}}
                        style={!this.state.tab ? selectedStyle : nonSelectedStyle}
                    >
                        {this.props.t('businessInfo')}
                    </NavItem>
                    <div style={{ 
                        paddingLeft: 40, 
                        paddingRight: 10, 
                        borderBottom: '1px solid #ccc', 
                        display: 'flex', 
                        alignItems: 'center' 
                    }}>
                        {this.props.t('decisionMakerInformation')}:
                    </div>
                    {
                        this.tabs.map((tab, i) =>
                            <NavItem 
                                key={i}
                                onClick={() => {this.selectTab(tab.key)}}
                                style={
                                    this.state.tab === tab.key 
                                        ? selectedStyle 
                                        : nonSelectedStyle
                                }>
                                    {tab.title}{tab.key in this.props.decisionMakerCounts ? ` (${this.props.decisionMakerCounts[tab.key]})` : ''}
                            </NavItem>
                        )
                    }
                </Nav>
                <div style={{ borderBottom: '1px solid #ccc', flex: 1 }}></div>
            </div>
            {
                this.state.tab &&
                <div style={{ 
                    textAlign: 'left', 
                    height: 'auto', 
                    padding: 0,
                    display: 'flex',
                    paddingBottom: 8,
                    flexWrap: 'wrap'
                }}>
                    {!this.props.decisionMakers[this.state.tab] &&
                        <BusyIndicator />
                    }
                    {this.props.decisionMakers[this.state.tab] && 
                    this.props.decisionMakers[this.state.tab].map(
                        (person, i) => 
                            <DecisionMakerInfoCard
                                key={i}
                                people={this.props.decisionMakers[this.state.tab]}
                                person={person} 
                                onClick={
                                    this.state.tab === 'inactive'
                                        ? null 
                                        : () => {
                                            this.props.copyDecisionMaker(
                                                person.id, 
                                                this.props.contact
                                            )
                                        }
                                } 
                                icon="plus"
                                readOnly
                                disableAdd={this.props.disableAdd}
                            />
                    )}
                </div>
            }
        </div>
    );
  }
}

const mapStateToProps = state => ({
  project: state.contacts.contact?.project || {},
  decisionMakers: state.contacts.decisionMakers,
  decisionMakerCounts: state.contacts.decisionMakerCounts,
  contact: state.contacts.contact,
  modifiedDecisionMaker: state.contacts.modifiedDecisionMaker,
});
const mapDispatchToProps = dispatch => ({
    copyDecisionMaker: (decisionMakerId, contact) => {
        dispatch(contactActions.copyDecisionMaker({
            decisionMakerId,
            contactId: contact.project.businessPerDecisionMaker
                ? contact.id
                : null
        }))
    },
    getDecisionMakers: (source, businessId, projectId=null, contactId=null) => {
        dispatch(contactActions.getDecisionMakers({
            source,
            businessId,
            projectId,
            contactId
        }))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(DecisionMakerInfoExt)
);


