import React, { Component } from 'react';
import { MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import moment from 'moment';
import { push } from 'react-router-redux';

export class BackgroundProcessLogEvent extends Component {

    details = {
        outlook_state_check: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message)
                const n = Object.keys(message?.state || {}).length
                return <div>
                    {n} {n === 1 
                        ? this.props.t('backgroundProcessLog._mismatch_singular') 
                        : this.props.t('backgroundProcessLog._mismatch_plural')
                    }.
                </div>
            } else return null
        },
        outlook_sync: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message)
                const n = message?.representativeIds?.length || 0
                return <div>
                    {n} {n === 1 
                        ? this.props.t('backgroundProcessLog._representative_singular') 
                        : this.props.t('backgroundProcessLog._representative_plural')
                    }.
                </div>
            } else return null
        },
        remove_businesses: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message)
                const singleBusiness = message.businessCount === 1
                const singleDm = message.decisionMakerCount === 1
                return <div>
                    {message.businessCount} {singleBusiness 
                        ? this.props.t('backgroundProcessLog._business_singular') 
                        : this.props.t('backgroundProcessLog._business_plural')},   {" "}
                    {message.decisionMakerCount} {singleDm
                        ? this.props.t('backgroundProcessLog._decisionMaker_singular') 
                        : this.props.t('backgroundProcessLog._decisionMaker_plural')}
                </div>
            } else return null
        },
        update_businesses: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message)
                return <div>
                    {message.count} {this.props.t('backgroundProcessLog._succeeded')},  {" "}
                    {message.failed} {this.props.t('backgroundProcessLog._failed')}.
                </div>
            } else return null
        },
        import_businesses: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message)
                return <div>
                    {message.count} {this.props.t('backgroundProcessLog._succeeded')},  {" "}
                    {message.failed} {this.props.t('backgroundProcessLog._failed')}.
                </div>
            } else return null
        },
        import_decision_makers: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message)
                return <div>
                    {message.count} {this.props.t('backgroundProcessLog._succeeded')},  {" "}
                    {message.failed} {this.props.t('backgroundProcessLog._failed')}.
                </div>
            } else return null
        },
        sync_telavox_calls: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message)
                return <div>
                    {message.success.length} {this.props.t('backgroundProcessLog._succeeded')},  {" "}
                    {message.failure.length} {this.props.t('backgroundProcessLog._failed')}.
                </div>
            } else return null
        },
        anonymize: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message || null)
                const single = message.contactIds.length === 1
                return <div>
                    {message.contactIds.length} {single 
                        ? this.props.t('backgroundProcessLog._contact_singular') 
                        : this.props.t('backgroundProcessLog._contact_plural')
                    } {this.props.t('backgroundProcessLog._anonymized')}.
                </div>
            } else return null
        },
        remove_contacts: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message || null)
                const single = message.count === 1
                return <div>
                    {message.count} {single 
                        ? this.props.t('backgroundProcessLog._contact_singular') 
                        : this.props.t('backgroundProcessLog._contact_plural')
                    } {this.props.t('backgroundProcessLog._removed')}.
                </div>
            } else return null    
        },
        remove_open_contacts: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message || null)
                const single = message.count === 1
                return <div>
                    {message.count} {single 
                        ? this.props.t('backgroundProcessLog._contact_singular') 
                        : this.props.t('backgroundProcessLog._contact_plural')
                    } {this.props.t('backgroundProcessLog._removed')}.
                </div>
            } else return null    
        },
        import_contacts: event => {
            if ( event.status === 'success' ) {
                const message = JSON.parse(event.message)
                const failed = message.summary.contactList.globalBlockedCount +
                    message.summary.contactList.projectBlockedCount +
                    message.summary.contactList.internalConflictCount +
                    message.summary.contactList.dropped.refuseBookingRange +
                    message.summary.contactList.dropped.refuseDeclinedAfterDate +
                    message.summary.contactList.dropped.tentatives +
                    message.summary.contactList.dropped.importedAt +
                    (message.summary.contactList.dropped.excludeIndices || 0) +
                    (message.summary.contactList.dropped.active || 0)
                return <div>
                    {message.summary.contactList.contactCount} {this.props.t('backgroundProcessLog._succeeded')},  {" "} 
                    {failed} {this.props.t('backgroundProcessLog._failed')}.
                </div>
            } else return null
        },
        import_contacts_init: event => {
            if ( event.status === 'failure' ) {
                try {
                    const message = JSON.parse(event.message)
                    return <div>{this.props.t('projectCtl.importError_' + message.errorDetails.type)}</div>
                } catch ( e ) {
                    return null
                }
            } else return null
        },
    }

    render() {
        const glyphiconStyle = { marginRight: 4 }
        return <MenuItem
            style={{
                borderLeft: this.props.unread ? '5px solid #a51890' : 0,
                borderBottom: '1px solid rgb(238, 238, 238)',
                padding: "5px 10px 5px 5px"
            }}
            className="menu-item"
            href={`/background-process-log/${this.props.event.uuid}`}
            onClick={e => {
                e.preventDefault()
                this.props.redirect(`/background-process-log/${this.props.event.uuid}`);
            }}>
            <div><b>{this.props.t('backgroundProcessLog.proc_' + this.props.event.name)}</b></div>
            <div style={{
                fontSize: '.9em', 
                display: 'flex', 
                paddingTop: 2, 
                flexFlow: 'column',
                alignItems: 'flex-start'
            }}>
                {this.props.event.status === 'start'
                    && <span style={{ color: '#444'}}>
                        <Glyphicon style={glyphiconStyle} glyph="time" />
                        {this.props.t('backgroundProcessLog.status_' + this.props.event.status)}...        
                    </span>
                }
                {this.props.event.status === 'success'
                    && <span style={{ color: '#070'}}>
                        <Glyphicon style={glyphiconStyle} glyph="ok" />
                        {this.props.t('backgroundProcessLog.status_' + this.props.event.status)}.        
                    </span>
                }
                {this.props.event.status === 'failure'
                    && <span style={{ color: '#900'}}>
                        <Glyphicon style={glyphiconStyle} glyph="remove" />
                        {this.props.t('backgroundProcessLog.status_' + this.props.event.status)}.        
                    </span>
                }
                {this.props.event.status === 'cancel'
                    && <span style={{ color: '#900'}}>
                        <Glyphicon style={glyphiconStyle} glyph="minus" />
                        {this.props.t('backgroundProcessLog.status_' + this.props.event.status)}.        
                    </span>
                }
                {
                    this.props.event.name in this.details 
                    && this.details[this.props.event.name](this.props.event)
                }
            </div>
            <div style={{ fontSize: '.7em', marginTop: 3, color: '#444' }}>
                {moment(this.props.event.timestamp).format('DD.MM.YYYY HH:mm:ss')}
            </div>
            {
                this.props.event.status === 'start' &&
                <div className="bgproc-event-loader" />                    
            }
        </MenuItem>
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
    redirect: url => {
        dispatch(push(url));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(
    translate('translations')(BackgroundProcessLogEvent)
  );