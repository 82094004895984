import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { push } from 'react-router-redux';

import * as userActions from '../../redux/user/actions';
import * as representativeActions from '../../redux/representatives/actions';

class ConnectOutlook extends Component {

  constructor(props) {
    super(props)
    this.props.loginInit(
      this.props.match.params.token
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.user !== nextProps.user ) {
      if ( nextProps.user.status === 401 ) {
        this.props.redirect('/');
      } else {
        this.props.startMsAuthFlow({
          ...this.state,
          representativeId: nextProps.user.representative.id
        })
      }
    }
  }

  render() {
    return (
      <div style={{ margin: "10px" }}>Uudelleenohjataan...</div>
    )
  }

}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  loginInit: token => {
    dispatch(userActions.loginInit({token: token}))
  },
  startMsAuthFlow: representative => {
    dispatch(representativeActions.startMsAuthFlow(representative))
  },
  redirect: url => {
    dispatch(push(url));
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ConnectOutlook)
)
