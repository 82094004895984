import { createAction } from 'redux-actions';

export const importContactList = createAction('IMPORT_CONTACT_LIST');
export const importedContactList = createAction('IMPORTED_CONTACT_LIST');

export const getContactLists = createAction('GET_CONTACT_LISTS');
export const gotContactLists = createAction('GOT_CONTACT_LISTS');

export const getOpenContactsCount = createAction('GET_OPEN_CONTACTS_COUNT');
export const gotOpenContactsCount = createAction('GOT_OPEN_CONTACTS_COUNT');

export const removeOpenContacts = createAction('REMOVE_OPEN_CONTACTS');
export const removedOpenContacts = createAction('REMOVED_OPEN_CONTACTS');

export const clearOpenContacts = createAction('CLEAR_OPEN_CONTACTS');
export const clearedOpenContacts = createAction('CLEARED_OPEN_CONTACTS');

export const getCampaign = createAction('GET_CAMPAIGN');
export const gotCampaign = createAction('GOT_CAMPAIGN');

export const patchCampaign = createAction('PATCH_CAMPAIGN');
export const patchedCampaign = createAction('PATCHED_CAMPAIGN');

export const getRepresentatives = createAction('GET_CAMPAIGN_REPRESENTATIVES');
export const gotRepresentatives = createAction('GOT_CAMPAIGN_REPRESENTATIVES');

export const addRepresentative = createAction('ADD_CAMPAIGN_REPRESENTATIVE');
export const addedRepresentative = createAction('ADDED_CAMPAIGN_REPRESENTATIVE');

export const deleteRepresentative = createAction('DELETE_CAMPAIGN_REPRESENTATIVE');
export const deletedRepresentative = createAction('DELETED_CAMPAIGN_REPRESENTATIVE');

export const getBookers = createAction('GET_CAMPAIGN_BOOKERS');
export const gotBookers = createAction('GOT_CAMPAIGN_BOOKERS');

export const addBooker = createAction('ADD_CAMPAIGN_BOOKER');
export const addedBooker = createAction('ADDED_CAMPAIGN_BOOKER');

export const deleteBooker = createAction('DELETE_CAMPAIGN_BOOKER');
export const deletedBooker = createAction('DELETED_CAMPAIGN_BOOKER');
