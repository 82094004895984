import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const admin = handleAction(
  actions.gotAdmin,
  (state, action) => action.payload || state,
  []
);

const admins = handleAction(
  actions.gotAdmins,
  (state, action) => action.payload || state,
  []
);

const patchedAdmin = handleAction(
  actions.patchedAdmin,
  (state, action) => action.payload || state,
  []
);

const postedAdmin = handleAction(
  actions.postedAdmin,
  (state, action) => action.payload || state,
  []
);

const adminsLoading = handleAction(
  actions.setAdminsLoading,
  (state, action) => action.payload,
  false
);

export default combineReducers({
  admin,
  admins,
  patchedAdmin,
  postedAdmin,
  adminsLoading
});
