import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment'
import ReactTags from 'react-tag-autocomplete'
import 'react-input-range/lib/css/index.css'
import InputRange from 'react-input-range'

export class BusinessDbTags extends Component {
    
  handleTagAddition(value, prop) {
    this.props.setState({
      [prop]: [].concat(this.props.state[prop], value)
    })
  }
        
  handleTagDelete(i, prop) {
    if ( !this.props.state[prop].length ) return
    const value = this.props.state[prop].slice(0)
    value.splice(i, 1)
    this.props.setState({[prop]: value})
  }

  handleGetSuggestions(prop) {
    return (this.props.values[prop] || []).filter(value => 
        !this.props.state[prop].find(_value => _value.name === (value.name || value))
      ).map(value => ({id: value.id || value, name: value.name || value}))
  }

  handleBusinessLineTagAddition(value, prop='businessLine') {
    this.props.setState({
      [prop]: [].concat(
        this.props.state[prop].filter(
          _value => _value.id && _value.id.startsWith && !_value.id.startsWith(value.id)
        ), value
      )
    })
  }

  handleToggleCheckbox(prop) {
    this.props.setState({
      [prop]: !this.props.state[prop]
    })
  }

  render() {
    const { values, state, style } = this.props

    const suggestionsTransform = (query, suggestions) =>
      suggestions.filter(
          suggestion => 
            suggestion.name.toLowerCase()
              .indexOf(query.toLowerCase()) >= 0
        )

    const suggestionsTransformByIdOnInt = (query, suggestions) =>
      parseInt(query) == query
        ? suggestions.filter(suggestion => suggestion.id && suggestion.id.startsWith && suggestion.id.startsWith(query))
        : suggestionsTransform(query, suggestions)

    const businessLineSuggestions = ((prop='businessLine') => 
        (values[prop] || [])
          .filter(value => !state[prop].find(_value => _value.name === (value.name || value)))
          .map(value => ({id: value.id || value, name: value.name || value}))
          .filter(value => value.id && value.id.startsWith)
          .filter(value => !state[prop].find(_value => value.id.startsWith(_value.id)))
      )()

    let registeredAtMin = moment().year() - 100
    try {
      registeredAtMin = this.props.values.registeredAt.min
    } catch ( e ) {}

    let registeredAtMax = moment().year()
    try {
      registeredAtMax = Math.max(
        this.props.values.registeredAt.max, 
        this.props.values.registeredAt.min + 1
      )
    } catch ( e ) {}
  
    const exclude = this.props.exclude || []

    return (
      <div style={style || {}}>
        { !exclude.includes('postalCode') &&
          <span className="business-5">
            <ReactTags
                maxSuggestionsLength={1000}
                tags={state.postalCode}
                onAddition={value => this.handleTagAddition(value, 'postalCode')}
                onDelete={i => this.handleTagDelete(i, 'postalCode')}
                suggestions={this.handleGetSuggestions('postalCode')}
                placeholderText={state.postalCode.length ? '' : 'Postinumero...'}
                minQueryLength={0}
                suggestionsTransform={suggestionsTransform}
            />
          </span>
        }
        { !exclude.includes('postalArea') &&
          <span className="business-4">
              <ReactTags
                  maxSuggestionsLength={1000}
                  tags={state.postalArea}
                  onAddition={value => this.handleTagAddition(value, 'postalArea')}
                  onDelete={i => this.handleTagDelete(i, 'postalArea')}
                  suggestions={this.handleGetSuggestions('postalArea')}
                  placeholderText={state.postalArea.length ? '' : 'Postitoimipaikka...'}
                  minQueryLength={0}
                  suggestionsTransform={suggestionsTransform}
              />
          </span>
        }
        { !exclude.includes('form') &&
          <span className="business-3">
              <ReactTags
                  maxSuggestionsLength={1000}
                  tags={state.form}
                  onAddition={value => this.handleTagAddition(value, 'form')}
                  onDelete={i => this.handleTagDelete(i, 'form')}
                  suggestions={this.handleGetSuggestions('form')}
                  placeholderText={state.form.length ? '' : 'Yritysmuoto...'}
                  minQueryLength={0}
                  suggestionsTransform={suggestionsTransform}
              />
          </span>
        }
        { !exclude.includes('businessLine') &&
          <span className="business-2">
              <ReactTags
                  maxSuggestionsLength={1000}
                  tags={state.businessLine}
                  onAddition={value => this.handleBusinessLineTagAddition(value)}
                  onDelete={i => this.handleTagDelete(i, 'businessLine')}
                  suggestions={businessLineSuggestions}
                  placeholderText={state.businessLine.length ? '' : 'Toimiala...'}
                  minQueryLength={0}
                  suggestionsTransform={suggestionsTransformByIdOnInt}
              />
          </span>
        }
        { !exclude.includes('personnelSize') &&
          <span className="business-1">
              <ReactTags
                  maxSuggestionsLength={1000}
                  tags={state.personnelSize}
                  onAddition={value => this.handleTagAddition(value, 'personnelSize')}
                  onDelete={i => this.handleTagDelete(i, 'personnelSize')}
                  suggestions={this.handleGetSuggestions('personnelSize')}
                  placeholderText={state.personnelSize.length ? '' : 'Henkilökuntaluokka...'}
                  minQueryLength={0}
                  suggestionsTransform={suggestionsTransform}
              />
          </span>
        }
        { !exclude.includes('revenueClass') &&
          <span className="business-0">
              <ReactTags
                  maxSuggestionsLength={1000}
                  tags={state.revenueClass}
                  onAddition={value => this.handleTagAddition(value, 'revenueClass')}
                  onDelete={i => this.handleTagDelete(i, 'revenueClass')}
                  suggestions={this.handleGetSuggestions('revenueClass')}
                  placeholderText={state.revenueClass.length ? '' : 'Liikevaihtoluokka...'}
                  minQueryLength={0}
                  suggestionsTransform={suggestionsTransform}
              />
          </span>
        }
        {
          this.props.includeRegisteredAt &&
          <div className="range-container" 
            style={{
              marginBottom: 14, 
              marginTop: 6, 
              marginLeft: 5, 
              marginRight: 5, 
              ...(this.props.rangeStyle || {})
            }}
          >
            <div className="input-label">
              <input type="checkbox" 
                style={{marginRight: 5}} 
                checked={this.props.state.filterByRegistrationDate} 
                onChange={() => this.handleToggleCheckbox('filterByRegistrationDate')}/>
              <div style={{ display: 'inline', minWidth: "180px", maxWidth: "300px", cursor: "pointer" }}
                 onClick={() => this.handleToggleCheckbox('filterByRegistrationDate')}>
                  Perustamisvuosi
              </div>
            </div>
            {
              this.props.state.filterByRegistrationDate &&
              <React.Fragment>
                <div style={{ marginTop: 4 }}></div>
                <InputRange
                  minValue={registeredAtMin}
                  maxValue={registeredAtMax}
                  value={{
                    min: Math.max(registeredAtMin, this.props.state.registeredStartsAt),
                    max: Math.min(registeredAtMax, this.props.state.registeredEndsAt)
                  }}
                  onChangeComplete={value => {
                    this.props.setState({
                      registeredStartsAt: value.min,
                      registeredEndsAt: value.max
                    })
                  }}
                  onChange={value => {
                    this.props.setStateNoReload({
                      registeredStartsAt: value.min,
                      registeredEndsAt: value.max
                    })
                  }} 
                />
              </React.Fragment>
            }
          </div>
        }
        </div>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(BusinessDbTags)
);
