import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

const MsGraphApiProxyModal = ({ proxy, setProxy, show, onClose, t }) => {

    const [url, setUrl] = useState('')
    const [tenant, setTenant] = useState('')
    const [scope, setScope] = useState('')
    const [clientId, setClientId] = useState('')
    const [clientSecret, setClientSecret] = useState('')

    useEffect(() => {
        if ( show ) {
            setUrl(proxy?.url || '')
            setTenant(proxy?.tenant || '')
            setScope(proxy?.scope || '')
            setClientId(proxy?.clientId || '')
            setClientSecret(proxy?.clientSecret || '')
        }
    }, [proxy, show])

    const rowStyle = {
        display: "flex",
        flexFlow: "row",
        marginBottom: "5px"
    }
    const inputLabelStyle = {
        width: "120px",
        minWidth: "120px",
        display: "flex",
        alignItems: "center"
    }
    const inputStyle = {
        height: "30px",
        paddingLeft: "7px",
        border: "solid 1px #d6d6d6",
        borderRadius: "4px",
        textAlign: "left",
        width: "100%",
        fontSize: "14px",
        marginBottom: 0
    }

    return (
        <Modal onHide={onClose} show={show}>
            <Modal.Header closeButton={false}>
                <Modal.Title>{ t('clientCtl.outlookIntegration_proxy') }</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 240, overflow: "auto" }}>
                <div style={ rowStyle }>
                    <div className="input-label" style={ inputLabelStyle }>URL</div>
                    <input style={ inputStyle } type="text" value={ url } onChange={ e => setUrl(e.target.value) }/>
                </div>
                <div style={ rowStyle }>
                    <div className="input-label" style={ inputLabelStyle }>Tenant</div>
                    <input style={ inputStyle } type="text" value={ tenant } onChange={ e => setTenant(e.target.value) }/>
                </div>
                <div style={ rowStyle }>
                    <div className="input-label" style={ inputLabelStyle }>Scope</div>
                    <input style={ inputStyle } type="text" value={ scope } onChange={ e => setScope(e.target.value) }/>
                </div>
                <div style={ rowStyle }>
                    <div className="input-label" style={ inputLabelStyle }>Client ID</div>
                    <input style={ inputStyle } type="text" value={ clientId } onChange={ e => setClientId(e.target.value) }/>
                </div>
                <div style={ rowStyle }>
                    <div className="input-label" style={ inputLabelStyle }>Client secret</div>
                    <input style={ inputStyle } type="text" value={ clientSecret } onChange={ e => setClientSecret(e.target.value) }/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    setProxy({
                        url,
                        tenant,
                        scope,
                        clientId,
                        clientSecret
                    })
                    onClose()
                }}>
                    {t('ok')}
                </Button>
                <Button onClick={onClose}>
                    {t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => ({
})
  
const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(
    translate('translations')(MsGraphApiProxyModal)
  );