import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSms } from '@fortawesome/free-solid-svg-icons'

import { eventTypeToFaIcon } from '../../utils';
import ConfirmationCallModal from './confirmation-call-modal'

moment.locale('fi');

const EventButton = (props) => {
  const {event, key, t} = props
  const icon = eventTypeToFaIcon(event.styleType);
  const doubleIcon = event.styleType === 'notConfirmed' && event.rescheduled
  const marginRight = doubleIcon ? 28 : 16
  const eventType = event.booking ? event.booking.type : 'null'
  const [showModal, setShowModal] = useState(false);

  const handleClickEvent = () => {
    if (event.bookingId === null) {
      props.goToEvent(event.id);
    } else if (event.bookingId !== null) {
      props.goToBooking(event.bookingId);
    }
  }

  const handleSmsClick = (e) => {
    e.stopPropagation();
    window.location.href=`sms:${event.booking.phoneNumber}`
  }

  const handlePhoneClick = (e) => {
    e.stopPropagation();
    window.location.href=`tel:${event.booking.phoneNumber}`
    if( 
      moment().isBefore(moment(event.startsAt))
      && !event.booking.confirmationCallDone
      && !event.booking.bookerConfirmationCall
    ){
      setShowModal(true)
    }
  }
  
  return (
    <div className={`event-button ${event.styleType}`}>
      <div className="btn btn-default" onClick={() => handleClickEvent()} key={key}>
        <div className={`event-type-indicator ${eventType}`}/>
        { event.bookingId && 
        <div className='call-sms-icon'>
          <button onClick={ e => handlePhoneClick(e)}>
            <FontAwesomeIcon icon='phone' size="sm" />
          </button>
          {' '}
          <button onClick={ e => handleSmsClick(e)}>
            <FontAwesomeIcon icon={faSms} size="sm" />
          </button>
        </div> }
        <div className="event-time-title">
          <div className="time"  style={{ overflow: 'hidden', textOverflow: 'ellipsis', marginRight }}>
            { event.booking && (event.booking.contact.contactBusinessType || {}).knownType === 'sok' &&
              <div className="sok-event" />
            }
            {`${moment(event.startsAt).format('H:mm')} - ${moment(
              event.endsAt
            ).format('H:mm')}`}
            { event.shortNote && <span style={{fontWeight: 'normal'}}> {event.shortNote }</span>}
          </div>
          <div className="title" style={{ marginRight, marginLeft: '0px' }}>
            {event.styleType === 'free'
              ? t('representative.bookable')
              : event.title}
          </div>
        </div>
        <div className="event-icon">
          {doubleIcon && 
            <FontAwesomeIcon icon={eventTypeToFaIcon('rescheduled')} className="rbc-event-icon-extra" style={{
              marginRight: 4
            }}/>
          }
          {icon &&
            <FontAwesomeIcon icon={icon} size="sm" />
          }
        </div>
      </div>
      { event.bookingId && 
        <ConfirmationCallModal show={showModal} setShowModal={setShowModal} bookingId={event.bookingId}/>
      }
    </div>
  )
}

EventButton.propTypes = {
  event: PropTypes.object
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  goToBooking: bookingId => {
    dispatch(push(`/bookings/${bookingId}`));
  },
  goToEvent: eventId => {
    dispatch(push(`/events/${eventId}`));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(EventButton)
);
