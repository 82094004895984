import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import DecisionMakerInfoCard from '../decision-maker-info-ext/card'
import {
    Button,
    Glyphicon
  } from 'react-bootstrap';
  
export class BookingParticipants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    }

    render() {
        return <div className="contact-info">
            <div className="heading" style={{ paddingBottom: 0 }}>
                {this.props.t('participants')}
                <Button
                    bsSize="small"
                    onClick={() => this.setState({show: !this.state.show})}
                >
                    {this.state.show ? (
                        <Glyphicon glyph="chevron-up" />
                    ) : (
                        <Glyphicon glyph="chevron-down" />
                    )}
                </Button>
            </div>
            {this.state.show && 
                <div className="info-row wrap" style={{ borderBottom: 0, textAlign: 'left', paddingTop: 0 }}>
                    {(this.props.people || []).map((person, i) => 
                        <DecisionMakerInfoCard 
                            disableGlobalDataFetch={true}
                            noButtons={true}
                            readOnly={true}
                            key={person.id}
                            person={person} 
                            icon="minus"
                        />
                    )}
                </div>
            }
        </div>

    }
}

const mapStateToProps = state => ({
    people: (state.booking.booking.participants || []).map(participant => ({
        ...participant,
        tag: participant.titles.map(title => title.title),
        tag2: participant.positions.map(position => position.position)
    }))
});
  
const mapDispatchToProps = dispatch => ({
});
  
export default connect(mapStateToProps, mapDispatchToProps)(
    translate('translations')(BookingParticipants)
);
  