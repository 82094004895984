import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';

import { ALL_SELECTED } from '../../consts';

export class ReportExtraFilter extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialFilterState(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reportId && nextProps.reportId !== this.props.reportId) {
      // Report changed, filter component is being re-used
      this.setState(this.getInitialFilterState(nextProps));
    }
  }

  getInitialFilterState(props) {
    return {
      filterOptions: props.filter.options,
      notifySelection: props.notifySelection,
      selectedFilterOptionId: undefined
    };
  }

  handleFilterOptionSelect(eventKey) {
    this.setState({
      selectedFilterOptionId: eventKey
    });
    const value = eventKey === ALL_SELECTED ? undefined : eventKey;
    this.props.notifySelection(this.props.filter.name, value);
  }

  filterTitle() {
    if (this.state.selectedFilterOptionId !== undefined) {
      if (this.state.selectedFilterOptionId === ALL_SELECTED) {
        return this.props.t(
          `reports.filterTitles.${this.props.filter.name}All`
        );
      } else {
        return this.props.t(
          `reports.headers.${this.fetchTitleForFilter(
            this.state.selectedFilterOptionId,
            this.state.filterOptions
          )}`
        );
      }
    }
    return this.props.t(`reports.filterTitles.${this.props.filter.name}`);
  }

  fetchTitleForFilter(id, options) {
    return options.find(option => option.id === id).name;
  }

  filterItems() {
    let items = [];
    if (this.state.filterOptions) {
      if (!this.props.filter.mandatory) {
        items.push(
          <MenuItem
            active={this.state.selectedFilterOptionId === ALL_SELECTED}
            eventKey={ALL_SELECTED}
            key={ALL_SELECTED}
            onSelect={eventKey => this.handleFilterOptionSelect(eventKey)}
          >
            {this.props.t(`reports.filterTitles.${this.props.filter.name}All`)}
          </MenuItem>
        );
        items.push(<MenuItem divider={true} key={'divider'} />);
      }
      items = items.concat(
        this.state.filterOptions.map(filterOption => {
          return (
            <MenuItem
              active={filterOption.id === this.state.selectedFilterOptionId}
              eventKey={filterOption.id}
              key={filterOption.id}
              onSelect={eventKey => this.handleFilterOptionSelect(eventKey)}
            >
              {this.props.t(`reports.headers.${filterOption.name}`)}
            </MenuItem>
          );
        })
      );
    }
    return items;
  }

  render() {
    return (
      <div className="id-filter">
        <DropdownButton
          id="select-filter"
          title={this.filterTitle()}
          disabled={this.state.filterOptions.length === 0}
        >
          {this.filterItems()}
        </DropdownButton>
      </div>
    );
  }
}

export default translate('translations')(ReportExtraFilter);
