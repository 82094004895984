import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { Component } from 'react';
import {
  Button,
  DropdownButton,
  Glyphicon,
  MenuItem,
  Modal
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import * as callListActions from '../../redux/call-lists/actions';
import * as callActions from '../../redux/call/actions';
import * as contactActions from '../../redux/contacts/actions';
import * as bookingActions from '../../redux/booking/actions';
import { fillSmsInfo, fillEmailInfo, fillEmailTitleInfo, CONTACT_BOOKING_IDS } from '../../utils';

import { ContactBookingList } from '../../components';

import DatePicker from '../../components/date-picker';
import TimePicker from '../../components/time-picker';
import ReactTags from 'react-tag-autocomplete'

import { authorizedGET } from '../../redux/utils/utils.saga';

const BUSINESS_NOSHOW_BOOKING_ID = 5
const BUSINESS_NOSHOW_BOOKING_IDS = [5, 6, 7, 10]

export class ContactStatus extends Component {
  constructor(props) {
    super(props);

    if (props.contact.id !== undefined && this.props.contact.status.superType !== 'blockList') {
      this.props.getContactStatuses(props.contact.id, props.withBooking);
    }

    const representative = props.servicePoint.representatives 
      ? props.servicePoint.representatives[0]
      : null
    
    let state = {

      // showBooking
      representative,
      sendTextMessage: props.project 
        ? !props.project.disableSendSmsByDefault 
        : true,
      textMessage: ''/*this.getTextMessage(props)*/,
      notes: '',
      bookingStatusId: props.bookingStatusId,
      showFreeTimeList: false,
      invalidTime: false,
      loadingSuggestions: false,
      suggestions: [],
      typeOfBooking: 'servicePoint',
      loadingHasMaxBookings: {},
      hasMaxBookings: {},
      representativeHasMaxBookings: false,
      representativeDisabledWeeks: [],
      servicePoint: props.servicePoint,

      sendEmail: true,
      emailTitle: '',
      emailBody: '',

      userModified: false,
      open: props.currentCallType == null
        && props.parent 
        && props.parent.props.location 
        && props.parent.props.location.hash === '#open'
    }
    if ( this.props.hasOwnProperty('allowSaveDefault') ) {
      state.allowSave = this.props.allowSaveDefault
    }

    if ( props.parent ) {
      props.parent.setState({subStatus: null})
    }

    this.state = state;
  }

  bookingStatuses = ['Toteutuu', 'Peruuntui']

  getCalendar(representative) {
    if ( !representative ) return null
    const calendar = representative.calendars.find(
      calendar => calendar.projectId === this.getServicePoint(this.props).projectId
    )
    if ( !calendar ) {
      return representative.calendars.find(
        calendar => calendar.projectId === null
      )
    } else return calendar
  }

  isBusinessBookingCallback() {
    return this.props.project.customUserInterfaceId === 2
      && this.state.showCallback
  }

  isBusinessBookingContact() {
    return this.props.project.customUserInterfaceId === 2
      && this.state.mainStatusId === 2
  }

  isBookingInBusinessUi() {
    return ['booker', 'teamLeader'].includes(this.props.user.type)
      && this.props.project.customUserInterfaceId === 2
      && this.props.withBooking
  }

  isBusinessBookingCancelled() {
    return this.isBookingInBusinessUi() && 
    BUSINESS_NOSHOW_BOOKING_IDS.includes(this.state.bookingStatusId)
  }

  isBooking() {
    return this.props.status?.superType === 'booking'
  }

  isBusinessBookingMade() {
    return this.isBookingInBusinessUi() && 
    this.isBooking() &&
    this.props.user.type === 'booker'
  }

  isBusinessBookingMadeOrCancelled() {
    return this.isBookingInBusinessUi() && 
    this.isBooking() || this.getMainStatusId() === 10 &&
    this.props.user.type === 'booker'
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.call !== nextProps.call 
      && nextProps.call !== undefined 
      && nextProps.call.active
      && !nextProps.call.origin 
      && nextProps.currentCallType == null
    ) {
      this.setState({open: true})
    }
    if ( this.props.contact !== undefined && 
      this.props.contact.status !== undefined && 
      nextProps.contact !== undefined &&
      nextProps.contact.status !== undefined ) {
      if ( this.props.contact.status.id !== nextProps.contact.status.id ) {
        this.props.getContactStatuses(nextProps.contact.id, this.props.withBooking);
      }
    }
    let userModified = this.state.userModified
    if ( nextProps.contact !== undefined && 
      this.props.contact.id !== nextProps.contact.id ) {
        userModified = false;
        this.setState({userModified: false})
        if ( this.props.parent ) {
          this.props.parent.setState({userModified: false})
        }
    }
    if (
      nextProps.contact !== undefined &&
      nextProps.status !== undefined &&
      !userModified &&
      isEqual(this.props.call, nextProps.call)
    ) {

      if ( this.props.contact.id !== undefined ) {
        let status = null
        outer:
        for ( let i = 0 ; i < this.props.contactStatuses.length ; i ++ ) {
          if ( this.props.contactStatuses[i].id === nextProps.status.id ) {
            status = this.props.contactStatuses[i]
            break
          }
          if ( this.props.contactStatuses[i].subStatuses ) {
            for ( let j = 0 ; j < this.props.contactStatuses[i].subStatuses.length ; j ++ ) {
              if ( this.props.contactStatuses[i].subStatuses[j].id === nextProps.status.id ) {
                status = this.props.contactStatuses[i].subStatuses[j]
                break outer
              }
            }
          }
        }
        if ( !status ) {
          this.props.getContactStatuses(nextProps.contact.id, this.props.withBooking);
        }
      }

      const callbackNotes =
        nextProps.contact.callbackNotes !== null
          ? nextProps.contact.callbackNotes
          : '';

      const contactNotes =
        nextProps.contact.contactNotes !== null
          ? nextProps.contact.contactNotes
          : '';  

      const callbackStartsAt =
        nextProps.contact.callbackStartsAt !== null
          ? nextProps.contact.callbackStartsAt
          : moment().toDate();

      const showCallback = nextProps.status.superType === 'callback';
      const showBooking = ['noShow', 'booking'].includes(nextProps.status.superType) && this.props.withBooking

      let mainStatusId = nextProps.status.isSubStatus
        ? nextProps.status.parentStatusId
        : nextProps.status.id;

      if ( this.isBookingInBusinessUi() && mainStatusId === 10 ) {
        mainStatusId = 6
      }

      const subStatusId = nextProps.status.isSubStatus
        ? nextProps.status.id
        : undefined;

      const showSubStatusList = nextProps.status.isSubStatus;
      let subStatuses = [];
      if (subStatusId !== undefined) {
        subStatuses = this.getSubStatusesById(nextProps.status.parentStatusId);
      }

      const contact = {
        callbackNotes,
        callbackStartsAt: nextProps.bookingStartsAt || callbackStartsAt,
        contactNotes,
        campaignId: nextProps.contact.campaignId,
        id: nextProps.contact.id,
        name: nextProps.contact.name
      };

      const representativeId = nextProps.bookingRepresentativeId ||
        nextProps.contact.representativeId

      const representative = this.getAdjacentRepresentativesByRepresentativeId(
        representativeId, nextProps
      ).find(representative => 
        representative.id === representativeId
      )

      const newState = {
        bookingStatusId: nextProps.bookingStatusId,
        typeOfBooking: nextProps.bookingType 
          || this.getDefaultBookingTypeForRepresentative(representative),
        notes: nextProps.bookingNotes,
        representative,
        contact,
        mainStatusId,
        showCallback,
        showBooking,
        showSubStatusList,
        subStatuses,
        subStatusId,
        init: false
      }

      if ( this.props.parent ) {
        if ( subStatusId !== undefined ) {
          this.props.parent.setState({
            subStatus: nextProps.status.subType
          })
        } else {
          this.props.parent.setState({subStatus: null})
        }
      }

      if ( representative ) {
        if ( nextProps.project.mergeServicePoints ) {
          newState.subArea = this.getSelectedSubAreaForRepresentative(representative.id)
          newState.servicePoint = this.getSelectedServicePointForRepresentative(representative.id)
          const _servicePoint = {...newState.servicePoint}
          delete _servicePoint.representatives
          newState.representative.servicePoint = _servicePoint
        } else {
          newState.servicePoint = representative.servicePoint
        }
      }

      this.setState(newState, this.refreshTemplates);
    }

    if (nextProps.contact.id !== this.props.contact.id) {
      // Contact has changed
      this.props.getContactStatuses(nextProps.contact.id, this.props.withBooking);
    }
  }

  getAdjacentRepresentativesByRepresentativeId(representativeId, props) {
    return (props.servicePoints.find(
      servicePoint => servicePoint.representatives.find(
        representative => representative.id === representativeId
      )
    ) || {representatives: []}).representatives || []
  }
  
  shouldComponentUpdate(nextProps, nextState) {

    if ( this.state.open !== nextState.open ) return true

    // Don't let call actions to reset status selections.
    else if (!isEqual(this.props.call, nextProps.call)) {
      return false;
    }
    return true;
  }

  refreshTemplates() {
    if ( this.state.showBooking ) {
      const textMessage = this.getTextMessage()
      if ( textMessage ) this.setState({textMessage})
      const emailTitle = this.getEmailTitle()
      if ( emailTitle ) this.setState({emailTitle})
      const emailBody = this.getEmailBody()
      if ( emailBody ) this.setState({emailBody})
      
      if ( this.props.contact.project.businessCheckBookingValidity &&
        this.state.representative 
      ) {
        const date = this.state.contact.callbackStartsAt
          ? moment(this.state.contact.callbackStartsAt)
          : moment();
        const projectId = this.getServicePoint(this.props).projectId
        const representativeId = this.state.representative.id
        const params = {
          startsAt: date.startOf('minute').toISOString(),
          type: this.state.typeOfBooking,
          projectId,
          skipBookingId: this.props.bookingId
        }
        authorizedGET(`representatives/${representativeId}/is-booking-time-valid`, {params})
          .then(res => {
            if ( res && res.data ) {
              this.setState({invalidTime: res.data.result !== null})
            } else {
              this.setState({invalidTime: false})
            }
          }).catch(err => {
            this.setState({invalidTime: false})
            console.log(err)
          })
        if ( !this.props.bookingId ) {
          const sparams = {
            date: moment(date).toISOString(),
            endDate: moment(date).toISOString(),
            timezone: 'Europe/Helsinki',
            projectId
          }
          sparams.representativeId = representativeId
          authorizedGET(`statistics/${representativeId}`, {params: sparams})
            .then(res => {
              const {maxBookingCount, n} = res.data
              this.setState({representativeHasMaxBookings: n[0].count >= maxBookingCount})
            }).catch(err => {
              console.error(err)
              this.setState({representativeHasMaxBookings: false})
            })    
        }
      }
    }
  }

  handleDayChange(date) {
    const selectedDate = moment(date);

    // Preserve selected time
    const newDate = this.state.contact.callbackStartsAt
      ? moment(this.state.contact.callbackStartsAt)
      : moment();

    newDate.set({
      date: selectedDate.date(),
      month: selectedDate.month(),
      year: selectedDate.year()
    });

    const contact = Object.assign({}, this.state.contact, {
      callbackStartsAt: newDate.toDate()
    });

    this.setState({
      contact,
      userModified: true
    }, this.refreshTemplates);
    if ( this.props.parent ) {
      this.props.parent.setState({userModified: true})
    }              
  }

  handleNotesChange(event, key='callbackNotes') {
    const contact = Object.assign({}, this.state.contact, {
      [key]: event.target.value
    });

    this.setState({
      contact,
      userModified: true
    });
    if ( this.props.parent ) {
      this.props.parent.setState({userModified: true})
    }
  }

  handleSelectStatus(statusId) {
    const status = this.props.contactStatuses.find(
      status => status.id === statusId
    );
    const stateCallback = () => {
      if ( this.props.onChangeStatus ) {
        this.props.onChangeStatus.bind(this.props.parent)(statusId)
      }  
    }
    if (status.subStatuses.length === 0) {
      this.setState({
        allowSave:
          (status.superType !== 'open' && status.superType !== 'booking') ||
          this.props.status.superType === 'tentative',
        mainStatusId: status.id,
        showCallback: status.superType === 'callback',
        showBooking: status.superType === 'booking' && this.props.withBooking,
        showSubStatusList: false,
        subStatuses: [],
        subStatusId: undefined,
        userModified: true
      }, () => {
        if ( this.props.project.customUserInterfaceId === 2 &&
          status.superType === 'booking' ) {
          this.refreshTemplates()
        }
        stateCallback()
      });
      if ( this.props.parent ) {
        this.props.parent.setState({
          userModified: true,
          subStatus: null
        }, stateCallback)
      }  
    } else {
      const subStatusId = this.props.status !== undefined && 
        this.props.status.id !== undefined
          ? this.props.status.id
          : undefined

      const subStatus = status.subStatuses.find(
        status => status.id === subStatusId
      );
      
      this.setState({
        allowSave: false,
        mainStatusId: statusId,
        showSubStatusList: true,
        showCallback: false,
        showBooking: false,
        subStatuses: status.subStatuses,
        subStatusId: subStatusId,
        userModified: true
      }, stateCallback);
      if ( this.props.parent ) {
        this.props.parent.setState({
          userModified: true,
          subStatus: subStatus ? subStatus.subType : null
        }, stateCallback)
      }  
    }
  }

  handleSelectSubStatus(statusId) {
    const status = this.state.subStatuses.find(
      status => status.id === statusId
    );

    this.setState({
      allowSave: true,
      subStatusId: status.id,
      userModified: true
    });
    if ( this.props.parent ) {
      this.props.parent.setState({
        userModified: true,
        subStatus: status.subType
      })
    }
  }

  handleTimeChange(time) {
    // Preserve selected date
    const newDate = this.state.contact.callbackStartsAt
      ? moment(this.state.contact.callbackStartsAt)
      : moment();

    newDate.set({
      hour: time.hour,
      minute: time.minute
    });

    if ( this.timePicker ) {
      const value = moment(newDate).format('HH:mm')
      this.timePicker.handleChange({target: {value}})
      this.timePicker.input.value = value
    }

    const contact = Object.assign({}, this.state.contact, {
      callbackStartsAt: newDate.toDate()
    });

    this.setState({
      contact,
      userModified: true
    }, this.refreshTemplates);
    if ( this.props.parent ) {
      this.props.parent.setState({userModified: true})
    }
  }

  getMainStatusId() {
    return this.props.status !== undefined && this.props.status.isSubStatus
      ? this.props.status.parentStatusId
      : this.props.status?.id;
  }

  getSubStatusId() {
    return this.state.subStatusId !== undefined
      ? this.state.subStatusId
      : this.props.status !== undefined && this.props.status.id !== undefined
        ? this.props.status.id
        : undefined;
  }

  getSelectedStatusName() {
    let selectedStatus;

    if (this.props.status !== undefined) {
      if (this.state.mainStatusId !== undefined) {
        selectedStatus = this.props.contactStatuses.find(
          status => status.id === this.state.mainStatusId
        );
      } else {
        selectedStatus = this.props.contactStatuses.find(
          status => status.id === ( 
            this.isBookingInBusinessUi() && 
            this.props.statusId === 10 
          ) ? 6 : this.props.status.id
        );
      }

      if (selectedStatus === undefined) {
        return '';
      } else {
        return selectedStatus.name;
      }
    }
    return '';
  }

  getSelectedSubStatusName() {
    try {
      const mainStatusId =
        this.state.mainStatusId !== undefined
          ? this.state.mainStatusId
          : this.props.status.parentStatusId;

      const mainStatus = this.props.contactStatuses
        .find(status => status.id === mainStatusId)

      if ( mainStatus == null ) return '';

      const selectedSubStatus =
        this.state.subStatusId !== undefined
          ? mainStatus.subStatuses.find(
                subStatus => subStatus.id === this.state.subStatusId
              )
          : mainStatus.subStatuses.find(
                subStatus => subStatus.id === this.getSubStatusId()
              );

      if (selectedSubStatus === undefined) {
        return '';
      } else {
        return selectedSubStatus.name;
      }
    } catch ( e ) {
      return ''
    }
  }

  getSubStatusesById(statusId) {
    const status = this.props.contactStatuses.find(
      status => status.id === statusId
    );
    if (status !== undefined && status.subStatuses) {
      return status.subStatuses;
    } else {
      return [];
    }
  }

  getSubStatusName() {
    const parentStatus = this.props.contactStatuses.find(
      status => status.id === this.getMainStatusId()
    );
    const subStatus = parentStatus.subStatuses.find(
      subStatus => subStatus.id === this.props.status.id
    );

    if (subStatus !== undefined) {
      return subStatus.name;
    }
    return '';
  }

  formatRepresentative(representative) {
    return {
      startsAt: this.state.contact.callbackStartsAt,
      address: '',
      representative: {
        emailTemplate: representative ? representative.emailTemplate : null,
        smsTemplate: representative ? representative.smsTemplate : null,
        user: {
          firstName: representative ? (representative.user?.firstName || representative.name?.split(' ')[0]) : '',
          lastName: representative ? (representative.user?.lastName || representative.name?.split(' ')[1]) : '',
          phoneNumber: representative ? representative.phoneNumber : ''
        }
      }
    }
  }

  getEmailTitle() {
    return fillEmailTitleInfo(
      this.formatRepresentative(this.state.representative), 
      this.props.project,
      this.props.client
    )
  }

  getEmailBody() {
    return fillEmailInfo(
      this.formatRepresentative(this.state.representative), 
      this.props.project,
      this.props.client
    )
  }

  getTextMessage() {
    return fillSmsInfo(
      this.formatRepresentative(this.state.representative), 
      this.props.project,
      this.props.client
    )
  }

  handleClickSave() {
    const status =
      this.state.subStatusId !== undefined
        ? this.state.subStatuses.find(
            status => status.id === this.state.subStatusId
          )
        : this.props.contactStatuses.find(
            status => status.id === this.state.mainStatusId
          );
    if (status.superType === 'blockList') {
      this.setState({ showModal: true });
      return;
    } else if ( this.isBookingInBusinessUi() && status.superType === 'booking' ) {
      if ( this.props.parent ) {
        this.props.parent.setState({savePending: true})
      }
      if ( this.props.bookingId && BUSINESS_NOSHOW_BOOKING_IDS.includes(this.state.bookingStatusId) ) {
        if ( this.state.bookingStatusId === this.props.bookingStatusId ) {
          this.props.getContact(this.props.contact.id)
        } else {
          this.props.setBookingStatus(
            this.props.bookingId, 
            this.state.bookingStatusId, 
            this.props.contact.id
          )
        }
        return
      }
      this.props.reserveBookingFinal(
        this.state.representative.id, 
        this.props.contact.id, 
        this.state.contact.callbackStartsAt,
        this.props.tags.map(tag => tag.id),
        this.state.sendEmail && this.props.project.enableEmailInvitations,
        this.state.emailTitle,
        this.state.emailBody,
        this.state.sendTextMessage,
        this.state.textMessage,
        this.state.typeOfBooking,
        this.state.notes,
        this.props.contact.status && this.props.contact.status.superType === 'noShow'
          ? this.props.bookingId
          : null
      )
    } else {      
      if ( this.props.parent ) {
        this.props.parent.setState({savePending: true})
      }
      let publicNotes = null
      if ( status.superType === 'callback' ) {
        publicNotes = this.state.contact.callbackNotes
      } else if ( status.superType === 'contact' ) {
        publicNotes = this.state.contact.contactNotes
      }
      this.props.setContactStatus(
        this.props.contact.id,
        publicNotes,
        this.state.contact.callbackStartsAt,
        status.id,
        status.superType,
        false,
        null,
        this.props.confirmationBookingId,
        this.props.project.customUserInterfaceId === 2 
          ? this.props.tags.map(tag => tag.id)
          : null,
        status.superType === 'callback' && this.props.switchChecked
      );
    }
  }

  onDelete (i) {
    if ( !this.props.tags.length ) return
    const tags = this.props.tags.slice(0)
    /*const tag = */tags.splice(i, 1)
    this.props.parent.setState({tags})
    this.setState({checked: false})
  }
 
  onAddition (tag) {
    const tags = [].concat(this.props.tags, tag)
    this.props.parent.setState({tags})
    this.setState({checked: false})
  }

  isSaveDisabled() {

    let val;

    if ( this.isBookingInBusinessUi() && CONTACT_BOOKING_IDS.includes(this.state.mainStatusId) ) {

      const missingRequiredContactData = this.props.contact.projectAdditionalContactInfo
        .filter(a => a.required)
        .find(a => !this.props.contact.contactData.find(b => b.title === a.title) ||
          !this.props.contact.contactData.find(b => b.title === a.title).value)

      if ( missingRequiredContactData ) {
        val = true
      } else if ( !this.state.representative 
          || this.props.tags.length === 0 
          || ( 
            this.props.contact.project.businessCheckBookingValidity 
            && (this.state.invalidTime || this.state.representativeHasMaxBookings)
            && !BUSINESS_NOSHOW_BOOKING_IDS.includes(this.state.bookingStatusId) 
      ) ) {
        val = true
      } else {
        val = false
      }
    } else if (
      this.state.subStatuses !== undefined &&
      this.state.subStatuses.length > 0 &&
      this.state.subStatusId === undefined
    ) {
      // Only parent status selected
      val = true;
    } else if (
      this.state.showCallback &&
      (this.state.contact.callbackNotes === undefined ||
        (this.state.contact.callbackNotes !== undefined &&
          this.state.contact.callbackNotes.length === 0))
    ) {
      // Status is callback but notes is empty
      val = true;
    } else if (!this.state.showCallback && 
        this.props.project.customUserInterfaceId === 2 &&
        this.state.mainStatusId === 2
      ) {
      val = false;
    } else if (!this.state.showCallback && !this.state.allowSave) {
      // Open or booked status
      val = true;
    } else {
      val = false;
    }

    if ( this.props.parent ) {
      this.props.parent.setState({
        liftDisabled: this.state.mainStatusId !== 1 && 
          this.state.mainStatusId !== 9 && 
          val,
        contactMainStatusId: this.state.mainStatusId,
        bookingStatusId: this.state.bookingStatusId
      })
    }
    return val;
  }

  closeModal(block) {
    if (block) {
      if ( this.props.parent ) {
        this.props.parent.setState({savePending: true})
      }
      this.props.setContactStatus(
        this.props.contact.id,
        '',
        '',
        this.state.subStatusId,
        'blockList',
        !this.props.callFlow,
        {
          ...this.props.contact,
          status: this.props.contactStatuses.find(
            status => status.id === this.state.mainStatusId
          )
        },
        this.props.confirmationBookingId
      );
    }
    this.setState({ showModal: false });
  }

  toggle() {
    this.setState({open: !this.state.open})
  }

  getDefaultBookingTypeForRepresentative(representative) {
    const calendar = this.getCalendar(representative)
    if ( calendar ) {
      let type = this.state.typeOfBooking
      for ( let i = 0 ; i < 2 ; i ++ ) {
        if ( type === 'servicePoint' && !calendar.enableServicePoint ) {
          type = 'remote'
        }
        if ( type === 'remote' && !calendar.enableRemote ) {
          type = 'call'
        }
        if ( type === 'call' && !calendar.enableCall ) {
          type = 'online'
        }
        if ( type === 'online' && !calendar.enableOnline ) {
          type = 'servicePoint'
        }
      }
      return type
    } else {
      return 'servicePoint'
    }
  }

  getSuggestions() {
    if ( this.state.showBooking && this.state.representative && this.props.contact.project.businessCheckBookingValidity ) {
      const current = this.state.contact.callbackStartsAt
        ? moment(this.state.contact.callbackStartsAt)
        : moment()
      const params = {
        startsAtOrAfter: moment(current).startOf('day').format(),
        endsBefore: moment(current).endOf('day').format(),
        projectId: this.getServicePoint(this.props).projectId
      }
      this.setState({loadingSuggestions: true})
      authorizedGET(`representatives/${this.state.representative.id}/suggestions`, {params})
        .then(res => {
          this.setState({
            suggestions: res.data,
            loadingSuggestions: false
          })
        }).catch(err => {
          this.setState({
            suggestions: [],
            loadingSuggestions: false
          })
        })
    }
  }

  updateRepresentativeDisabledWeeks(representativeId, date) {
    if ( this.props.contact.project.businessCheckBookingValidity && !this.props.bookingId ) {
      const projectId = this.getServicePoint(this.props).projectId
      const params = {
        date: moment(date).startOf('month').toISOString(),
        endDate: moment(date).endOf('month').toISOString(),
        timezone: 'Europe/Helsinki',
        projectId
      }
      params.representativeId = representativeId
      authorizedGET(`statistics/${representativeId}`, {params})
        .then(res => {
          const {maxBookingCount, n} = res.data
          this.setState({
            representativeDisabledWeeks: 
              n.filter(a => a.count >= maxBookingCount)
                .map(a => a.week)
          })
        }).catch(err => {
          console.error(err)
          this.setState({representativeDisabledWeeks: []})
        })  
    }
  }

  getServicePoint(props) {
    if ( props.servicePoint && props.servicePoint.id ) return props.servicePoint
    else if ( props.contact && props.contact.representativeId ) {
      const servicePoint = props.servicePoints.find(
        servicePoint => servicePoint.representatives.find(
          representative => representative.id === props.contact.representativeId
        )
      )
      if ( servicePoint ) {
        return servicePoint
      }
    }
    return {}
  }

  updateMaxBookingCount() {
    const date = this.state.contact.callbackStartsAt
      ? moment(this.state.contact.callbackStartsAt)
      : moment();
    const projectId = this.getServicePoint(this.props).projectId
    let servicePointId = this.state.representative
      ? this.state.representative.servicePoint?.id
      : this.props.servicePoint.id

    if ( !servicePointId ) {
      const servicePoint = this.getServicePoint(this.props)
      servicePointId = servicePoint.id
    }

    const params = {
      date: date.toISOString(),
      endDate: date.toISOString(),
      timezone: 'Europe/Helsinki',
      projectId
    }
    this.setState({loadingHasMaxBookings: true}, () => {
      authorizedGET(`statistics/service-point/${servicePointId}`, {params})
      .then(res => {
        const hasMaxBookings = {}
        res.data.forEach(a => {
          if ( a.n[0].count >= a.maxBookingCount ) {
            hasMaxBookings[a.representativeId] = true
          }
        })
        this.setState({
          hasMaxBookings,
          loadingHasMaxBookings: false
        })
      }).catch(err => {
        console.error(err)
        this.setState({
          loadingHasMaxBookings: false,
          hasMaxBookings: {}
        })
      })
    })
  }

  getParticipantTagComponent(title, placeholder, includeSwitch=false) {
    const tags = this.props.tags || []
    const people = this.props.people || []
    return <div className="info-row" style={{width: '100%', borderBottom: 0}}>
      <div className={'info-row-column double' + (tags.length ? '' : ' no-tags')} style={{ width: '90%' }}>
        <div className="heading" style={{fontSize: 14, marginTop: 10, paddingBottom: 6}}>
          { title }
        </div>
        <div style={
          ( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) || ( includeSwitch && this.props.switchChecked ) 
            ? {pointerEvents: 'none', cursor: 'not-allowed', opacity: .5}
            : {} 
        }>
          <ReactTags
            maxSuggestionsLength={1000}
            ref={this.reactTags}
            tags={tags}
            suggestions={
              ( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) ? [] : 
                people.filter(person => person.id >= 0)
                  .filter(person => !tags.find(tag => tag.name === person.name))
            }
            onDelete={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) ? () => {} : this.onDelete.bind(this)}
            onAddition={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) ? () => {} : this.onAddition.bind(this)}
            placeholderText={tags.length ? '' : placeholder}
            minQueryLength={0}
            suggestionsTransform={(query, suggestions) =>
                  suggestions.filter(
                      suggestion => 
                        suggestion.name.toLowerCase()
                          .indexOf(query.toLowerCase()) >= 0
                    )
            }
          />
        </div>
        { includeSwitch &&
          <div className="heading" style={{fontSize: 14, fontWeight: 'normal', marginTop: 2, paddingBottom: 6}}>
            <input
                name="switch"
                type="checkbox"
                checked={this.props.switchChecked}
                onChange={e => this.toggleSwitch(e)}
              />
              <span style={{cursor: 'pointer'}} 
                onClick={e => this.toggleSwitch(e)}>
                  Vaihde
              </span>
          </div>
        }
      </div>
    </div>
  }

  getContactListComponent() {
    return this.props.contact?.bookings?.length ?
      <div className="contact">
          <div className="bookings" style={{
              width: '100%', 
              padding: 10, 
              margin: '5px 20px 20px 10px', 
              boxShadow: '1px 1px 3px rgba(0,0,0,.2)',
              borderRadius: 10,
              border: 0
          }}>
            <div className="title" style={{ fontSize: 20 }}>
              {this.props.t('booking.bookings')}
            </div>
            <ContactBookingList 
              contact={this.props.contact} 
              reloadOnCancelOrDelete={
                this.props.callFlow && 
                this.props.project?.customUserInterfaceId === 2
              }
              businessCallFlow={
                this.props.callFlow &&
                this.props.project?.customUserInterfaceId === 2
              }
            />
        </div>
      </div>
    : null
  }

  toggleSwitch = e => {
    this.props.parent.setState({
      switchChecked: !this.props.switchChecked
    })
  }

  getSelectedSubArea(subAreaId) {
    if ( subAreaId ) {
      return this.props.subAreas.find(
        subArea => subArea.id === subAreaId
      );
    } else {
      const representativeId = this.state.representative?.id
      const subArea = this.props.subAreas.find(subArea => {
        if ( subArea?.representatives.find(representative => representative.id === representativeId) ) {
          return subArea
        }
      })
      return subArea
    }
  }

  getSelectedServicePointForRepresentative(representativeId) {
    const servicePoint = this.props.servicePoints.find(servicePoint => {
      if ( servicePoint?.representatives.find(representative => representative.id === representativeId) ) {
        return servicePoint
      }
    })
    return servicePoint
  }

  getSelectedSubAreaForRepresentative(representativeId) {
    const subArea = this.props.subAreas.find(subArea => {
      if ( subArea?.representatives.find(representative => representative.id === representativeId) ) {
        return subArea
      }
    })
    return subArea
  }

  getSelectedSubAreaName(subAreaId, defaultName='') {
    const subArea = this.getSelectedSubArea(subAreaId);
    return subArea
      ? subArea.name
      : defaultName;
  }

  getRepresentativeName(representative) {
    return representative.name || `${representative.user?.firstName} ${representative.user?.lastName}`
  }

  render() {
    const disabledRange = []
    if ( this.state.showBooking || this.state.showCallback ) {
      if ( this.state.showBooking && 
        this.state.representative && 
        !this.props.bookingId &&
        this.state.representativeDisabledWeeks.length 
      ) {
        disabledRange.push(day => 
          this.state.representativeDisabledWeeks.includes(
            moment(day).week()
          )
        )
      }
      if (( this.props.contact && 
        this.props.contact.project && 
        this.props.contact.project.disableBookingTimeRange 
        ) || this.props.project.disableBookingTimeRange 
      ) {
  
      } else {
        disabledRange.push({
          after: moment()
            .add(45, 'days')
            .toDate()  
        })
      }
    }

    return (
      <React.Fragment>
      <div className="contact-status" style={
          this.props.callFlow && ( this.state.showBooking || this.isBusinessBookingContact() || this.isBusinessBookingCallback() )
            ? {
              borderBottom: 0, 
              paddingBottom: this.isBusinessBookingContact() || this.isBusinessBookingCallback() || this.props.project.businessAllowServicePointChange 
                ? 0 : 20
            } : {}
      }>
        <div 
          style={this.state.showBooking ? {flex: '0 1 25%'} : {}}
          className={
            "status-column" + (
              this.props.parent !== undefined
                ? ` status-column-wide ${
                  (!this.state.showSubStatusList && !this.state.showCallback && !this.state.showBooking)
                    ? "status-column-wide-single"
                    : ""
                  }` 
                : ""
              )
            }
          >
          <div className="heading">{this.props.t('status.status')}</div>
          <DropdownButton
            disabled={ (this.props.disableStatusIfBooked && 
                this.props.status &&
                this.props.status.superType === 'booking') ||
                this.isBusinessBookingMadeOrCancelled() 
            }
            open={this.state.open}
            id="select-status"
            title={this.getSelectedStatusName()}
            onToggle={this.toggle.bind(this)}
          >
            {this.props.contactStatuses.map(status => {
              if ( this.isBookingInBusinessUi() && status.superType === 'noShow' ) {
                return null
              }
              return (
                <MenuItem
                  active={status.id === this.state.mainStatusId}
                  eventKey={status.id}
                  key={status.id}
                  onSelect={eventKey => this.handleSelectStatus(eventKey)}
                >
                  {status.name}
                </MenuItem>
              );
            })}
          </DropdownButton>
          {
            this.state.showBooking &&
            <div style={{ marginTop: 10 }}>
            <DropdownButton
              disabled={!this.props.bookingId}
              id="select-booking-status"
              title={this.bookingStatuses[BUSINESS_NOSHOW_BOOKING_IDS.includes(this.state.bookingStatusId) ? 1 : 0]}
            >
            {this.bookingStatuses.map((outcome, i) => {
              return (
                <MenuItem
                  active={BUSINESS_NOSHOW_BOOKING_IDS.includes(this.state.bookingStatusId) ? i === 1 : i === 0}
                  eventKey={i}
                  key={i}
                  onSelect={eventKey => {
                    let bookingStatusId = 0
                    if ( eventKey ) {
                      bookingStatusId = BUSINESS_NOSHOW_BOOKING_ID
                    } else {
                      if ( BUSINESS_NOSHOW_BOOKING_IDS.includes(this.props.bookingStatusId) ) {
                        bookingStatusId = 1
                      } else bookingStatusId = this.props.bookingStatusId
                    }
                    this.setState({bookingStatusId, userModified: true})
                    if ( this.props.parent ) {
                      this.props.parent.setState({userModified: true})
                    }              
                  }}
                >
                  {outcome}
                </MenuItem>
              );
            })}
          </DropdownButton>
          </div>
          }
        </div>

        {this.state.showSubStatusList && (
          <div className={ "status-column" + (this.props.parent !== undefined ? " status-column-wide status" : "") }>
            <div className="heading">{this.props.t('status.specify')}</div>
            <DropdownButton
              id="select-sub-status"
              title={this.getSelectedSubStatusName()}
            >
              {this.getSubStatusesById(this.state.mainStatusId).map(status => {
                return (
                  <MenuItem
                    active={status.id === this.getSubStatusId()}
                    eventKey={status.id}
                    key={status.id}
                    onSelect={eventKey => this.handleSelectSubStatus(eventKey)}
                  >
                    {status.name}
                  </MenuItem>
                );
              })}
            </DropdownButton>
          </div>
        )}

        {(this.state.showCallback || this.state.showBooking) && (
          <div className={ 
              "status-column" + (this.props.parent !== undefined 
                ? " status-column-wide status" : "") 
              }
              style={this.state.showBooking ? {flex: '0 1 25%'} : {}}
            >
            <div className="heading">{this.props.t(this.state.showCallback ? 'callback' : 'booking.booking')}</div>
            <div style={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) 
              ? {pointerEvents: 'none', cursor: 'not-allowed', opacity: .5}
              : {}
            }>
              <div className={this.state.showBooking ? "picker-row booking" : "picker-row"}>
                <Glyphicon glyph="calendar" />
                <DatePicker
                  style={{color: this.state.representativeHasMaxBookings ? 'red' : 'unset'}}
                  onMonthChange={month => {
                    if ( this.state.showBooking && this.state.representative ) {
                      const date = moment(month).startOf('day')
                      this.updateRepresentativeDisabledWeeks(this.state.representative.id, date)
                    }
                  }}
                  date={
                    this.state.contact.callbackStartsAt
                      ? moment(this.state.contact.callbackStartsAt).toDate()
                      : moment().toDate()
                  }
                  disabledRange={
                    disabledRange 
                      ? disabledRange.length > 1
                        ? disabledRange
                        : disabledRange[0]
                      : undefined
                  }
                  onDayChange={date => {
                    this.handleDayChange(date);
                  }}
                />
              </div>
              <div className={this.state.showBooking ? "picker-row booking" : "picker-row"}>
                <Glyphicon glyph="time" />
                {
                  this.state.showBooking
                    ? <div style={{ 
                      color: this.state.invalidTime || this.state.representativeHasMaxBookings
                        ? 'red' : 'inherit', 
                      width: '75%', 
                      position: 'relative' 
                    }}>
                      <TimePicker
                        style={{ minWidth: '100%' }}
                        onRef={ref => {this.timePicker = ref}}
                        onFocus={() => {
                          if ( this.props.contact.project.businessCheckBookingValidity ) {
                            this.setState({showFreeTimeList: true})
                            if ( this.state.representative && 
                              (this.props.bookingId || !this.state.representativeHasMaxBookings) 
                            ) {
                              this.getSuggestions()
                            } else {
                              this.setState({suggestions: []})
                            }
                          }
                        }}
                        onBlur={e => {
                          if ( this.props.contact.project.businessCheckBookingValidity ) {
                            e.persist()
                            setTimeout(() => {
                              this.setState({showFreeTimeList: false})
                              if ( e.target ) e.target.blur()
                            }, 250)
                            e.preventDefault()
                          }
                        }}
                        disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() )}
                        date={
                          this.state.contact.callbackStartsAt
                            ? moment(this.state.contact.callbackStartsAt).toDate()
                            : moment().toDate()
                        }
                        onTimeChange={time => {
                          this.handleTimeChange(time);
                        }}
                      />
                      { this.state.showFreeTimeList &&
                        this.state.representative &&
                        <div className="free-time-list">
                          {
                            this.state.loadingSuggestions &&
                            <div>
                              <div style={{ height: 75 }} className="busy-indicator">
                                <div className="spinner" 
                                  style={{
                                    maxHeight: 50,
                                    maxWidth: 50,
                                    borderWidth: 10
                                  }}
                                />
                              </div>
                            </div>
                          }
                          { !this.state.loadingSuggestions &&
                            !this.state.suggestions.length > 0 &&
                             <div style={{ margin: '4px 12px', color: '#aaa' }}>Ei vapaita aikoja.</div>
                          }

                          { !this.state.loadingSuggestions &&
                            this.state.suggestions.length
                              ? this.state.suggestions.filter(value => 
                                  value.type === ( 
                                    this.state.typeOfBooking === 'servicePoint'
                                      ? 'primary' : this.state.typeOfBooking
                                  ) && 
                                  value.representativeId === this.state.representative.id
                                ).map((value, i) => {
                                  const currentDate = this.state.contact.callbackStartsAt
                                    ? moment(this.state.contact.callbackStartsAt)
                                    : moment()
                                  const startsAt = moment(value.startsAt)
                                  const endsAt = moment(value.endsAt)
                                  if ( currentDate.day() !== startsAt.day() ||
                                    currentDate.month() !== startsAt.month() ||
                                    currentDate.year() !== startsAt.year() ) {
                                      return null
                                    }
                                  const style = startsAt.hour() === currentDate.hour() &&
                                    startsAt.minute() === currentDate.minute()
                                    ? {
                                      color: 'white', 
                                      backgroundColor: '#337ab7'
                                    } : {}
                                  return <div key={i} style={style} className="item" onClick={() => {
                                      const time = {
                                        hour: startsAt.hour(),
                                        minute: startsAt.minute()
                                      }
                                      this.handleTimeChange(time);
                                      this.setState({showFreeTimeList: false})
                                    }}>
                                      { startsAt.format('HH:mm') } - { endsAt.format('HH:mm') }
                                    </div>
                                })
                              : null
                          }
                        </div>
                      }
                    </div>
                  : <TimePicker
                    disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() )}
                    date={
                      this.state.contact.callbackStartsAt
                        ? moment(this.state.contact.callbackStartsAt).toDate()
                        : moment().toDate()
                    }
                    onTimeChange={time => {
                      this.handleTimeChange(time);
                    }}
                  />  
                }
              </div>
            </div>
          </div>
        )}

        {this.state.showBooking && (
          <div className={ "status-column" + (this.props.parent !== undefined ? " status-column-wide status" : "") }>
            <div style={{ display: 'flex', flexFlow: 'row' }}>
              <div style={{ flex: 1 }}>
                <div className="heading" style={{ fontSize: 14, marginTop: 8 }}>{this.props.t('representative.representative')}</div>
                {
                  this.props.project.businessAllowServicePointChange && this.props.project.mergeServicePoints &&
                  <>
                    <DropdownButton
                        disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() )}
                        id="select-sub-area"
                        title={
                          this.getSelectedSubAreaName(
                            this.state.subArea?.id, 
                            'Valitse tulosalue...'
                          )
                        }
                      >
                      { this.props.subAreas.map(subArea => {
                          return (
                            <MenuItem
                              active={subArea.id === this.state.subArea?.id}
                              eventKey={subArea}
                              key={subArea.id}
                              onSelect={subArea => {
                                const representative = subArea.representatives[0]
                                const newState = { representative, subArea }
                                if ( representative && this.props.contact.project.businessCheckBookingValidity ) {
                                  newState.typeOfBooking = this.getDefaultBookingTypeForRepresentative(representative)
                                }
                                this.setState(newState, this.refreshTemplates)
                              }}
                            >
                              <span>{subArea.name}</span>
                            </MenuItem>
                          );
                        })
                      }
                    </DropdownButton>
                    <div style={{ marginBottom: 10 }}></div>
                  </>
                }
                {
                  this.props.project.businessAllowServicePointChange && !this.props.project.mergeServicePoints &&
                  <>
                    <DropdownButton
                        disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() )}
                        id="select-service-point"
                        title={
                          this.state.servicePoint && this.state.servicePoint.id
                            ? this.state.servicePoint.name
                            : 'Valitse toimipiste...'
                        }
                      >
                      { this.props.servicePoints.map(servicePoint => {
                          return (
                            <MenuItem
                              active={this.state.servicePoint
                                && servicePoint.id === this.state.servicePoint.id
                              }
                              eventKey={servicePoint}
                              key={servicePoint.id}
                              onSelect={servicePoint => {
                                const representative = servicePoint.representatives[0]
                                const newState = { representative, servicePoint }
                                if ( representative && this.props.contact.project.businessCheckBookingValidity ) {
                                  newState.typeOfBooking = this.getDefaultBookingTypeForRepresentative(representative)
                                }
                                this.setState(newState, this.refreshTemplates)
                              }}
                            >
                              <span>{servicePoint.name}</span>
                            </MenuItem>
                          );
                        })
                      }
                    </DropdownButton>
                    <div style={{ marginBottom: 10 }}></div>
                  </>
                }
                <DropdownButton
                    disabled={this.isBusinessBookingCancelled() || this.isBusinessBookingMade()}
                    onToggle={isOpen => {
                      if ( isOpen ) {
                        if ( this.props.contact.project.businessCheckBookingValidity && !this.props.bookingId ) {
                          this.updateMaxBookingCount()
                        } else this.setState({loadingHasMaxBookings: false})
                      }
                    }}
                    id="select-representative"
                    title={
                      this.state.representative
                        ? this.getRepresentativeName(this.state.representative)
                        : 'Valitse edustaja...'
                    }
                  >
                  {
                    this.state.loadingHasMaxBookings &&
                    <div style={{ height: 75 }} className="busy-indicator">
                      <div className="spinner" 
                        style={{
                          maxHeight: 50,
                          maxWidth: 50,
                          borderWidth: 10
                        }}
                      />
                    </div>
                  }
                  { !this.state.loadingHasMaxBookings &&

                    ( this.props.project.mergeServicePoints
                      ? (this.state.subArea?.representatives || [])
                      : (this.state.representative 
                        ? this.getAdjacentRepresentativesByRepresentativeId(this.state.representative.id, this.props)
                        : this.props.servicePoint.representatives || this.getServicePoint(this.props).representatives || []
                      )
                    ).map(representative => {
                      return (
                        <MenuItem
                          active={this.state.representative
                            && representative.id === this.state.representative.id
                          }
                          eventKey={representative}
                          key={representative.id}
                          onSelect={representative => {
                            const newState = { representative }
                            if ( this.props.contact.project.businessCheckBookingValidity ) {
                              newState.typeOfBooking = this.getDefaultBookingTypeForRepresentative(representative)
                            }
                            this.setState(newState, this.refreshTemplates)
                          }}
                        >
                          <span style={{ 
                            color: !this.props.bookingId && 
                              this.state.hasMaxBookings[representative.id] 
                                ? 'red' : 'unset' 
                          }}>{this.getRepresentativeName(representative)}</span>
                        </MenuItem>
                      );
                    })
                  }
                </DropdownButton>
              </div>
              <div style={{ flex: 1 }}>
              <div className="heading" style={{ fontSize: 14, marginTop: 8 }}>{this.props.t('booking.typeOfBooking')}</div>
                  <DropdownButton
                    disabled={
                      ( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) ||
                      ( !this.state.representative && !this.getCalendar(this.state.representative) )
                    }
                    id="select-type-of-booking"
                    title={
                      (
                        !this.state.representative ||
                        !this.getCalendar(this.state.representative)
                      ) ? '' :
                      this.props.t(
                        'booking.type' +
                        this.state.typeOfBooking.substring(0, 1).toUpperCase() + 
                        this.state.typeOfBooking.substring(1)
                      )
                    }
                  >
                  {
                    (() => {
                      const calendar = this.getCalendar(this.state.representative)
                      const items = []
                      if ( calendar ) {
                        if ( calendar.enableServicePoint ) {
                          items.push(
                            <MenuItem
                              active={this.state.typeOfBooking ==='servicePoint'}
                              eventKey={'servicePoint'}
                              key={'servicePoint'}
                              onSelect={typeOfBooking => {
                                this.setState(
                                  {typeOfBooking}, 
                                  this.refreshTemplates
                                )
                              }}
                            >
                              {this.props.t('booking.typeServicePoint')}
                            </MenuItem>
                          )
                        }
                        if ( calendar.enableRemote ) {
                          items.push(
                            <MenuItem
                              active={this.state.typeOfBooking ==='remote'}
                              eventKey={'remote'}
                              key={'remote'}
                              onSelect={typeOfBooking => {
                                this.setState(
                                  {typeOfBooking}, 
                                  this.refreshTemplates
                                )
                              }}
                            >
                              {this.props.t('booking.typeRemote')}
                            </MenuItem>
                          )
                        }
                        if ( calendar.enableCall ) {
                          items.push(
                            <MenuItem
                              active={this.state.typeOfBooking ==='call'}
                              eventKey={'call'}
                              key={'call'}
                              onSelect={typeOfBooking => {
                                this.setState(
                                  {typeOfBooking}, 
                                  this.refreshTemplates
                                )
                              }}
                            >
                              {this.props.t('booking.typeCall')}
                            </MenuItem>
                          )
                        }
                        if ( calendar.enableOnline ) {
                          items.push(
                            <MenuItem
                              active={this.state.typeOfBooking ==='online'}
                              eventKey={'online'}
                              key={'online'}
                              onSelect={typeOfBooking => {
                                this.setState(
                                  {typeOfBooking}, 
                                  this.refreshTemplates
                                )
                              }}
                            >
                              {this.props.t('booking.typeOnline')}
                            </MenuItem>
                          )
                        }
                      }
                      return items
                    })()
                  }
                </DropdownButton>
              </div>
            </div>
            { !this.props.project.businessAllowServicePointChange && this.props.callFlow &&
              this.getParticipantTagComponent('Osallistujat', 'Lisää osallistujia...') }
          </div>
        )}

        {this.state.showCallback && (
          <div className={ "status-column" + (this.props.parent !== undefined ? " status-column-wide status" : "") }>
            <div className="heading">{this.props.t('notes')}</div>
            <textarea
              value={this.state.contact.callbackNotes}
              onChange={event => this.handleNotesChange(event)}
            />
          </div>
        )}
        <div style={{
          display: this.props.parent === undefined
            ? "flex"
            : "none"
          }}
          className="status-column save">
          <Button
            bsStyle="primary"
            className="save"
            disabled={this.isSaveDisabled()}
            onClick={() => this.handleClickSave()}
          >
            {this.props.t('save')}
          </Button>
        </div>
        <Modal onHide={this.closeModal} show={this.state.showModal}>
          <Modal.Header closeButton={false}>
            <Modal.Title>
              {this.props.t('call.confirmAddToBlocklist')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={() => this.closeModal(true)}>
              {this.props.t('yes')}
            </Button>
            <Button onClick={() => this.closeModal(false)}>
              {this.props.t('no')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      { ( this.isBusinessBookingContact() || this.isBusinessBookingCallback() ) && this.props.callFlow &&
        <div className="reservation" style={{ display: 'flex', flexFlow: 'column', borderBottom: this.isBusinessBookingContact() ? '' : 'solid 1px #d6d6d6' }}>
          { this.getParticipantTagComponent('Päättäjät', 'Lisää päättäjiä...', this.isBusinessBookingCallback()) }
        </div>
      }

      { this.isBusinessBookingContact() && this.props.callFlow &&
        <div className="reservation" style={{ display: 'flex', flexFlow: 'column', borderBottom: 'solid 1px #d6d6d6' }}>
          <div className="info-row" style={{width: '100%', borderBottom: 0, paddingTop: 0}}>
            <div className="info-row-column double" 
              style={{ marginTop: this.props.project.enableEmailInvitations ? 8 : 0 }}>
              <div className="heading" style={{height: 22}}>
                <span>Lisätiedot päämiehelle</span>
              </div>
              <textarea
                style={{ height: 107 }}
                disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() )}
                onChange={event => this.handleNotesChange(event, 'contactNotes')}
                value={this.state.contact.contactNotes || ''}
                placeholder='Lisätiedot...'
              />
            </div>
          </div>
        </div>
      }

      { this.state.showBooking &&
        <div className="reservation" style={{ display: 'flex', flexFlow: 'column' }}>
          { 
            this.props.project.businessAllowServicePointChange && this.props.callFlow && 
            <>
              {this.getParticipantTagComponent('Osallistujat', 'Lisää osallistujia...')}
              {this.getContactListComponent()}
            </>
          }
          { 
            !this.props.project.businessAllowServicePointChange && this.props.callFlow && 
            this.getContactListComponent()
          }
          <div className="info-row" style={{
            width: '100%', flexFlow: 'row', flexWrap: 'wrap', paddingTop: 4,
            ...(( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) 
              ? {pointerEvents: 'none', cursor: 'not-allowed', opacity: .5}
              : {}
            ) 
          }}>
          <div className="info-row-column double">
              <div className="heading" style={{height: 22}}>
                <input
                  disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() )}
                  name="sendTextMessage"
                  type="checkbox"
                  checked={this.state.sendTextMessage}
                  onChange={() => this.setState({sendTextMessage: !this.state.sendTextMessage})}
                />
                <span style={{cursor: 'pointer'}} onClick={() => {
                  this.setState({sendTextMessage: !this.state.sendTextMessage})
                }}>Lähetä osallistujille vahvistus tekstiviestillä</span>
              </div>
              <textarea
                style={{ height: 107 }}
                disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) || !this.state.sendTextMessage}
                onChange={event => this.setState({textMessage: event.target.value})}

                placeholder={'Viesti...'/*this.state.sendTextMessage ? '' : this.state.textMessage*/}
                value={this.state.textMessage || ''}
              />
            </div>
          {
            this.props.project.enableEmailInvitations &&
            <div className="info-row-column double">
              <div className="heading">
                <input
                  name="sendEmail"
                  type="checkbox"
                  disabled={ ( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) || this.props.reservation === null }
                  checked={ this.props.reservation !== null && this.state.sendEmail }
                  onChange={() => this.setState({
                    sendEmail: !this.state.sendEmail
                  })}
                />
                <span style={{cursor: this.props.reservation === null ? 'not-allowed' : 'pointer'}} onClick={() => {
                  if ( this.props.reservation !== null ) {
                    this.setState({sendEmail: !this.state.sendEmail})
                  }
                }}>Lähetä osallistujille sähköpostikutsu{/*this.props.t('booking.sendEmail')*/}</span>
              </div>
              <input 
                placeholder={'Otsikko'}
                disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) || this.props.reservation === null || !this.state.sendEmail}
                style={{ fontSize: '13px', width: '95%', border: '1px solid #ccc', padding: 5, borderRadius: 2, marginBottom: 2 }} 
                onChange={event => this.setState({ emailTitle: event.target.value })}
                value={this.state.emailTitle ? this.state.emailTitle : ''}
              />
              <textarea
                disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() ) || this.props.reservation === null || !this.state.sendEmail}
                onChange={event => this.setState({ emailBody: event.target.value })}
                placeholder={'Viesti...'/*this.state.sendEmail ? '' : this.state.emailBody*/}
                value={this.state.emailBody ? this.state.emailBody : ''}
              />
            </div>
          }
            <div className="info-row-column double" 
              style={{ marginTop: this.props.project.enableEmailInvitations ? 8 : 0 }}>
              <div className="heading" style={{height: 22}}>
                <span>Lisätiedot päämiehelle</span>
              </div>
              <textarea
                style={{ height: 107 }}
                disabled={( this.isBusinessBookingCancelled() || this.isBusinessBookingMade() )}
                onChange={event => this.setState({notes: event.target.value})}
                value={this.state.notes || ''}
                placeholder='Lisätiedot...'
              />
            </div>
          </div>
        </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  call: state.call.call,
  contact: state.contacts.contact,
  contactStatuses: state.contacts.contactStatuses,
  includeNotInterested: state.callLists.includeNotInterested,
  onlyOwn: state.callLists.onlyOwn,
  status: state.contacts.contact.status,
  user: state.user,
  currentCallType: state.callLists.current,
  project: state.contacts.contact?.project || {},
  client: state.call.selectedClient,
  servicePoint: state.call.selectedServicePoint,
  servicePoints: state.servicePoints.servicePoints,
  suggestions: state.call.suggestions.suggestions || [],
  subAreas: state.projects.subAreas
});

const mapDispatchToProps = dispatch => ({
  getContact: contactId => {
    dispatch(contactActions.getContact({contactId}));
  },
  setBookingStatus: (bookingId, statusId, getContactId) => {
    dispatch(bookingActions.setBookingStatus({bookingId, statusId, getContactId}));
  },
  reserveBookingFinal: (representativeId, contactId, startsAt, participantIds, sendEmail, emailTitle, emailBody, sendTextMessage, textMessage, type, notes, bookingId) => {
    dispatch(bookingActions.reserveBooking({
      representativeId, contactId, startsAt, final: true, participantIds, sendEmail, emailTitle, emailBody, sendTextMessage, textMessage, type, notes, bookingId
    }))
  },
  getCallbacks: () => {
    dispatch(callListActions.getCallbacks());
  },
  getContactCard: campaignId => {
    dispatch(contactActions.getContactCard());
  },
  getContactStatuses: (contactId, withBooking=false) => {
    dispatch(
      contactActions.getContactStatuses({
        contactId,
        withBooking
      })
    );
  },
  setContactStatus: (
    contactId, 
    publicNotes, 
    startsAt, 
    statusId, 
    superType, 
    noNextAfterBlock=false, 
    contact=null, 
    confirmationBookingId=null, 
    participantIds=null,
    switchChecked=false
  ) => {
    dispatch(
      contactActions.setContactStatus({
        contactId,
        publicNotes,
        startsAt,
        statusId,
        superType,
        noNextAfterBlock,
        contact,
        confirmationBookingId,
        participantIds,
        switchChecked
      })
    );
  },
  getTentatives: () => {
    dispatch(callListActions.getTentatives());
  },
  resetCurrentCallType: () => {
    dispatch(callListActions.resetCurrentCallType());
  },
  getSuggestions: (campaignId, representativeId, servicePointId, projectId, startsAt, endsAt) => {
    dispatch(
      callActions.getSuggestions({
        campaignId,
        representativeId,
        servicePointId,
        projectId,
        startsAt,
        endsAt
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {withRef:true})(
  translate('translations', {withRef:true})(ContactStatus)
);
