import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTags from 'react-tag-autocomplete'
import 'react-input-range/lib/css/index.css'

import * as callActions from '../../redux/call/actions';

export class ContactInfoTags extends Component {

  handleTagAddition(value) {
    this.props.onChange([...this.props.values, value.name])
  }
        
  handleTagDelete(i) {
    if ( !this.props.values.length ) return
    const value = this.props.values.slice(0)
    value.splice(i, 1)
    this.props.onChange(value)
  }

  handleGetSuggestions() {
    const options = this.props.options.map(option => ({
      id: option,
      name: option
    }))
    return (options || []).filter(value => 
      !this.props.values.find(_value => _value === (value.name || value))
    ).map(value => ({id: value.id || value, name: value.name || value}))
  }

  render() {

    const suggestionsTransform = (query, suggestions) =>
      this.props.readOnly ? [] : 
      suggestions.filter(suggestion =>
        suggestion.name.toLowerCase()
          .includes(query.toLowerCase())
      )
      
    return (
      <div style={this.props.style}>
        <span className={'contact-info-tags' + (this.props.values.length ? '' : ' no-tags')}
          style={this.props.readOnly ? {pointerEvents: 'none'} : {}}>
          <ReactTags
              maxSuggestionsLength={1000}
              tags={this.props.values.map(value => ({id: value, name: value}))}
              onAddition={this.props.readOnly ? ()=>{} : value => this.handleTagAddition(value)}
              onDelete={this.props.readOnly ? ()=>{} : i => this.handleTagDelete(i)}
              suggestions={this.handleGetSuggestions()}
              placeholderText={this.props.values.length ? '' : 'Valitse...'}
              minQueryLength={0}
              suggestionsTransform={suggestionsTransform}
          />
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: state.projects.campaigns,
  selectedCampaigns: state.call.selectedCampaigns.map(
    campaign => state.projects.campaigns.find(_campaign => _campaign.id === campaign.id) || {id: campaign.id}
  ),
});

const mapDispatchToProps = dispatch => ({
  setSelectedCampaigns: campaigns => {
    dispatch(callActions.setSelectedCampaigns(campaigns));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ContactInfoTags)
);
