import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as userActions from '../../redux/user/actions';

class LoginRepresentativeBooking extends Component {

  constructor(props){
    super(props)
    this.props.loginRepresentativeBooking(this.props.match.params.token)
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if ( this.props.user !== nextProps.user ) {
      if ( nextProps.user.status === 401 ) {
        this.props.redirect('/');
      } else {
        this.props.redirect(`/bookings/${this.props.match.params.id}/_`);
      }
    }
  }

  render() {
    return (
      <div style={{ margin: "10px" }}>{ this.props.t('loggingIn')}</div>
    )
  }

}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  loginRepresentativeBooking: token => {
    dispatch(
      userActions.loginRepresentativeBooking({
        token: token
      })
    )
  },
  redirect: url => {
    dispatch(push(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(LoginRepresentativeBooking)
);
