import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';

import * as actions from './actions';

import moment from 'moment';

const currentDay = handleAction(
  actions.setCurrentDay,
  (state, action) => action.payload || state,
  moment().toDate()
);

const event = handleActions(
  {
    [actions.gotEvent]: (state, action) => action.payload || state,
    [actions.setEvent]: (state, action) => action.payload || state
  },
  {}
);

const events = handleAction(
  actions.gotEvents,
  (state, action) => action.payload || state,
  []
);

const bgEvents = handleAction(
  actions.gotBgEvents,
  (state, action) => action.payload || state,
  []
);

export default combineReducers({
  currentDay,
  event,
  events,
  bgEvents
});
