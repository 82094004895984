import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const keys = handleAction(
    actions.gotApiAccessKeys,
    (state, action) => action.payload,
    []
);
  
const setKeys = handleAction(
    actions.setApiAccessKeysDone,
    (state, action) => action.payload,
    []
);

export default combineReducers({
    keys,
    setKeys
});