import { put, takeEvery, select } from 'redux-saga/effects';
import * as userActions from '../user/actions';
import * as loginRedirectActions from '../loginRedirect/actions';
import * as actions from './actions';
import { push } from 'react-router-redux';

function* requestFailed(action){

    const s = action.payload && action.payload.message
        ? action.payload.message
        : action.payload

    const data = action.payload && action.payload.data
        ? action.payload.data
        : null


    /*

        TODO: Interception should be done before the message is composed!

    */


    const expiredToken = s && s.endsWith('(expiredToken)');
    if ( expiredToken ) {
        yield put(userActions.logout())
        return
    }

    const invalidMfaCode = s 
        && (
            s.endsWith('(invalidMfaCode)') ||
            s.endsWith('(invalidMfaCode_)')
        );
    if ( invalidMfaCode ) {
        if ( data && data.error && data.error.payload ) {
            const minimalUser = data.error.payload
            const currentUser = yield select(state => state.user)
            if ( minimalUser.id !== currentUser.id ) {
                yield put(userActions.loggedIn(minimalUser))
            }
        }
        const codePath = '/code'
        if ( s.endsWith('(invalidMfaCode)') ) {
            const redirectPath = yield select(state => state.router.location.pathname)
            if ( redirectPath && redirectPath !== codePath ) {
                yield put(loginRedirectActions.setMfaRedirect(redirectPath))
            } else {
                yield put(loginRedirectActions.setMfaRedirect('/'))
            }
        }
        yield put(
            push({
              pathname: codePath,
              redirect: codePath
            })
          );      
        return
    }
}
  
export const watchRequestFailed = takeEvery(
    actions.requestFailed().type,
    requestFailed
  );