import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const processHeartbeat = handleAction(
    actions.gotProcessHeartbeat,
    (state, action) => action.payload,
    []
);

export default combineReducers({
    processHeartbeat
});