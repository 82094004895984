import React, { Component } from 'react'

class NotFoundView extends Component {
  render() {
    return (
      <div className="not-found">
        <span>Sorry, page was not found.</span>
      </div>
    );
  }
}

export default NotFoundView;
