import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const booker = handleAction(
  actions.gotBooker,
  (state, action) => action.payload || state,
  []
);

const bookers = handleAction(
  actions.gotBookers,
  (state, action) => action.payload || state,
  []
);

const callHistoryBookers = handleAction(
  actions.gotCallHistoryBookers,
  (state, action) => action.payload || state,
  []
);

const postBooker = handleAction(
  actions.postedBooker,
  (state, action) => action.payload || state,
  []
);

const patchedBooker = handleAction(
  actions.patchedBooker,
  (state, action) => action.payload || state,
  []
);

const callSession = handleAction(
  actions.gotCallSession,
  (state, action) => action.payload || state,
  []
);

const bookersLoading = handleAction(
  actions.setBookersLoading,
  (state, action) => action.payload,
  false
);

export default combineReducers({
  booker,
  bookers,
  callHistoryBookers,
  postBooker,
  patchedBooker,
  callSession,
  bookersLoading
});
