import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

const RepresentativeStats = ({statistics, t}) => {

  const getStatistic = (title, statistic, target = false) => {
    if (statistic !== undefined) {
      return (
        <div className="statistic">
          <span>{title}</span>
          {target && statistic.target !== null ? ((
            Array.isArray(statistic.current) && 
            Array.isArray(statistic.target) &&
            <div className="booking-counts">
              {statistic.current.map(current => 
                <div className="booking-count-row">
                  <b>
                    {current.value}/{statistic.target.find(
                      stat => stat.projectId === current.projectId
                    ).value}
                  </b>
                  {current.name}
                </div>
              )}
            </div>) ||
            <span>
              {statistic.current}/{statistic.target}
            </span>
          ) : (
            <span>{statistic.current}</span>
          )}
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="representative-stats">
      {statistics.current !== undefined && (
        <div className="statistics">
          {getStatistic(
            t('cancelled'),
            statistics.current.cancelled
          )}
          {getStatistic(
            t('newBookings'),
            statistics.current.newBookings,
            true
          )}
          {getStatistic(
            t('rescheduledTo'),
            statistics.current.rescheduled
          )}
          {getStatistic(
            t('rescheduledFrom'),
            statistics.current.rescheduledFrom || '0'
          )}
          {getStatistic(
            t('unconfirmed'),
            statistics.current.unconfirmed
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  statistics: state.statistics
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(RepresentativeStats)
);
