import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Alert, Glyphicon } from 'react-bootstrap'
import moment from 'moment';
import * as notificationActions from '../../redux/notification/actions';

export class Notification extends Component {

  componentWillMount() {
    this.props.getNotifications(this.props.includeRead)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.includeRead !== this.props.includeRead || nextProps.getNotifications !== this.props.getNotifications) {
      nextProps.getNotifications(nextProps.includeRead)
    }
  }

  render() {

    const { t, notification, setNotificationRead } = this.props

    const filteredNotifications = notification
      .filter(notification => !notification.read && moment().isAfter(notification.displayAt))

    return <div className="container" style={{ padding: 0, marginTop: filteredNotifications.length ? 10 : 0 }}>
    {
      filteredNotifications.map((notification, i) => 
        <Alert bsStyle="danger" 
          style={{ 
            marginBottom: 0, 
            marginTop: i > 0 ? 2 : 0, 
            whiteSpace: 'pre-wrap' 
          }} onDismiss={() => setNotificationRead(notification.id)}>
          <div>
            <b>
              <Glyphicon glyph="exclamation-sign" style={{ marginRight: 4 }}/>
              {t('notificationCtl.notificationFromAdmin')}
            </b>
          </div>
          <div style={{ marginTop: 2 }}>
            {notification.content}
          </div>
        </Alert>
      )
    }
  </div>

  }

}

Notification.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  notification: state.notification.notifications
});

const mapDispatchToProps = dispatch => ({
  getNotifications: includeRead => {
    dispatch(notificationActions.getNotifications(includeRead));
  },
  setNotificationRead: id => {
    dispatch(notificationActions.setNotificationRead({id}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(Notification)
);
