import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';
import moment from 'moment';

import { NOSHOW_ONLY } from '../../consts';
import * as actions from './actions';

const CONFIRMATION_ONLY = { ...NOSHOW_ONLY }

const callbacks = handleAction(
  actions.gotCallbacks,
  (state, action) => action.payload || state,
  []
);

const startConfirmation = handleActions(
  {
    [actions.setStartConfirmation]: (state, action) => action.payload,
  },
  false
);

const startNoShowModal = handleActions(
  {
    [actions.setStartNoShowModal]: (state, action) => action.payload,
  },
  null
);

const current = handleActions(
  {
    [actions.setCurrentCallType]: (state, action) => action.payload,
    [actions.resetCurrentCallType]: (state, action) => null
  },
  null
);

const noShowBookings = handleAction(
  actions.gotNoShowBookings,
  (state, action) => action.payload || state,
  []
);

const tentatives = handleAction(
  actions.gotTentatives,
  (state, action) => action.payload || state,
  []
);

const confirmations = handleAction(
  actions.gotConfirmations,
  (state, action) => action.payload || state,
  []
);

const includeNotInterested = handleActions(
  {
    [actions.setIncludeNotInterested]: (state, action) => !state,
    [actions.setIncludeNotInterestedWithValue]: (state, action) => action.payload,
  },
  false
);

const onlyOwn = handleAction(
  actions.setOnlyOwn,
  (state, action) => 
    state === action.payload
        ? NOSHOW_ONLY.disabled
        : action.payload,
  NOSHOW_ONLY.own
);

const noShowSokContacts = handleAction(
  actions.setNoShowSokContacts,
  (state, action) => action.payload || false,
  true
);

const noShowPostalAreas = handleAction(
  actions.gotNoShowPostalAreas,
  (state, action) => action.payload || state,
  []
);

const selectedNoShowServicePoint = handleAction(
  actions.setSelectedNoShowServicePoint,
  (state, action) => action.payload || {},
  {}
);

const selectedNoShowBooker = handleAction(
  actions.setSelectedNoShowBooker,
  (state, action) => action.payload || {},
  {}
);

const selectedNoShowPostalAreas = handleAction(
  actions.setSelectedNoShowPostalAreas,
  (state, action) => action.payload || state,
  []
);

const selectedNoShowRepresentatives = handleAction(
  actions.setSelectedNoShowRepresentatives,
  (state, action) => action.payload || state,
  []
);

const selectedNoShowYear = handleAction(
  actions.setSelectedNoShowYear,
  (state, action) => action.payload,
  moment().year()
);

const selectedNoShowMonth = handleAction(
  actions.setSelectedNoShowMonth,
  (state, action) => action.payload,
  null
);

const selectedNoShowStatus = handleAction(
  actions.setSelectedNoShowStatus,
  (state, action) => action.payload || [],
  []
);

const selectedConfirmationServicePoint = handleAction(
  actions.setSelectedConfirmationServicePoint,
  (state, action) => action.payload,
  {}
);

const selectedConfirmationRepresentatives = handleAction(
  actions.setSelectedConfirmationRepresentatives,
  (state, action) => action.payload,
  []
);

const selectedConfirmationBooker = handleAction(
  actions.setSelectedConfirmationBooker,
  (state, action) => action.payload,
  {}
);

const confirmationOnlyOwn = handleAction(
  actions.setConfirmationOnlyOwn,
  (state, action) => action.payload,
  CONFIRMATION_ONLY.own
);

const confirmationScheduled = handleAction(
  actions.setConfirmationScheduled,
  (state, action) => action.payload,
  true
);

const additionalContactInfoFilter = handleAction(
  actions.setAdditionalContactInfoFilter,
  (state, action) => action.payload,
  null
);

const isLoading = handleAction(
  actions.setIsLoading,
  (state, action) => Object.assign({}, state, action.payload || {}),
  {}
);

export default combineReducers({
  callbacks,
  current,
  includeNotInterested,
  noShowBookings,
  onlyOwn,
  tentatives,
  confirmations,
  noShowPostalAreas,
  noShowSokContacts,
  selectedNoShowServicePoint,
  selectedNoShowPostalAreas,
  selectedNoShowRepresentatives,
  selectedNoShowYear,
  selectedNoShowMonth,
  selectedNoShowStatus,
  selectedConfirmationServicePoint,
  selectedConfirmationRepresentatives,
  selectedConfirmationBooker,
  confirmationOnlyOwn,
  confirmationScheduled,
  startConfirmation,
  startNoShowModal,
  additionalContactInfoFilter,
  selectedNoShowBooker,
  isLoading
});
