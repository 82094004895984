import { handleActions } from 'redux-actions';

import * as actions from './actions';

export default handleActions(
  {
    [actions.closed().type]: () => 'closed',
    [actions.connect().type]: () => 'connecting',
    [actions.connecting().type]: () => 'connecting',
    [actions.disconnect().type]: () => 'disconnecting',
    [actions.open().type]: () => 'open'
  },
  'disconnected'
);
