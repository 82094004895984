import { combineReducers } from 'redux';
import * as actions from './actions';
import { handleAction, handleActions } from 'redux-actions';

const events = handleActions(
  {
    [actions.gotEvents]: (state, action) => action.payload || [],
    [actions.setEvents]: (state, action) => action.payload || [],
    [actions.addEvent]: (state, action) => [action.payload, ...state]
  },
  []
);

const event = handleAction(
  actions.gotEvent,
  (state, action) => action.payload || state,
  {}
);

export default combineReducers({events, event})