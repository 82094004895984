import { CLIENTADMIN, BOOKER, REPRESENTATIVE, TEAMLEADER, ADMIN } from './consts';

export const REST_API_BASE_URL = !process.env.REACT_APP_BACKEND_URL
//  ? 'http://94.199.118.76:3000/v1'
  ? 'https://localhost:443/v1'
  : process.env.REACT_APP_BACKEND_URL + '/v1';
export const WEBSOCKET_API_URL = !process.env.REACT_APP_WEBSOCKET_URL
//  ? 'ws://94.199.118.76:3000'
  ? 'wss://localhost:443'
  : process.env.REACT_APP_WEBSOCKET_URL;

export const COOKIE_CONSENT_V = parseInt(process.env.REACT_APP_CURRENT_COOKIE_CONSENT_V || 0)

export const DEFAULT_ROUTES = {
  [BOOKER]: '/call/contacts',
  [REPRESENTATIVE]: '/representative',
  [TEAMLEADER]: '/call/contacts',
  [CLIENTADMIN]: '/calendars',
  [ADMIN]: '/clients'
};

// Calendar events
export const EVENT_RECURRENCE = ['never', 'weekly', 'daily'];
export const BOOKABLE_EVENT_RECURRENCE = ['weekly'];
export const EVENT_TYPES = ['personal', 'free'];

export const TIMES_OF_DAY = {
  morning: [8, 12],
  afternoon: [12, 17],
  evening: [17, 20]
};

// Break
export const BREAK_TIMEOUT = 300000; // milliseconds
