import { createAction } from 'redux-actions';

export const getContact = createAction('GET_CONTACT');
export const gotContact = createAction('GOT_CONTACT');
export const gotContactData = createAction('GOT_CONTACT_DATA');

export const getContacts = createAction('GET_CONTACTS');
export const gotContacts = createAction('GOT_CONTACTS');

export const getContactCard = createAction('GET_CONTACT_CARD');
export const gotContactCard = createAction('GOT_CONTACT_CARD');

export const resetContact = createAction('RESET_CONTACT');

export const setContactData = createAction('SET_CONTACT_DATA');

export const setContactStatus = createAction('SET_CONTACT_STATUS');

export const setContactNotes = createAction('SET_CONTACT_NOTES');
export const setContactNotesDone = createAction('SET_CONTACT_NOTES_DONE');

export const getContactStatuses = createAction('GET_CONTACT_STATUSES');
export const gotContactStatuses = createAction('GOT_CONTACT_STATUSES');

export const setRepresentative = createAction('SET_REPRESENTATIVE');
export const setContactRepresentative = createAction('SET_CONTACT_REPRESENTATIVE');

export const setContactServicePoint = createAction('SET_CONTACT_SERVICE_POINT');
export const setContactCardServicePoint = createAction('SET_CONTACT_CARD_SERVICE_POINT');

export const setContactSubArea = createAction('SET_CONTACT_SUB_AREA');
export const setContactCardSubArea = createAction('SET_CONTACT_CARD_SUB_AREA');

export const gotPostalAreas = createAction('GOT_CONTACT_POSTAL_AREAS');

export const patchContact = createAction('PATCH_CONTACT');

export const postContact = createAction('POST_CONTACT');
export const postedContact = createAction('POSTED_CONTACT');
export const copyContact = createAction('COPY_CONTACT');

export const getContactsForRemoval = createAction('GET_CONTACTS_FOR_REMOVAL');
export const gotContactsForRemoval = createAction('GOT_CONTACTS_FOR_REMOVAL');
export const clearContactsForRemoval = createAction('CLEAR_CONTACTS_FOR_REMOVAL');
export const removeContacts = createAction('REMOVE_CONTACTS');
export const removedContacts = createAction('REMOVED_CONTACTS');

export const downloadContactViewLog = createAction('DOWNLOAD_CONTACT_VIEW_LOG');

export const getBookerForContact = createAction('GET_BOOKER_FOR_CONTACT');
export const setBookerForContact = createAction('SET_BOOKER_FOR_CONTACT');
export const gotBookerForContact = createAction('GOT_BOOKER_FOR_CONTACT');

export const clearContactsForReassignment = createAction('CLEAR_CONTACTS_FOR_REASSIGNMENT');
export const getContactsForReassignment = createAction('GET_CONTACTS_FOR_REASSIGNMENT');
export const gotContactsForReassignment = createAction('GOT_CONTACTS_FOR_REASSIGNMENT');
export const reassignContacts = createAction('REASSIGN_CONTACTS');
export const reassignedContacts = createAction('REASSIGNED_CONTACTS');

export const removeBundle = createAction('REMOVE_BUNDLE');
export const patchBundle = createAction('PATCH_BUNDLE');
export const postBundle = createAction('POST_BUNDLE');
export const setBundlePatched = createAction('SET_BUNDLE_PATCHED');

export const getDecisionMakerTitles = createAction('GET_DECISION_MAKER_TITLES');
export const gotDecisionMakerTitles = createAction('GOT_DECISION_MAKER_TITLES');

export const getDecisionMakerPositions = createAction('GET_DECISION_MAKER_POSITIONS');
export const gotDecisionMakerPositions = createAction('GOT_DECISION_MAKER_POSITIONS');

export const getDecisionMakers = createAction('GET_DECISION_MAKERS');
export const gotDecisionMakers = createAction('GOT_DECISION_MAKERS');

export const getDecisionMakerCounts = createAction('GET_DECISION_MAKER_COUNTS');
export const setDecisionMakerCounts = createAction('SET_DECISION_MAKER_COUNTS');
export const resetDecisionMakerCounts = createAction('RESET_DECISION_MAKER_COUNTS');

export const copyDecisionMaker = createAction('COPY_DECISION_MAKER');
export const removeDecisionMaker = createAction('REMOVE_DECISION_MAKER');
export const patchDecisionMaker = createAction('PATCH_DECISION_MAKER');
export const postDecisionMaker = createAction('POST_DECISION_MAKER');
export const modifiedDecisionMaker = createAction('MODIFIED_DECISION_MAKER');

export const getDecisionMakerStatistics = createAction('GET_DECISION_MAKER_STATISTICS');
export const gotDecisionMakerStatistics = createAction('GOT_DECISION_MAKER_STATISTICS');

export const setRequestState = createAction('SET_REQUEST_STATE');

export const setContactCardModified = createAction('SET_CONTACT_CARD_MODIFIED');