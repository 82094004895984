import moment from 'moment';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../redux/reports/actions';

import { DatePicker } from '../../components';

export class ReportTimeFilters extends Component {
  constructor(props) {
    super(props);

    this.state = this.getFilterInitialState();

    if (this.props.notifyFiltersChanged !== undefined) {
      this.props.notifyFiltersChanged({
        startsAt: this.state.startsAt,
        endsAt: this.state.endsAt,
        valid: moment(this.state.endsAt).isAfter( this.state.startsAt)
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reportId !== this.props.reportId) {
      this.setState(this.getFilterInitialState());
      if (nextProps.notifyFiltersChanged !== undefined) {
        this.props.notifyFiltersChanged({
          startsAt: this.state.startsAt,
          endsAt: this.state.endsAt,
          valid: moment(this.state.endsAt).isAfter( this.state.startsAt)
        });
      }
    }
  }

  getFilterInitialState() {
    return this.props.timeFilterValues;
  }

  handleDayChange(value, option) {
    let endsAt = this.state.endsAt;
    let startsAt = this.state.startsAt;
    switch (option) {
      case 'endsAt':
        const formattedEndsAt = moment(value)
          .endOf('day')
          .utc()
          .format();
        this.setState({
          endsAt: formattedEndsAt
        });
        endsAt = formattedEndsAt;
        break;
      case 'startsAt':
        const formattedStartsAt = moment(value)
          .startOf('day')
          .utc()
          .format();
        this.setState({
          startsAt: formattedStartsAt
        });
        startsAt = formattedStartsAt;
        break;
      default:
        break;
    }
    this.props.setTimeFilterValues({startsAt, endsAt})
    this.props.notifyFiltersChanged({
      startsAt, endsAt,
      valid: moment(endsAt).isAfter(startsAt)
    });
  }

  render() {
    return (
      <div className="time-filters">
        <DatePicker
          date={moment(this.state.startsAt).toDate()}
          onDayChange={date => {
            this.handleDayChange(date, 'startsAt');
          }}
          displayFormat={'L'}
        />
        <span>-</span>
        <DatePicker
          date={moment(this.state.endsAt).toDate()}
          onDayChange={date => {
            this.handleDayChange(date, 'endsAt');
          }}
          displayFormat={'L'}
        />
      </div>
    );
  }
}


const mapStateToProps = state => ({
  timeFilterValues: state.reports.opts.datetime
});

const mapDispatchToProps = dispatch => ({
  setTimeFilterValues: ({startsAt, endsAt}) => {
    dispatch(
      actions.setReportOpts({
        datetime: {
          startsAt, endsAt
        }
      })
    )
  }  
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ReportTimeFilters)
);
