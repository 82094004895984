import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox } from 'react-bootstrap'
import { COOKIE_CONSENT_V } from '../../config'

/**
 * CookieConsent Modal that is shown in login window when 
 * users browser does not have the latest CookieConsent variable set.
 */
const CookieConsent = () => {
  const [show, setShow] = useState(false)
  const [choice, setChoice] = useState({
    version: COOKIE_CONSENT_V,
    all: false,
    dashboard: false
  })

  useEffect(() => {
    let oldConsent = window.localStorage.getItem('CookieConsent')
    if (oldConsent) oldConsent = JSON.parse(oldConsent)
    if(!oldConsent || oldConsent.version !== COOKIE_CONSENT_V){
      setShow(true)
    }
  },[show])

  const consentToStorage = () => {
    window.localStorage.setItem('CookieConsent', JSON.stringify(choice))
    setShow(false)
  }

  const handleChoice = ( newChoice ) => {
    setChoice({ ...choice, ...newChoice })
  }

  if (show) {
    return (
      <div className="cookie-consent-modal">
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Cookie / localStorage Consent</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            Käytämme selaimesi työmuistia tarjotaksemme...
            <Checkbox onChange={(event)=>handleChoice({all: !choice.all})}> Kaikki </Checkbox>
            <Checkbox onChange={(event)=>handleChoice({dashboard: !choice.dashboard})}> 2.vaihtoehto </Checkbox>
          </Modal.Body>

          <Modal.Footer>
            <Button bsStyle="primary" onClick={consentToStorage}> Tallenna valinta</Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    ) 
  }
  else return null
}

export default CookieConsent;