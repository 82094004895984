import { combineReducers } from 'redux';
import * as actions from './actions';
import { handleAction } from 'redux-actions';

const importedBlocklist = handleAction(
    actions.importedBlocklist, 
    (state, action) => action.payload,
    {count: 0}
  );
  
const removedBlocklist = handleAction(
    actions.removedBlocklist, 
    (state, action) => action.payload,
    {count: 0}
  );

const gotBlocklistCount = handleAction(
  actions.gotBlocklistCount, 
  (state, action) => action.payload,
  {count: 0}
);

export default combineReducers({
    importedBlocklist,
    removedBlocklist,
    gotBlocklistCount
})