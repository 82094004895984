import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import TelavoxLogo from '../../assets/images/telavox.jpg';

import {
  Button,
  Glyphicon,
  MenuItem,
  Modal,
  Nav,
  Navbar,
  NavItem,
  NavDropdown
} from 'react-bootstrap';
import { translate } from 'react-i18next';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Search } from '../';
import { BackgroundProcessLogEvent } from '../'
import { IdleTimer } from '../';

import * as userActions from '../../redux/user/actions';
import * as worktimeActions from '../../redux/worktime/actions';
import * as socketActions from '../../redux/socket/actions';
import BookersLogo from '../../assets/images/bookers2.png';
import * as callActions from '../../redux/call/actions';
import * as bgprocActions from '../../redux/background-process-log/actions';

import {
  CLIENTADMIN,
  BOOKER,
  REPRESENTATIVE,
  SEARCH_TYPES,
  TEAMLEADER,
  ADMIN
} from '../../consts';
import { DEFAULT_ROUTES } from '../../config';
import { WEBSOCKET_API_URL } from '../../config';

const BGPROC_TYPES = [
  "all",
  "refresh_business_coordinates",
  "remove_expired_data",
  "netvisor_workdayhours",
  "sync_telavox_calls",
  "send_sok_marketing_ban_list_email",
  "release_old_callbacks",
  "anonymize",
  "remove_contacts",
  "remove_open_contacts",
  "import_contacts",
  "import_contacts_init",
  "import_businesses",
  "update_businesses",
  "remove_businesses",
  "outlook_sync",
  "import_decision_makers",
  "decrypt_project_additional_contact_info",
  "reload_contact_cache",
  "reload_call_cache",
  "outlook_state_check"
]

export class TopBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      elapsedWorktime: this.getElapsedWorktime(),
      showLogoutModal: false,
      showEndCallModal: false,
      showBgprocEventTypeModal: false,
      userDisconnectedSocket: false,
      searchString: '',
      showResults: false,
      bgprocTypes: this.props.user.type === ADMIN
        ? (this.props.user?.admin?.bgprocType || '').split(',')
        : []
    };

    this.timer = null;
  }

  UNSAFE_componentWillMount() {
    console.log('adding window event listeners');
    window.addEventListener('online', () => {
      console.log('network connection: online');
    });
    window.addEventListener('offline', () => {
      console.log('network connection: offline');
    });
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      if (this.props.worktime.breakStarted === null) {
        this.setState({
          elapsedWorktime: this.getElapsedWorktime()
        });
      }
    }, 60000); // Update each minute
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.socket !== nextProps.socket && nextProps.socket !== undefined) {
      console.log('Socket`s new state: ' + nextProps.socket);
      if (nextProps.socket === 'disconnecting') {
        this.setState({
          userDisconnectedSocket: true
        });
      } else if (nextProps.socket === 'closed' &&
      !this.state.userDisconnectedSocket) {
        this.setState({
          userDisconnectedSocket: false
        });
        console.log('socket was disconnected, reconnecting after 1000 milliseconds');
        setTimeout(() => {
          this.props.connect(this.props.user.token);
        }, 1000);
      }
    }
  }

  handleClickNewContact() {
    this.props.redirect('/contacts/new');
  }

  handleClickContactSelection(role) {
    if (!this.props.call.active) {
      this.props.redirect(DEFAULT_ROUTES[role]);
    }
  }

  handleClickMyInfo() {
    this.props.redirect('/me');
  }

  handleClickCalendar() {
    this.props.redirect('/representative');
  }

  handleClickProposed() {
    this.props.redirect('/proposed');
  }

  handleClickClientMgmt() {
    this.props.redirect('/clients');
  }

  handleClickAdminMgmt() {
    this.props.redirect('/admins');
  }

  handleClickBookerMgmt() {
    this.props.redirect('/bookers');
  }

  handleClickBreak() {
    this.startBreak();
  }

  handleClickReports() {
    if (!this.props.call.active) {
      this.props.redirect('/reports');
    }
  }

  handleClickCallHistory() {
    this.props.redirect('/call-history');
  }

  handleClickAdminPanel() {
    this.props.redirect('/admin-panel');
  }

  handleClickCalendars() {
    this.props.redirect('/calendars');
  }

  handleClickHolidayMgmt() {
    this.props.redirect('/date-blocks');
  }

  handleClickBlocklistMgmt() {
    this.props.redirect('/blocklist');
  }

  handleClickBusinessDbMgmt() {
    this.props.redirect('/business-db');
  }

  handleClickRepresentatives(clientID) {
    this.props.redirect(`/clients/${clientID}/representatives`)
  }

  handleClickReassign() {
    this.props.redirect(`/projects/${this.props.selectedProject.id}/reassign`);
  }

  handleClickApiKeyMgmt() {
    this.props.redirect('/api-access');
  }

  handleClickNotificationMgmt() {
    this.props.redirect('/notification');
  }

  handleClickSendTextMessage() {
    const w = 600
    const h = 400
    const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
    const newWindow = window.open(`/sms`, 'sms', 
      `width=${w},height=${h},left=${x},top=${y},directories=0,titlebar=0,toolbar=0,menubar=0,location=0`
    )
    newWindow.focus()
  }

  handleClickLogout() {
    this.setState({ showLogoutModal: true });
  }

  closeLogoutModal(answered) {
    if (answered === true) {
      if (this.props.user.type === BOOKER || this.props.user.type === TEAMLEADER) {
        if (!this.props.call.active) {
          this.props.handleLogout(
            moment().diff(moment(this.props.worktime.workStarted), 'seconds'),
            this.props.worktime.elapsedBreak,
            this.props.user.type
          );
        }
      } else {
        this.props.handleLogout(null, null, this.props.user.type);
      }
    }
    if ( this.setState ) {
      this.setState({ showLogoutModal: false });
    }
  }

  closeBgProcEventTypeModal() {
    if ( this.setState ) {
      this.setState({ showBgprocEventTypeModal: false })
    }
  }

  getElapsedWorktime() {
    const elapsedWorktime =
      moment().diff(moment(this.props.worktime.workStarted), 'seconds') -
      this.props.worktime.elapsedBreak;
    return moment.utc(elapsedWorktime * 1000).format('H:mm');
  }

  getMenu() {
    if (this.props.user.type === BOOKER) {
      return this.getBookerMenu();
    } else if (this.props.user.type === REPRESENTATIVE) {
      return this.getRepresentativeMenu();
    } else if (this.props.user.type === TEAMLEADER) {
      return this.getTeamLeaderMenu(this.props.user.booker.readBusinessDb);
    } else if (this.props.user.type === CLIENTADMIN ) {
      return this.getClientAdminMenu();
    } else if (this.props.user.type === ADMIN ) {
      return this.getAdminMenu();
    }
  }

  isProjectSelected() {
    return this.props.selectedProject 
      && this.props.selectedProject.id 
      && this.props.selectedProject.customUserInterfaceId !== 1
  }

  handleClickCall() {
    if (this.props.call.active) {
      if ( this.props.useFlow || this.props.currentCallType === 'confirmation' ) {
        this.setState({ showEndCallModal: true });
      } else {
        this.props.endCall(false);
      }
    } else {
      if ( this.searchStringHasPhonenumber() ) {
        const type = 'phoneNumber';
        this.props.startCall(this.state.searchString, type, null, 'top-bar');
      } else {
        this.props.startCall(
          this.props.contact.phoneNumber, 
          this.props.currentCallType, 
          this.props.contact.id, 
          this.isOnCallPath() ? null : 'top-bar'
        )
      }
    }
  }

  isOnCallPath() {
    return this.props.path && this.props.path.endsWith('/call')
  }

  isCallDisabled() {
    return (window.enableCallDelayTimer && this.props.callDelayTimer > 0) 
      || ( this.props.callDisabled !== undefined && this.props.callDisabled.bind(this)() )
      || this.props.selectedProject == null
      || ( !this.searchStringHasPhonenumber() && this.props.contactContext === undefined )
      || ( this.props.call && this.props.call.pending )
  }

  searchStringHasPhonenumber() {
    return this.state.searchString.length > 0
  }

  getContactContext() {
    return this.props.contactContext.bind(this)()
  }

  getCallNav(callBtnFieldStyle) {
    return (
      <Nav>
        <NavItem>
          <div className="btn-round-wrapper">
            <Button
              bsStyle={
                this.props.call.active && 
                ( 
                    this.props.call.origin === 'top-bar' || 
                    ( this.props.call.origin == null && (!this.isOnCallPath() || !this.searchStringHasPhonenumber()) )
                ) 
                  ? 'danger' 
                  : 'success'
              }
              disabled={
                this.isCallDisabled() || 
                ( 
                  this.props.call.active && (
                    (
                      this.props.call.origin === null && 
                      this.isOnCallPath() && 
                      this.searchStringHasPhonenumber()
                    ) || 
                    (
                      this.props.call.origin && 
                      this.props.call.origin.startsWith('decision-maker-')
                    )
                  ) 
                )}
              onClick={() => this.handleClickCall()}
            >
              <div style={{ height: '100%', display: 'flex', flexFlow: 'row', alignItems: 'center', textAlign: 'left' }}>
                { ( this.searchStringHasPhonenumber() || this.props.contactContext !== undefined ) &&
                  <div style={{ fontSize: "12px", marginRight: "10px"}}>
                    { this.searchStringHasPhonenumber() &&
                      <div style={{ lineHeight: "14px", ...callBtnFieldStyle }}>
                        { this.state.searchString }
                      </div>
                    }
                    { !this.searchStringHasPhonenumber() &&
                      this.props.contactContext !== undefined &&
                      <div style={{ lineHeight: "14px", fontWeight: 'bold', ...callBtnFieldStyle }}>
                        { this.getContactContext().name }
                      </div>
                    }
                    { !this.searchStringHasPhonenumber() &&
                      this.props.contactContext !== undefined &&
                      <div style={{ fontSize: "9px", lineHeight: "12px", ...callBtnFieldStyle }}>
                        { this.getContactContext().phoneNumber }
                      </div>
                    }
                  </div>
                }
                <FontAwesomeIcon size="xs" icon={
                  this.props.call.active && 
                  ( 
                      this.props.call.origin === 'top-bar' || 
                      ( this.props.call.origin == null && (!this.isOnCallPath() || !this.searchStringHasPhonenumber()) )
                  )
                    ? "phone-slash"
                    : "phone"
                }/>
              </div>
            </Button>
          </div>
        </NavItem>
        { this.props.user.booker && this.props.user.booker.useFlow &&
          <NavItem href="https://flowapp.telavox.com/"  target="_blank">
            <div style={{ display: 'inline-block' }}>
                <img style={{ maxHeight: 20 }} alt="Telavox Flow" src={TelavoxLogo}></img>
            </div>
          </NavItem> 
        }
      </Nav>
    )
  }

  getBookerMenu() {

    const callBtnFieldStyle = {
      'maxWidth': '120px',
      'overflow': 'hidden',
      'textOverflow': 'ellipsis'
    }

    return (
      <Navbar.Collapse>
        <IdleTimer />
        <Nav>
          <NavItem className="worktime" onClick={() => {}}>
            <span className="text">{this.props.t('worktime')}</span>
            <span className="value">
              {this.state.elapsedWorktime !== null
                ? this.state.elapsedWorktime
                : '0:00'}
            </span>
            <span className="value">
              ({moment
                .utc(this.props.worktime.elapsedBreak * 1000)
                .format('H:mm')})
            </span>

            <Button onClick={() => this.handleClickBreak()}>
              {this.props.worktime.breakStarted !== null ? (
                <div>
                  <Glyphicon glyph="play" />
                </div>
              ) : (
                <Glyphicon glyph="pause" />
              )}
            </Button>
          </NavItem>

          <NavItem>
            <Search
              setParentState={ (state, fn) => {
                if ( !fn ) this.setState(state)
                else this.setState(state, fn)
              } }
              parentState={ this.state }
              placeholder={this.props.t('searchContacts')}
              searchType={SEARCH_TYPES.CONTACT}
            />
          </NavItem>

        </Nav>

        { this.getCallNav(callBtnFieldStyle) }
            
        <Nav pullRight id='with-project'>
          <NavItem>
            <div
              className={classNames('socket-indicator', this.props.socket)}
              title={this.props.t('socketConnection')}
            >
              <Glyphicon glyph="signal" />
            </div>
          </NavItem>

          <NavDropdown
            className="hidden-xs"
            id="main-menu"
            noCaret
            title={
            <div id='title-wrap'>
              <div>
                { this.props.selectedProject.name ? <div id='project-name'> {this.props.selectedProject.name} </div> : <></>}
                <div id='username'> {this.props.user.firstName} {this.props.user.lastName} </div>
              </div>
              <span className="caret"></span>
            </div>
            }
          >
          
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickContactSelection(BOOKER);
              }}
            >
              {this.props.t('contacts.contactSelection')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickNewContact();
              }}
            >
              {this.props.t('contacts.new')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickReports();
              }}
            >
              {this.props.t('reports.reports')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickCalendars();
              }}
            >
              {this.props.t('calendars')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickSendTextMessage();
              }}
            >
              {this.props.t('sendTextMessage')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickCallHistory();
              }}
            >
              {this.props.t('call.callHistory')}
            </MenuItem>
            <MenuItem divider />
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickLogout();
              }}
            >
              {this.props.t('logout')}
            </MenuItem>
          </NavDropdown>

          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickContactSelection(BOOKER);
            }}
          >
            {this.props.t('contacts.contactSelection')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickNewContact();
            }}
          >
              {this.props.t('contacts.new')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickReports();
            }}
          >
            {this.props.t('reports.reports')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickCalendars();
            }}
          >
            {this.props.t('calendars')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickSendTextMessage();
            }}
          >
            {this.props.t('sendTextMessage')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickCallHistory();
            }}
          >
            {this.props.t('call.callHistory')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => this.handleClickLogout()}
          >
            {this.props.t('logout')}
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    );
  }

  getRepresentativeMenu() {
    return (
      <Navbar.Collapse>
        <Nav>
          <NavItem>
            <Search
              setParentState={ (state, fn) => {
                if ( !fn ) this.setState(state)
                else this.setState(state, fn)
              } }
              parentState={ this.state }
              placeholder={this.props.t('searchBookings')}
              searchType={SEARCH_TYPES.BOOKING}
            />
          </NavItem>
        </Nav>

        <Nav pullRight>
          <NavItem>
            <div
              className={classNames('socket-indicator', this.props.socket)}
              title={this.props.t('socketConnection')}
            >
              <Glyphicon glyph="signal" />
            </div>
          </NavItem>

          <NavDropdown
            className="hidden-xs"
            id="main-menu"
            title={`${this.props.user.firstName} ${this.props.user.lastName}`}
          >
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickCalendar();
              }}
            >
              {this.props.t('calendar')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickProposed();
              }}
            >
              Avoimet tarjoukset
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickReports();
              }}
            >
              {this.props.t('reports.reports')}
            </MenuItem>
            <MenuItem divider />
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickMyInfo();
              }}
            >
              {this.props.t('myInfo')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickLogout();
              }}
            >
              {this.props.t('logout')}
            </MenuItem>
          </NavDropdown>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickCalendar();
            }}
          >
            {this.props.t('calendar')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickReports();
            }}
          >
            {this.props.t('reports.reports')}
          </NavItem>
          <NavItem
              className="menu-item show-only-on-xs"
              onSelect={() => this.handleClickMyInfo()}
            >
              {this.props.t('myInfo')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => this.handleClickLogout()}
          >
            {this.props.t('logout')}
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    );
  }

  getTeamLeaderMenu(readBusinessDb) {

    const callBtnFieldStyle = {
      'maxWidth': '120px',
      'overflow': 'hidden',
      'textOverflow': 'ellipsis'
    }

    return (
      <Navbar.Collapse>
        <IdleTimer />
        <Nav>
        <NavItem className="worktime" onClick={() => {}}>
            <span className="text">{this.props.t('worktime')}</span>
            <span className="value">
              {this.state.elapsedWorktime !== null
                ? this.state.elapsedWorktime
                : '0:00'}
            </span>
            <span className="value">
              ({moment
                .utc(this.props.worktime.elapsedBreak * 1000)
                .format('H:mm')})
            </span>

            <Button onClick={() => this.handleClickBreak()}>
              {this.props.worktime.breakStarted !== null ? (
                <div>
                  <Glyphicon glyph="play" />
                </div>
              ) : (
                <Glyphicon glyph="pause" />
              )}
            </Button>
          </NavItem>

          <NavItem>
            <Search
              setParentState={ (state, fn) => {
                if ( !fn ) this.setState(state)
                else this.setState(state, fn)
              } }
              parentState={ this.state }
              placeholder={this.props.t('searchContacts')}
              searchType={SEARCH_TYPES.CONTACT}
            />
          </NavItem>

        </Nav>

        { this.getCallNav(callBtnFieldStyle) }

        <Nav pullRight id='with-project'>
          <NavItem>
            <div
              className={classNames('socket-indicator', this.props.socket)}
              title={this.props.t('socketConnection')}
            >
              <Glyphicon glyph="signal" />
            </div>
          </NavItem>

          <NavDropdown
            className="hidden-xs"
            id="main-menu"
            noCaret
            title={
            <div id='title-wrap'>
              <div>
                { this.props.selectedProject.name ? <div id='project-name'> {this.props.selectedProject.name} </div> : <></>}
                <div id='username'> {this.props.user.firstName} {this.props.user.lastName} </div>
              </div>
              <span className="caret"></span>
            </div>
            }
          >
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickContactSelection(TEAMLEADER);
              }}
            >
              {this.props.t('contacts.contactSelection')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickNewContact();
              }}
            >
              {this.props.t('contacts.new')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickReassign();
              }}
            >
              {this.props.t('reassignmentTool')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickReports();
              }}
            >
              {this.props.t('reports.reports')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickCalendars();
              }}
            >
              {this.props.t('calendars')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickSendTextMessage();
              }}
            >
              {this.props.t('sendTextMessage')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}
              className="menu-item"
              onSelect={() => {
                this.handleClickCallHistory();
              }}
            >
              {this.props.t('call.callHistory')}
            </MenuItem>
            <MenuItem
              disabled={!this.isProjectSelected()}

              className="menu-item"
              onSelect={() => {
                this.handleClickRepresentatives(this.props.selectedProject.clientId);
              }}
            >
              {this.props.t('repCtl.repMgmt')}
            </MenuItem>
            {
              readBusinessDb &&
              <MenuItem
                className="menu-item"
                onSelect={() => {
                  this.handleClickBusinessDbMgmt();
                }}
              >
                {this.props.t('businessDbCtl.businessDbMgmt')}
              </MenuItem>
            }
            <MenuItem divider />
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickLogout();
              }}
            >
              {this.props.t('logout')}
            </MenuItem>
          </NavDropdown>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickContactSelection(TEAMLEADER);
            }}
          >
            {this.props.t('contacts.contactSelection')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickNewContact();
            }}
          >
              {this.props.t('contacts.new')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickReassign();
            }}
          >
              {this.props.t('reassignmentTool')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickReports();
            }}
          >
            {this.props.t('reports.reports')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickCalendars();
            }}
          >
            {this.props.t('calendars')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickSendTextMessage();
            }}
          >
            {this.props.t('sendTextMessage')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickCallHistory();
            }}
          >
            {this.props.t('call.callHistory')}
          </NavItem>
          <NavItem
            disabled={!this.isProjectSelected()}
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickRepresentatives(this.props.selectedProject.clientId);
            }}
          >
            {this.props.t('repCtl.repMgmt')}
          </NavItem>
          {
            readBusinessDb &&
            <NavItem
              className="menu-item show-only-on-xs"
              onSelect={() => {
                this.handleClickBusinessDbMgmt();
              }}
            >
              {this.props.t('businessDbCtl.businessDbMgmt')}
            </NavItem>
          }
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => this.handleClickLogout()}
          >
            {this.props.t('logout')}
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    );
  }

  getAdminMenu() {

    const filterUniqueUuids = events => {
      const ids = {}
      return events.filter(event => {
        if ( event.uuid in ids ) return false
        ids[event.uuid] = true
        return true
      })
    }

    const unread = filterUniqueUuids((this.props.bgproc.events || []))
      .filter(event => 
        moment(this.props.user.admin.bgprocRead)
          .isBefore(moment(event.timestamp))
      ).length

    const inProgress = !!filterUniqueUuids((this.props.bgproc.events || []))
        .find(event => event.status === 'start')

    return (
      <Navbar.Collapse>
        <Nav>
          <NavItem>
            <Search
              setParentState={ (state, fn) => {
                if ( !fn ) this.setState(state)
                else this.setState(state, fn)
              } }
              parentState={ this.state }
              placeholder={this.props.t('searchContacts')}
              searchType={SEARCH_TYPES.CONTACT}
            />
          </NavItem>
        </Nav>

        <Nav pullRight>
          <NavDropdown
            ref={this.eventsMenu}
            id="background-processes"
            className="background-process-dropdown"
           // disabled={!this.props.bgproc.events.length}
            onToggle={open => { 
              const e = document.querySelector('.background-process-dropdown>ul>ul')
              if ( e ) e.scrollTop = 0
              if ( !open ) {
                this.props.setBgprocRead(this.props.user.admin.id)
              }
            }}
            title={
              <React.Fragment>
                <Glyphicon glyph="bell" style={{
                  fontSize: '1.25em', 
                  color: unread ? '#a51890' : '#aaa'
                }}/>
                { unread > 0 &&
                  <div className="unread-count">
                    { unread >= 25 ? '25+' : unread }
                  </div>
                }
                { inProgress && <div className="bell-loader" /> }
              </React.Fragment>
            }
          >
            <ul style={{ maxHeight: 600, overflow: "auto", listStyleType: "none", padding: 0 }}>
              {filterUniqueUuids(this.props.bgproc.events).map((event, i) => 
                <BackgroundProcessLogEvent 
                  unread={
                    moment(this.props.user.admin.bgprocRead)
                      .isBefore(moment(event.timestamp))
                  }
                  key={event.uuid} event={event} 
                />
              ).filter(e => e).slice(0, 25)}
            </ul>
            <a id="bgproc-event-types" href="#" onClick={e => {
              this.setState({ 
                showBgprocEventTypeModal: true,
                bgprocTypes: (this.props.user?.admin?.bgprocType || '').split(',')
              })
            }}>
              <Glyphicon glyph="cog" />
              <span style={{ marginLeft: 4 }}>
                { this.props.t('backgroundProcessLog.eventTypes') }
              </span>
            </a>
          </NavDropdown>
          <NavDropdown
            className="hidden-xs"
            id="main-menu"
            title={`${this.props.user.firstName} ${this.props.user.lastName}`}
          >
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickClientMgmt();
              }}
            >
              {this.props.t('clientCtl.clientMgmt')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickBookerMgmt();
              }}
            >
              {this.props.t('bookerCtl.bookerMgmt')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickAdminMgmt();
              }}
            >
              {this.props.t('adminCtl.adminMgmt')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickHolidayMgmt();
              }}
            >
              {this.props.t('holidayCtl.holidayMgmt')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickBlocklistMgmt();
              }}
            >
              {this.props.t('blocklistCtl.globalBlocklistMgmt')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickBusinessDbMgmt();
              }}
            >
              {this.props.t('businessDbCtl.businessDbMgmt')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickApiKeyMgmt();
              }}
            >
              {this.props.t('apiKeyCtl.apiKeyMgmt')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickNotificationMgmt();
              }}
            >
              {this.props.t('notificationCtl.notificationMgmt')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickNewContact();
              }}
            >
              {this.props.t('contacts.new')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickCalendars();
              }}
            >
              {this.props.t('calendars')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickCallHistory();
              }}
            >
              {this.props.t('call.callHistory')}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickAdminPanel();
              }}
            >
              {this.props.t('panel.adminPanel')}
            </MenuItem>
            <MenuItem divider />
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickLogout();
              }}
            >
              {this.props.t('logout')}
            </MenuItem>
          </NavDropdown>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickClientMgmt();
            }}
          >
            {this.props.t('clientCtl.clientMgmt')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickBookerMgmt();
            }}
          >
            {this.props.t('bookerCtl.bookerMgmt')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickAdminMgmt();
            }}
          >
            {this.props.t('adminCtl.adminMgmt')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickHolidayMgmt();
            }}
          >
            {this.props.t('holidayCtl.holidayMgmt')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickBlocklistMgmt();
            }}
          >
            {this.props.t('blocklistCtl.globalBlocklistMgmt')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickBusinessDbMgmt();
            }}
          >
            {this.props.t('businessDbCtl.businessDbMgmt')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickApiKeyMgmt();
            }}
          >
            {this.props.t('apiKeyCtl.apiKeyMgmt')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickNotificationMgmt();
            }}
          >
            {this.props.t('notificationCtl.notificationMgmt')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickNewContact();
            }}
          >
              {this.props.t('contacts.new')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickCalendars();
            }}
          >
            {this.props.t('calendars')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickCallHistory();
            }}
          >
            {this.props.t('call.callHistory')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => {
              this.handleClickAdminPanel();
            }}
          >
            {this.props.t('panel.adminPanel')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => this.handleClickLogout()}
          >
            {this.props.t('logout')}
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    );
  }

  getClientAdminMenu() {
    return (
      <Navbar.Collapse>
        <Nav pullRight>

          <NavDropdown
            className="hidden-xs"
            id="main-menu"
            title={`${this.props.user.firstName} ${this.props.user.lastName}`}
          >
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickReports();
              }}
            >
              {this.props.t('reports.reports')}
            </MenuItem>
           <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickCalendars();
              }}
            >
              {this.props.t('calendars')}
            </MenuItem>
            <MenuItem divider />
            <MenuItem
              className="menu-item"
              onSelect={() => {
                this.handleClickLogout();
              }}
            >
              {this.props.t('logout')}
            </MenuItem>
          </NavDropdown>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => this.handleClickReports()}
          >
            {this.props.t('reports.reports')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => this.handleClickCalendars()}
          >
            {this.props.t('calendars')}
          </NavItem>
          <NavItem
            className="menu-item show-only-on-xs"
            onSelect={() => this.handleClickLogout()}
          >
            {this.props.t('logout')}
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    );
  }

  startBreak() {
    if (
      !this.props.call.active &&
      (this.props.user.type === BOOKER || this.props.user.type === TEAMLEADER) &&
      this.props.worktime.breakStarted === null
    ) {
      this.props.startBreak(moment().toDate());
    }
  }

  closeModal(answered) {
    this.setState({ showEndCallModal: false });
    this.props.endCall(answered);
  }

  toggleBgprocType(value) {
    let newState;
    if ( value === "all" ) {
        newState = {
          bgprocTypes: 
            this.allBgprocTypesSelected() 
              ? []
              : BGPROC_TYPES.filter(t => t !== "all")
        }
    } else {
      newState = {
        bgprocTypes: 
          this.state.bgprocTypes.includes(value)
            ? this.state.bgprocTypes.filter(t => t !== value)
            : [...this.state.bgprocTypes, value]
      }
    }
    this.setState(newState)
  }

  allBgprocTypesSelected() {
    return BGPROC_TYPES.filter(t => t !== "all")
      .every(t => this.state.bgprocTypes.find(_t => _t === t))
  }

  render() {
    return (
      <Navbar collapseOnSelect id="top-bar">
        <Navbar.Header>
          <Navbar.Brand>
            <img src={BookersLogo} alt="Bookers" />
          </Navbar.Brand>
          <iframe title="cmpro-frame" style={{ display: 'none' }} id="cmpro-frame"></iframe>
          <Navbar.Toggle />
        </Navbar.Header>

        {this.getMenu()}

        <Modal show={this.state.showEndCallModal}>
          <Modal.Header closeButton={false}>
            <Modal.Title>{this.props.t('call.didContactAnswer')}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={() => this.closeModal(true)}>
              {this.props.t('yes')}
            </Button>
            <Button onClick={() => this.closeModal(false)}>
              {this.props.t('no')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal onHide={this.closeLogoutModal} show={this.state.showLogoutModal}>
          <Modal.Header closeButton={false}>
            <Modal.Title>{this.props.t('logoutConfirmation')}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={() => this.closeLogoutModal(true)}>
              {this.props.t('yes')}
            </Button>
            <Button onClick={() => this.closeLogoutModal(false)}>
              {this.props.t('no')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal onHide={() => this.closeBgProcEventTypeModal()} 
          show={this.state.showBgprocEventTypeModal}>
          <Modal.Header closeButton={false}>
            <Modal.Title>{ this.props.t('backgroundProcessLog.eventTypes') }</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: 240, maxHeight: 240, overflow: "auto" }}>
            {
              (() => {
                const typeMap = BGPROC_TYPES.map(type => ({
                  label: this.props.t(
                    type !== "all" 
                      ? ("backgroundProcessLog.proc_" + type) 
                      : "all"), 
                  value: type
                }))
                return typeMap.map(type => <div key={type.value} style={{ cursor: "pointer" }} onClick={() => {
                  this.toggleBgprocType(type.value)
                }}>
                  <input
                    key={type.value}
                    type="checkbox"
                    checked={
                      type.value === "all"
                        ? this.allBgprocTypesSelected()
                        : !!this.state.bgprocTypes.find(t => t === type.value)
                    }
                    onChange={() => this.toggleBgprocType(type.value)}
                    style={{ width: "unset", height: "unset" }}
                  />
                    <span onClick={() => this.toggleBgprocType(type.value)} style={{ marginLeft: 6 }}>
                      {type.label}
                    </span>
                </div>
                )
              })()
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {
              this.props.setBgprocTypes(
                this.props.user.admin.id,
                this.state.bgprocTypes
              )
              this.closeBgProcEventTypeModal()
            }}>
              {this.props.t('save')}
            </Button>
            <Button onClick={e => {
              this.closeBgProcEventTypeModal()
            }}>
              {this.props.t('cancel')}
            </Button>
          </Modal.Footer>
        </Modal>

      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  contact: state.contacts.contact,
  call: state.call.call,
  callDelayTimer: state.call.delayTimer,
  selectedProject: state.call.selectedProject,
  showStatistics: state.statistics.showStatistics,
  socket: state.socket,
  user: state.user,
  worktime: state.worktime,
  path: state.router.location.pathname,
  currentCallType: state.callLists.current,
  bgproc: state.backgroundProcessLog,
  useFlow: (state.user.booker || {}).useFlow === true
});

const mapDispatchToProps = dispatch => ({
  setBgprocTypes: (adminId, types) => {
    dispatch(bgprocActions.setTypes({adminId, types}));
  },
  setBgprocRead: adminId => {
    dispatch(bgprocActions.setRead({adminId}));
  },
  handleLogout: (duration, breakDuration, role) => {
    dispatch(
      userActions.logout({
        duration,
        breakDuration,
        role
      })
    );
  },
  redirect: url => {
    dispatch(push(url));
  },
  startBreak: startedAt => {
    dispatch(
      worktimeActions.startBreak({
        startedAt
      })
    );
  },
  connect: token => {
    const url = `${WEBSOCKET_API_URL}?Authorization=${token}`;
    dispatch(socketActions.connect({ url }));
  },
  startCall: (phoneNumber, type, contactId, origin) => {
    dispatch(callActions.startCall({
      phoneNumber, type: type || 'booking', contactId, origin
    }));
  },
  endCall: answered => {
    dispatch(
      callActions.endCall({
        answered
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(TopBar)
);
