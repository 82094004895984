import moment from 'moment';
import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as bookerActions from '../../redux/bookers/actions';
import * as bookingActions from '../../redux/booking/actions';

export class BookingInfo extends Component {

  constructor(props){
    super(props)
    if ( props.showBooker ) {
      this.props.getBookers(
        props.booking.booking.projectId, 
        props.booking.booking.contactId
      )
    }
  }

  handleSelectBooker(bookerId) {
    this.props.setBookingBooker(
      this.props.booking.booking.id,
      bookerId
    )
  }

  render() {
    return (
      <div className="booking-info">
        <div className="info-row">
        { this.props.showBooker &&
            <div className="info-row-column">
              <span>{this.props.t('booking.booker')}</span>
              <br />
              <DropdownButton
                style={{ width: "100%"}}
                id="booking-booker"
                disabled={ false }
                title={mapBooker(this.props.booking.booking.booker).name}
              >
              {this.props.bookers.map(booker => {
                return (
                  <MenuItem
                    active={this.props.booking.booking.bookerId === booker.id}
                    eventKey={booker.id}
                    key={booker.id}
                    onSelect={eventKey => this.handleSelectBooker(eventKey)}
                  >
                    { booker.name }
                  </MenuItem>
                );
              })}
              </DropdownButton>
            </div>
          }
          <div className="info-row-column">
            <span>{this.props.t('booking.date')}</span>
            <br />
            {this.props.booking.booking !== null && (
              <input
                placeholder={moment(this.props.booking.booking.startsAt).format(
                  'D.M.YYYY'
                )}
                readOnly
                type="text"
              />
            )}
          </div>
          <div className="info-row-column">
            <span>{this.props.t('day')}</span>
            <br />
            {this.props.booking.booking !== null && (
              <input
                placeholder={moment(this.props.booking.booking.startsAt).format(
                  'dddd'
                )}
                readOnly
                type="text"
              />
            )}
          </div>
          <div className="info-row-column">
            <span>{this.props.t('time')}</span>
            <br />
            {this.props.booking.booking !== null && (
              <input
                placeholder={`${moment(
                  this.props.booking.booking.startsAt
                ).format('H:mm')} - ${moment(
                  this.props.booking.booking.endsAt
                ).format('H:mm')}`}
                readOnly
                type="text"
              />
            )}
          </div>
          <div className="info-row-column">
            <span>{this.props.t('representative.representative')}</span>
            <br />
            {this.props.booking.booking !== null &&
              this.props.booking.booking.representative !== null &&
              this.props.booking.booking.representative.user !== undefined && (
                <React.Fragment>
                <input
                  placeholder={`${
                    this.props.booking.booking.representative.user.firstName
                  } ${this.props.booking.booking.representative.user.lastName}`}
                  readOnly
                  type="text"
                />
                <div style={{ fontSize: 12}}>
                  { this.props.booking.booking.representative.user.phoneNumber }
                </div>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapBooker = booker => ({
  name: `${booker.user.firstName} ${booker.user.lastName}`,
  id: booker.id
})

const mapStateToProps = state => ({
  booking: state.booking,
  bookers: state.bookers.callHistoryBookers
  .map(mapBooker).sort((a, b) => 
    ((a.name.toLowerCase() < b.name.toLowerCase()) 
      ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0
  ))
});

const mapDispatchToProps = dispatch => ({
  getBookers: (projectId, contactId) => {
    dispatch(bookerActions.getBookers({
      callHistory: true,
      filterAsAdmin: true,
      projectId,
      contactId
    }));
  },
  setBookingBooker: (bookingId, bookerId) => {
    dispatch(bookingActions.setBookingBooker({
      bookingId,
      bookerId
    }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(BookingInfo)
);
