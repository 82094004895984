import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as bookingActions from '../../redux/booking/actions';

moment.locale('fi');

const ConfirmationCallModal = (props) => {
  const { show, setShowModal, bookingId } = props
  const handleClose = () => {
    setShowModal(false)
  }

  const handleClickAnswered = ( answer ) => {
    props.setConfirmationCallAttempt(bookingId, answer)
    setShowModal(false)
  }
  
  return (
    <Modal show={show}>
      <Modal.Body>
        <p>{props.t('confirmationCallModal.question')}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => handleClickAnswered(true)}>{props.t('yes')}</Button>
        <Button onClick={() => handleClickAnswered(false)}>{props.t('no')}</Button>
        <Button onClick={() => handleClose()}>{props.t('confirmationCallModal.notConfirmationCall')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

ConfirmationCallModal.propTypes = {
  show: PropTypes.bool,
  setShowModal: PropTypes.func,
  bookingId: PropTypes.number
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setConfirmationCallAttempt: (bookingId, success) => {
    dispatch(
      bookingActions.setConfirmationCallAttempt({
        bookingId,
        success
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ConfirmationCallModal)
);
