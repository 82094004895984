import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Glyphicon, Modal } from 'react-bootstrap';
import i18n from 'i18next';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import * as actions from '../../redux/error-handling/actions';

const ErrorBanner = ({ dismiss, error, t }) => (
  <Modal className="error-banner" show={!isEmpty(error) && error.name !== 'invalidMfaCode' && error.name !== 'invalidMfaCode_' }>
    <Modal.Header closeButton={false}>
      <Modal.Title>
        <Glyphicon glyph="remove-circle" />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {i18n.exists(`error.${error.name}`)
        ? t(`error.${error.name}`)
        : error.message
      }
    </Modal.Body>
    <Modal.Footer>
      <Button
        onClick={() => {
          dismiss();
        }}
      >
        {t('close')}
      </Button>
    </Modal.Footer>
  </Modal>
);

ErrorBanner.propTypes = {
  dismiss: PropTypes.func.isRequired,
  error: PropTypes.object,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  error: state.error
});

const mapDispatchToProps = dispatch => ({
  dismiss: () => {
    dispatch(actions.clearError());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ErrorBanner)
);
