import { createAction } from 'redux-actions';

export const getEvents = createAction('GET_BGPROC_EVENTS');
export const getEvent = createAction('GET_BGPROC_EVENT');
export const gotEvents = createAction('GOT_BGPROC_EVENTS');
export const gotEvent = createAction('GOT_BGPROC_EVENT');
export const setEvents = createAction('SET_BGPROC_EVENTS');
export const addEvent = createAction('ADD_BGPROC_EVENT');
export const setRead = createAction('SET_BGPROC_READ');
export const setTypes = createAction('SET_BGPROC_TYPES');

export const cancelProcess = createAction('CANCEL_BGPROC');
