import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Glyphicon } from 'react-bootstrap';

import { REST_API_BASE_URL } from '../../config';

export class AudioPlayer extends Component {

  constructor(props){
    super(props)
    if ( !this.props.dontHideOnClick ) {
      this.boundCloseFn = this.closeFn.bind(this)
      document.addEventListener('click', this.boundCloseFn, true)
    }
  }

  boundCloseFn = () => {}

  closeFn() {
    if ( !this.props.dontHideOnClick ) {
      document.removeEventListener('click', this.boundCloseFn, true)
    }
    this.props.closeFn(this.props.callId)
  }

  render() {
    const defaultStyle = { 
      width: 500, 
      height: 40, 
      position: 'absolute', 
      zIndex: 100,
      boxShadow: '2px 2px 8px 4px rgba(0,0,0,.1)',
      display: 'flex',
      flexFlow: 'row',
      alignContent: 'center',
      marginTop: - (this.props.parentScrollTop || 0) - 3,
      marginLeft: - (this.props.parentScrollLeft || 0)  - 3,
      overflow: 'hidden',
      borderRadius: 15,
      backgroundColor: '#f4f4f4'
    }
    const style = this.props.style ? {...defaultStyle, ...this.props.style} : defaultStyle
    return (
      <div className="audio-player" style={style}>
            <div style={{ flex: 1 }}>
                <audio autoPlay controls controlsList="nodownload" style={{ flex: 1, width: '100%', height: '100%', outline: 'none' }}>
                    <source src={`${REST_API_BASE_URL}/calls/${this.props.callId}/recording?authorization=${this.props.token}`} type="audio/mpeg"></source>
                </audio>
            </div>
                <Button onClick={this.closeFn.bind(this)} style={{
                    alignContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    margin: 0,
                    border: 0
               }}>
                    <Glyphicon glyph="remove" />
                </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(AudioPlayer)
);
