import moment from 'moment';
import React, { Component } from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { EventButton } from '../';

import * as eventActions from '../../redux/events/actions';
import * as calendarActions from '../../redux/calendars/actions';

moment.locale('fi');

class Day extends Component {
  componentDidMount() {

    this.props.getShowFreeSlots()
    this.getDayEvents()

  }

  getDayEvents() {
    const day =
      this.props.events.currentDay !== null
        ? moment(this.props.events.currentDay)
        : moment();

    this.props.getEvents(
      this.props.representative || this.props.user.representative.id,
      day.startOf('day').format(),
      day.endOf('day').format()
    );
  }

  addEvent() {
    this.props.redirect('/new-event');
  }

  setNextDay() {
    this.props.getEvents(
      this.props.representative || this.props.user.representative.id,
      moment(this.props.events.currentDay)
        .add(1, 'day')
        .startOf('day')
        .format(),
      moment(this.props.events.currentDay)
        .add(1, 'day')
        .endOf('day')
        .format()
    );
  }

  setPreviousDay() {
    this.props.getEvents(
      this.props.representative || this.props.user.representative.id,
      moment(this.props.events.currentDay)
        .subtract(1, 'day')
        .startOf('day')
        .format(),
      moment(this.props.events.currentDay)
        .subtract(1, 'day')
        .endOf('day')
        .format()
    );
  }

  setToday() {
    this.props.getEvents(
      this.props.representative || this.props.user.representative.id,
      moment()
        .startOf('day')
        .format(),
      moment()
        .endOf('day')
        .format()
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.booking !== nextProps.booking ) {
      this.getDayEvents();
    }
  }

  render() {
    let events = this.props.events.events.sort(
      (a,b) => new Date(a.startsAt) - new Date(b.startsAt)
    )
    if ( !this.props.showFreeSlots ) {
      events = events.filter(event => event.styleType !== 'free' )
    }
    
    return (
      <div className="day">
        <div className="heading">
          {this.props.t('calendar')}
          <Button className="add-button" onClick={() => this.addEvent()}>
            <Glyphicon glyph="plus" />
          </Button>
        </div>
        <div className="actions">
          <Button className="browse" onClick={() => this.setPreviousDay()}>
            <Glyphicon glyph="chevron-left" />
          </Button>
          <Button className="day" onClick={() => this.setToday()}>
            {moment(this.props.events.currentDay).format('dddd D.M.')}
          </Button>
          <Button onClick={() => this.setNextDay()}>
            <Glyphicon glyph="chevron-right" />
          </Button>
        </div>
        <div className="events">
          {
            events
              .filter(event => !event.booking || event.booking.contactId)
              .map((event, i) => {
                return (
                  <EventButton
                    event={event}
                    key={`${i}${event.startAt}${event.endsAt}`}
                  />
                );
              })
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  booking: state.booking,
  events: state.events,
  user: state.user,
  showFreeSlots: state.calendars.showFreeSlots !== null
    ? state.calendars.showFreeSlots
    : true,
  lastCancelledOrDeleted: state.booking.lastCancelledOrDeleted
});

const mapDispatchToProps = dispatch => ({
  getShowFreeSlots: () => {
    dispatch(calendarActions.getShowFreeSlots());
  },
  setShowFreeSlots: value => {
    dispatch(calendarActions.setShowFreeSlots(value));
  },
  getEvents: (representativeId, startsAtOrAfter, endsBefore) => {
    dispatch(
      eventActions.getEvents({
        representativeId,
        startsAtOrAfter,
        endsBefore
      })
    );
  },
  redirect: url => {
    dispatch(push(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(Day)
);
