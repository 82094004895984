import { createAction } from 'redux-actions';

export const getCampaigns = createAction('GET_CAMPAIGNS');
export const gotCampaigns = createAction('GOT_CAMPAIGNS');
export const setCampaignsLoading = createAction('SET_GET_CAMPAIGNS_LOADING');

export const getProjects = createAction('GET_PROJECTS');
export const gotProjects = createAction('GOT_PROJECTS');
export const setProjectsLoading = createAction('SET_PROJECTS_LOADING');

export const getProject = createAction('GET_PROJECT');
export const gotProject = createAction('GOT_PROJECT');

export const getAdditionalContactInfo = createAction('GET_ADDITIONAL_CONTACT_INFO');
export const gotAdditionalContactInfo = createAction('GOT_ADDITIONAL_CONTACT_INFO');

export const setAdditionalContactInfo = createAction('SET_ADDITIONAL_CONTACT_INFO');
export const setAdditionalContactInfoDone = createAction('SET_ADDITIONAL_CONTACT_INFO_DONE');

export const getBookerTarget = createAction('GET_BOOKER_TARGET');
export const gotBookerTarget = createAction('GOT_BOOKER_TARGET');

export const setBookerTarget = createAction('SET_BOOKER_TARGET');
export const setBookerTargetDone = createAction('SET_BOOKER_TARGET_DONE');

export const getAndSelectProject = createAction('GET_AND_SELECT_PROJECT');

export const getContactImportTemplates = createAction('GET_CONTACT_IMPORT_TEMPLATES');
export const gotContactImportTemplates = createAction('GOT_CONTACT_IMPORT_TEMPLATES');

export const patchProject = createAction('PATCH_PROJECT');
export const patchedProject = createAction('PATCHED_PROJECT');

export const postCampaign = createAction('POST_CAMPAIGN');
export const postedCampaign = createAction('POSTED_CAMPAIGN');

export const getRepresentatives = createAction('GET_PROJECT_REPRESENTATIVES');
export const gotRepresentatives = createAction('GOT_PROJECT_REPRESENTATIVES');

export const getBookers = createAction('GET_PROJECT_BOOKERS');
export const gotBookers = createAction('GOT_PROJECT_BOOKERS');

export const setBookers = createAction('SET_PROJECT_BOOKERS');
export const setBookersDone = createAction('SET_PROJECT_BOOKERS_DONE');

export const addBooker = createAction('ADD_PROJECT_BOOKER');
export const deleteBooker = createAction('DELETE_PROJECT_BOOKER');

export const getSubAreas = createAction('GET_PROJECT_SUB_AREAS');
export const gotSubAreas = createAction('GOT_PROJECT_SUB_AREAS');

export const getLocations = createAction('GET_PROJECT_LOCATIONS');
export const gotLocations = createAction('GOT_PROJECT_LOCATIONS');

export const getRepresentativesSubAreas = createAction('GET_PROJECT_REPRESENTATIVES_SUBAREAS');
export const gotRepresentativesSubAreas = createAction('GOT_PROJECT_REPRESENTATIVES_SUBAREAS');

export const getContactStatuses = createAction('GET_PROJECT_CONTACT_STATUSES');
export const gotContactStatuses = createAction('GOT_PROJECT_CONTACT_STATUSES');

export const exportContacts = createAction('EXPORT_PROJECT_CONTACTS');

export const sendSMS = createAction('PROJECT_SEND_SMS');
export const sentSMS = createAction('PROJECT_SENT_SMS');

export const sendSMSBatch = createAction('PROJECT_SEND_SMS_BATCH');
export const sentSMSBatch = createAction('PROJECT_SENT_SMS_BATCH');

export const sendEmail = createAction('PROJECT_SEND_EMAIL');
export const sentEmail = createAction('PROJECT_SENT_EMAIL');

export const gotDecisionMakerTitles = createAction('GOT_PROJECT_DECISION_MAKER_TITLES');

export const setDecisionMakerTitlesProject = createAction('SET_DECISION_MAKER_TITLES_PROJECT');
export const setDecisionMakerTitlesProjectDone = createAction('SET_DECISION_MAKER_TITLES_PROJECT_DONE');

export const setContactStatuses = createAction('SET_CONTACT_STATUSES_PROJECT');
export const setContactStatusesDone = createAction('SET_CONTACTS_STATUSES_DONE');