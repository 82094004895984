import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Glyphicon, Modal, NavDropdown, Nav, MenuItem } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete'
import * as callActions from '../../redux/call/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as contactActions from '../../redux/contacts/actions';

export class DecisionMakerInfoCard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showEndCallModal: false,
      name: props.person.name,
      phoneNumber: props.person.phoneNumber,
      email: props.person.email,
      businessUnit: props.person.businessUnit,
      checked: props.person.id >= 0,
      tags: [...props.person.tag.map(name => ({name}))],
      tags2: [...props.person.tag2.map(name => ({name}))],
      modified: false,
      phoneFormatError: false,
      unknown: props.person.unknown,
      notes: props.person.notes
    }
    this.reactTags = React.createRef()
    this.reactTags2 = React.createRef()
    const projectId = props.contact && props.contact.projectId
      ? props.contact.projectId
      : null
    if ( !props.disableGlobalDataFetch ) {
      this.props.getDecisionMakerTitles(projectId)
      this.props.getDecisionMakerPositions()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.decisionMakerTitles !== nextProps.decisionMakerTitles && nextProps.decisionMakerTitles ) {
      if ( nextProps.person.id < 0 && !this.state.modified && !this.state.tags.length ) {
        const defaults = nextProps.decisionMakerTitles.filter(title => title.default)
        this.setState({
          tags: defaults.map(title => ({
            name: title.title, 
            id: title.id
          }))
        })
      }
    }
  }

  componentDidMount() {
    this.setChecked(this.props.person.id >= 0)
  }

  componentWillUnmount() {
    this.setChecked(true)
  }

  setChecked(checked) {
    if ( this.props.onDecisionMakerStateEvent ) {
      this.props.onDecisionMakerStateEvent({
        id: this.props.person.id,
        unverified: this.props.checkable && !checked
      })
    }
    this.setState({checked})
  }

  handleChangeInfo(param, e, mergeObj={}) {
    const o = {
      modified: true,
      [param]: e.target.value,
      ...mergeObj
    }
    if ( param === 'phoneNumber' ) {
      o.phoneFormatError = false
    }
    this.setState(o, () => {
      this.setChecked(false)
    })
  }

  onDelete(key) {
    return i => {
      if ( !this.state[key].length ) return
      const tags = this.state[key].slice(0)
      tags.splice(i, 1)
      this.setState({
        modified: true,
        [key]: tags
      }, () => {
        this.setChecked(false)
      })
    }
  }
 
  onAddition (key) {
    return tag => {
      const tags = [].concat(this.state[key], tag)
      this.setState({ 
        modified: true,
        [key]: tags
      }, () => {
        this.setChecked(false)
      })
    }
  }

  isCheckDisabled() {
    if ( this.state.unknown ) {
      return !this.state.notes || !this.state.notes.length
    } else {
      return !this.state.name || !this.state.name.length || 
        !this.state.phoneNumber || !this.state.phoneNumber.length /*||
        !this.state.email || !this.state.email.length || 
        !this.state.tags || !this.state.tags.length*/
    }
  }

  handleClickCall() {
    if (this.props.call.active) {
      if ( this.props.useFlow ) {
        this.setState({showEndCallModal: true})
      } else {
        this.props.endCall(false, this.props.currentCallType)
      }
    } else {
      this.props.startCall(
        this.props.person.phoneNumber,
        this.props.currentCallType, 
        this.props.contact.id, 
        'decision-maker-' + this.props.person.id
      )
    }
  }

  closeModal(answered) {
    this.setState({showEndCallModal: false});
    this.props.endCall(answered, this.props.currentCallType)
  }

  render() {
    const inputStyle = {
      outline: 0,
      border: 0,
      width: '100%',
      paddingBottom: "8px"
    }
    const btnStyle = {
      width: 25, 
      height: 25, 
      padding: 0,
      backgroundColor: 'rgb(165, 24, 144)',
      borderColor: 'rgb(165, 24, 144)',
      outlineColor: 'rgb(165, 24, 144)',
      fontSize: 11,
      marginLeft: 5
    }
    const btnStyle2 = {
      width: 25, 
      height: 25, 
      padding: 0, 
      fontSize: 11, 
      marginLeft: 5,
    }
    const btnStyle3 = {
      ...btnStyle2,
      border: 'none'
    }
    const checkBtnStyle = !this.state.checked
      ? btnStyle
      : btnStyle2

    const suggestions = (stateKey, selectFn, attr) => 
      (selectFn(this.props) || []).filter(
        e => !this.state[stateKey].find(
          tag => tag.name === e[attr]
        )
      ).map(e => ({name: e[attr]}))

    const emailStillRequired = this.props.additionalRequiredFields && 
      this.props.additionalRequiredFields.includes('email') && 
      this.props.checkable && 
      this.state.checked &&
      (!this.state.email || !this.state.email.length)

    const tagsStillRequired = this.props.additionalRequiredFields &&
      this.props.additionalRequiredFields.includes('tag') && 
      this.props.checkable && 
      this.state.checked &&
      (!this.state.tags || !this.state.tags.length)

    const tags2StillRequired = this.props.additionalRequiredFields &&
      this.props.additionalRequiredFields.includes('tag2') && 
      this.props.checkable && 
      this.state.checked &&
      (!this.state.tags2 || !this.state.tags2.length)

    return (
      <React.Fragment>
        <div style={{
            paddingRight: 20, 
            padding: 15, 
            margin: 8, 
            marginRight: 0, 
            borderRadius: 5,
            boxShadow: '1px 1px 3px rgba(0,0,0,.2)',
            position: 'relative',
            maxWidth: 400,
            marginBottom: 'auto',
            borderColor: emailStillRequired || tagsStillRequired || tags2StillRequired || this.state.phoneFormatError
              ? 'red' : 'rgba(204, 204, 204, .5)'
        }}
          className="decision-maker-card"        
        >
          <div>
            {!this.props.noButtons &&
              <div style={{
                display: 'flex',
                right: 10,
                position: 'absolute'
              }}>
                { this.props.checkable && !this.state.checked &&
                  <Button disabled={this.isCheckDisabled()} className={this.isCheckDisabled() ? "" : "elevated"} onClick={() => {
                      if ( !this.state.checked ) {
                        const phoneNumber = (this.state.phoneNumber || '')
                          .split(' ').join('')
                          .split('-').join('')
                          .replace(/^\+358/, '0')
                        if ( this.state.unknown ) {
                          const getName = (name='Tuntematon', i=1) => {
                            const names = this.props.people.map(person => person.name)
                            if ( names.includes(name) ) {
                              return getName('Tuntematon (' + (i + 1) + ')', i + 1)
                            } else return name
                          }
                          this.props.onCheck({
                            id: this.props.person.id,
                            name: getName(),
                            notes: this.state.notes,
                            unknown: this.state.unknown
                          })
                          this.setChecked(!this.state.checked)
                        } else if ( !phoneNumber 
                            || !phoneNumber.length 
                            || !(/^\d+$/).test(phoneNumber) ) {
                          this.setState({phoneFormatError: true})
                        } else {
                          this.props.onCheck({
                            id: this.props.person.id,
                            name: this.state.name,
                            phoneNumber,
                            email: this.state.email,
                            businessUnit: this.state.businessUnit,
                            notes: this.state.notes,
                            unknown: this.state.unknown,
                            titles: this.state.tags
                              .map(tag => 
                                  this.props.decisionMakerTitles.find(title => title.title === tag.name) ||
                                  this.props.person.titles.find(title => title.title === tag.name)
                              ).filter(title => title)
                              .map(title => title.id),
                            positions: this.state.tags2
                              .map(tag => this.props.decisionMakerPositions.find(position => position.position === tag.name))
                              .filter(position => position)
                              .map(position => position.id)
                          })
                          this.setChecked(!this.state.checked)
                        }
                      } else {
                        this.setChecked(!this.state.checked)
                      }
                    }}
                    bsStyle={!this.state.checked ? 'primary' : 'default'}
                    style={checkBtnStyle} >
                    <Glyphicon glyph="ok"></Glyphicon>
                  </Button>
                } 
                { this.props.checkable && this.state.checked &&
                  <Button onClick={() => {this.handleClickCall()}}
                    disabled={this.state.unknown || (window.enableCallDelayTimer && this.props.callDelayTimer > 0) || ( this.props.call.active && this.props.call.origin !== ( 'decision-maker-' + this.props.person.id ) ) }
                    style={btnStyle2} 
                    className={
                      this.props.call.active && this.props.call.origin === ( 'decision-maker-' + this.props.person.id ) 
                        ? 'btn-danger'
                        : 'btn-success'
                    }
                    bsStyle={this.props.icon === 'plus' ? 'primary' : 'default'}
                  >
                  <FontAwesomeIcon size="xs" icon={ 
                    this.props.call.active && this.props.call.origin === ( 'decision-maker-' + this.props.person.id )
                      ? "phone-slash"
                      : "phone"
                    }/>
                  </Button>
                }
                { this.props.onClick &&
                  <Button onClick={() => {this.props.onClick()}}
                    disabled={
                      ( this.props.icon === 'minus' && 
                        this.props.call.active && 
                        this.props.call.origin === ( 'decision-maker-' + this.props.person.id ) ) || (
                          this.props.icon === 'plus' && this.props.disableAdd
                        )
                    }
                    style={this.props.icon === 'plus' ? btnStyle : btnStyle2} 
                    bsStyle={this.props.icon === 'plus' ? 'primary' : 'default'}
                    >
                    <Glyphicon glyph={this.props.icon} />
                  </Button>
                }
                  <div className="decision-maker-card-more">
                    <Nav id={"decision-maker-card-more-nav-" + this.props.person.id}>
                      <NavDropdown
                        id={"decision-maker-card-more-nav-dd-" + this.props.person.id}
                        className="hidden-xs"
                        noCaret
                        title={
                          <div>
                            <Glyphicon glyph={'option-horizontal'} />
                          </div>
                        }
                      >
                        { !this.state.unknown && this.props.person.id > 0 &&
                          <MenuItem
                            disabled={
                              this.props.disableAdd && 
                              !this.props.person.active
                            }
                            className="menu-item"
                            onSelect={() => {
                              this.props.toggleDecisionMakerActive({
                                ...this.props.person,
                                projectId: this.props.contact.projectId
                              })
                            }}
                          >
                            { this.props.t(
                                this.props.person.active
                                  ? 'setAsInactive'
                                  : 'restoreAsActive'
                              ) 
                            }
                          </MenuItem>
                        }
                        {
                          this.props.checkable &&
                          <MenuItem
                            className="menu-item"
                            onSelect={() => {
                              const value = !this.state.unknown
                              const mergeObj = {
                                  name: value ? 'Tuntematon' : '',
                                  notes: '',
                                  phoneNumber: '',
                                  email: '',
                                  tags: [],
                                  tags2: [],
                                  businessUnit: ''
                                }
                              this.handleChangeInfo('unknown', {target: {value}}, mergeObj)
                            }}
                          >
                            { this.props.t(
                                !this.state.unknown
                                  ? 'setAsUnknown'
                                  : 'restoreAsKnown'
                              ) 
                            }
                          </MenuItem>
                        }
                      </NavDropdown>
                    </Nav>
                  </div>
              </div>
            }
            <b className="decision-maker-field-title">{this.props.t('name')}</b>
            <div style={{ marginBottom: 3 }}>
              { this.state.unknown &&
                <input
                  readOnly={true}
                  value={this.state.name}
                  type="text"
                  style={inputStyle}
                />
              }
              { !this.state.unknown &&
                <input
                  placeholder={this.props.readOnly ? '' : "Lisää nimi..."}
                  readOnly={this.props.readOnly}
                  value={this.state.name || ''}
                  type="text"
                  style={inputStyle}
                  onChange={e => {this.handleChangeInfo('name', e)}}
                />
              }
            </div>
        </div>
        
      {
        this.state.unknown &&
        <div style={{ display: 'flex', flexFlow: 'column' }}>
          <b className="decision-maker-field-title">Memo</b>
          <textarea
            style={{...inputStyle, width: 370, resize: 'none', height: 137}}
            onChange={e => {this.handleChangeInfo('notes', e)}}
            value={this.state.notes || ''}
            placeholder='Syy tietojen puuttumiselle...'
          />
        </div>
      }
        { !this.state.unknown && <>

        <b className="decision-maker-field-title decision-maker-field-title-tags" style={{color: tagsStillRequired ? 'red' : ''}}>
          {this.props.t('department')}
        </b>
        <div className={'business-2' + (this.state.tags.length ? '' : ' no-tags' )} 
          style={this.props.readOnly ? {pointerEvents: 'none'} : {}}>
          <ReactTags
            maxSuggestionsLength={1000}
            ref={this.reactTags}
            tags={this.state.tags}
            suggestions={
              suggestions(
                'tags', 
                props => [
                  ...props.decisionMakerTitles || [], 
                  ...(props.person.titles || []).filter(a => !(props.decisionMakerTitles || []).find(b => a.id === b.id))
                ], 
                'title'
              )
            }
            onDelete={this.props.readOnly ? ()=>{} : this.onDelete('tags').bind(this)}
            onAddition={this.props.readOnly ? ()=>{} : this.onAddition('tags').bind(this)}
            placeholderText={(this.props.readOnly || this.state.tags.length) ? '' : 'Lisää vastuualueita...'}
            minQueryLength={0}
            suggestionsTransform={
              this.props.readOnly 
                ? ()=>[]
                : (query, suggestions) =>
                  suggestions.filter(
                    suggestion => 
                      suggestion.name.toLowerCase()
                        .indexOf(query.toLowerCase()) >= 0
                  )
            }
          />
        </div>
        <b className="decision-maker-field-title  decision-maker-field-title-tags" style={{color: tags2StillRequired ? 'red' : ''}}>
          {this.props.t('title')}
        </b>
        <div className={'business-1' + (this.state.tags2.length ? '' : ' no-tags')} 
          style={this.props.readOnly ? {pointerEvents: 'none'} : {}}>
          <ReactTags
            maxSuggestionsLength={1000}
            ref={this.reactTags2}
            tags={this.state.tags2}
            suggestions={suggestions('tags2', props => props.decisionMakerPositions, 'position')}
            onDelete={this.props.readOnly ? ()=>{} : this.onDelete('tags2').bind(this)}
            onAddition={this.props.readOnly ? ()=>{} : this.onAddition('tags2').bind(this)}
            placeholderText={(this.props.readOnly || this.state.tags2.length) ? '' : 'Lisää titteleitä...'}
            minQueryLength={0}
            suggestionsTransform={
              this.props.readOnly 
                ? ()=>[]
                : (query, suggestions) =>
                  suggestions.filter(
                    suggestion => 
                      suggestion.name.toLowerCase()
                        .indexOf(query.toLowerCase()) >= 0
                  )
            }
          />
        </div>
        <b className="decision-maker-field-title">{this.props.t('businessUnit')}</b>
        <div style={{ marginBottom: 3 }}>
          <input
            placeholder={this.props.readOnly ? '' : "Lisää liiketoimintayksikkö..."}
            readOnly={this.props.readOnly}
            value={this.state.businessUnit || ''}
            type="text"
            style={inputStyle}
            onChange={e => {this.handleChangeInfo('businessUnit', e)}}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '30%', minWidth: 170 }}>
            <b className="decision-maker-field-title" style={{ color: this.state.phoneFormatError ? 'red' : 'unset' }}>
              {this.props.t('phone')}
            </b>
            <div>
              <input
                  placeholder={this.props.readOnly ? '' : "Lisää puhelin..."}
                  readOnly={this.props.readOnly}
                  value={this.state.phoneNumber || ''}
                  type="text"
                  style={
                    this.state.phoneFormatError
                      ? {...inputStyle, paddingBottom: "4px" }
                      : inputStyle
                  }
                  onChange={e => {this.handleChangeInfo('phoneNumber', e)}}
                />
            </div>
            {
              this.state.phoneFormatError &&
              <div style={{ marginLeft: 1, marginTop: 0, fontSize: '0.8em', color: 'red' }}>
                <div>Virheellinen muoto. Käytä vain numeroita ilman maakoodia, esim. <b>0401234567</b>.</div>
              </div>
            }
          </div>
          <div style={{ flex: 1, minWidth: 200 }}>
            <b className="decision-maker-field-title" style={{ color: emailStillRequired ? 'red' : '' }}>
              {this.props.t('email')}
            </b>
            <div>
              <input
                  placeholder={this.props.readOnly ? '' : "Lisää sähköpostiosoite..."}
                  readOnly={this.props.readOnly}
                  value={this.state.email || ''}
                  type="text"
                  style={inputStyle}
                  onChange={e => {this.handleChangeInfo('email', e)}}
                />
            </div>
          </div>
        </div>
        </> }
      </div>
      {
        this.props.person.id &&
          <Modal show={this.state.showEndCallModal}>
            <Modal.Header closeButton={false}>
              <Modal.Title>{this.props.t('call.didContactAnswer')}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button onClick={() => this.closeModal(true)}>
                {this.props.t('yes')}
              </Button>
              <Button onClick={() => this.closeModal(false)}>
                {this.props.t('no')}
              </Button>
            </Modal.Footer>
          </Modal>
      }
    </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  decisionMakerTitles: state.contacts.decisionMakerTitles,
  decisionMakerPositions: state.contacts.decisionMakerPositions,
  call: state.call.call,
  callDelayTimer: state.call.delayTimer,
  currentCallType: state.callLists.current,
  contact: state.contacts.contact,
  useFlow: (state.user.booker || {}).useFlow === true
});
const mapDispatchToProps = dispatch => ({
  toggleDecisionMakerActive: decisionMaker => {
    dispatch(contactActions.patchDecisionMaker({
      decisionMaker: {
        id: decisionMaker.id, 
        active: !decisionMaker.active,
        projectId: decisionMaker.projectId,
        businessId: decisionMaker.businessId
      }
    }))
  },
  getDecisionMakerTitles: projectId => {
    dispatch(contactActions.getDecisionMakerTitles({projectId}))
  },
  getDecisionMakerPositions: () => {
    dispatch(contactActions.getDecisionMakerPositions())
  },
  startCall: (phoneNumber, type, contactId, origin) => {
    dispatch(callActions.startCall({
      phoneNumber, type: type || 'booking', contactId, origin
    }));
  },
  endCall: (answered, type, bookingId) => {
    dispatch(
      callActions.endCall({
        answered,
        type,
        bookingId
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(DecisionMakerInfoCard)
);