import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';

import * as actions from './actions';

const breakStarted = handleActions(
  {
    [actions.startBreak]: (state, action) => action.payload.startedAt,
    [actions.endBreak]: () => null
  },
  null
);

const elapsedBreak = handleActions(
  {
    [actions.resetElapsedBreak]: () => 0,
    [actions.setElapsedBreak]: (state, action) =>
      state + action.payload.elapsedBreak || state
  },
  0 // seconds
);

const workStarted = handleAction(
  actions.startWork,
  (state, action) => action.payload || state,
  null
);

export default combineReducers({
  breakStarted,
  elapsedBreak,
  workStarted
});
