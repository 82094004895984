import React, {useState } from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { 
    ToggleButton,
    ToggleButtonGroup,
    Button, 
    Modal 
} from 'react-bootstrap';
import * as bookingActions from '../../redux/booking/actions';
import * as contactActions from '../../redux/contacts/actions';
  
const ContactBookingList = ({contact, user, t, redirect, deleteBooking, cancelBooking, reloadOnCancelOrDelete=false, businessCallFlow=false}) => {

    const [showModal, setShowModal] = useState(null)
    const [cancelReason, setCancelReason] = useState('')
    const [showDeleteModal, setShowDeleteModal] = useState(null)

    const closeModal = answered => {
        if (answered) {
            cancelBooking(
                showModal, 
                cancelReason, 
                reloadOnCancelOrDelete
            )
        }
        setCancelReason('')
        setShowModal(null)        
    }    

    const closeDeleteModal = confirm => {
        if ( confirm ) {
            deleteBooking(
                showDeleteModal, 
                reloadOnCancelOrDelete
            )
        }
        setShowDeleteModal(null)
    }

    const goToBooking = (contactId, bookingId) => {
        redirect(`/contacts/${contactId}/bookings/${bookingId}`);
    }

    const getBookingLink = (contactId, booking, i) => {
        if (booking.status && booking.status.superType === 'cancelled') {
            return bookingLink(contactId, booking, i);
        } else {
            return bookingLinkWithCancel(contactId, booking, i);
        }
    }

    const bookingLink = (contactId, booking, i) => (
        <div className="booking-link" key={i}>
            <Button
                style={{minWidth: 150, textAlign: 'left'}}
                bsStyle="link"
                onClick={() => goToBooking(contactId, booking.id)}
            >
                {moment(booking.startsAt).format('H:mm D.M.YYYY')}
            </Button>
            { !businessCallFlow &&
                <Button
                    style={{ visibility: 'hidden' }}
                    bsStyle="link"
                    onClick={() => {}}
                >
                    {t('booking.cancel')}
                </Button>
            }
            { user.type !== 'booker' &&
                <Button
                    bsStyle="link"
                    onClick={() => setShowDeleteModal(booking.id)}
                >
                    {t('booking.delete')}
                </Button>
            }
        </div>
    )
    
    const bookingLinkWithCancel = (contactId, booking, i) => (
        <div className="booking-link" key={i}>
            <Button
                style={{minWidth: 150, textAlign: 'left'}}      
                bsStyle="link"
                onClick={() => goToBooking(contactId, booking.id)}
            >
                {moment(booking.startsAt).format('H:mm D.M.YYYY')}
            </Button>
            { !businessCallFlow &&
                <Button
                    bsStyle="link"
                    onClick={() => setShowModal(booking.id)}
                >
                    {t('booking.cancel')}
                </Button>
            }
            { user.type !== 'booker' &&
                <Button
                    bsStyle="link"
                    onClick={() => setShowDeleteModal(booking.id)}
                >
                    {t('booking.delete')}
                </Button>
            }
        </div>
    )

    return <div>
        {contact.bookings.map((booking, i) => 
            <div key={i} className="booking-wrapper">
                { getBookingLink(contact.id, booking, i) }
                <div className="details">
                    <div className="detail-row">
                        <div className="detail-name">{ t('booking.booked') }:</div>
                        <div className="detail-value">{ moment(booking.createdAt).format('H:mm D.M.YYYY') }</div>
                    </div>
                    <div className="detail-row">
                        <div className="detail-name">{ t('booking.booker') }:</div>
                        <div className="detail-value">
                            { booking.booker ? booking.booker.user.firstName : '' } { booking.booker ? booking.booker.user.lastName : '' }
                        </div>
                    </div>
                </div>
            </div>
        )}
        <Modal show={showModal !== null}>
            <Modal.Header closeButton={false}>
                <Modal.Title>
                    {t('booking.chooseCancelReason')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ToggleButtonGroup
                    name="options"
                    onChange={e => setCancelReason(e)}
                    type="radio"
                    value={cancelReason}
                >
                    <ToggleButton value="callLater">
                        {t('reports.headers.callLater')}
                    </ToggleButton>
                    <ToggleButton value="noInterest">
                        {t('reports.headers.noInterest')}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    bsStyle="danger"
                    disabled={cancelReason === ''}
                    onClick={() => closeModal(true)}
                >
                    {t('booking.confirm')}
                </Button>
                <Button onClick={() => closeModal(false)}>
                    {t('close')}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal onHide={closeDeleteModal} show={showDeleteModal !== null}>
            <Modal.Header closeButton={false}>
                <Modal.Title>
                    {t('booking.confirmDelete')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button onClick={() => closeDeleteModal(true)}>
                    {t('yes')}
                </Button>
                <Button onClick={() => closeDeleteModal(false)}>
                    {t('no')}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    getContact: contactId => {
        dispatch(contactActions.getContact({ contactId, inferServicePointIdIfNull: 1 }));
    },    
    redirect: url => {
        dispatch(push(url));
    },
    deleteBooking: (bookingId, reload) => {
        dispatch(
            bookingActions.deleteBooking({
                bookingId,
                reload
            })
        );
    },
    cancelBooking: (bookingId, cancelReason, reload) => {
        dispatch(
            bookingActions.cancelBooking({
                bookingId,
                cancelReason,
                reload
            })
        );
    }    
})
    
export default connect(mapStateToProps, mapDispatchToProps)(
    translate('translations')(ContactBookingList)
)
  