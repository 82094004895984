import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const servicePoints = handleAction(
  actions.gotServicePoints,
  (state, action) => action.payload || state,
  []
);

const subAreaServicePoints = handleAction(
  actions.gotSubAreaServicePoints,
  (state, action) => action.payload || state,
  []
);

const postalAreas = handleAction(
  actions.gotPostalAreas,
  (state, action) => action.payload || state,
  []
);

const setServicePointsDone = handleAction(
  actions.setServicePointsDone,
  (state, action) => action.payload || state,
  []
);

export default combineReducers({
  postalAreas,
  servicePoints,
  subAreaServicePoints,
  setServicePointsDone
});
