import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { contactStatusToColor } from '../../utils';

import { SEARCH_TYPES } from '../../consts';

import * as contactActions from '../../redux/contacts/actions';
import * as searchActions from '../../redux/search/actions';
import * as callListActions from '../../redux/call-lists/actions';

class Search extends Component {
  constructor(props) {
    super(props);
    this.delay = {
      timeout: null,
      ms: 250
    }
    /*
    document.addEventListener('click', event => {
      if (!(event.target === this.input)) {
        clearTimeout(this.delay.timeout)
        this.props.clearSearchResults();
        this.setState({
          showResults: false
        });
      }
    })
    */
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  doesNotHaveCustomUserInterface(contact) {
    return contact 
      ? !contact.project.customUserInterfaceId 
      : !this.props.selectedProject || !this.props.selectedProject.customUserInterfaceId
  }
  
  /**
   * Alert if clicked on outside of element
   */
   handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.props.parentState.showResults) {
      this.props.clearSearchResults();
      clearTimeout(this.delay.timeout)
      this.props.setParentState({
        showResults: false
      });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleChange(event) {

      // Don't search is call is active.
      if (
        !this.props.call.active &&
        this.props.booking.reservation === null
      ) {

        this.props.setParentState({ searchString: event.target.value }, () => {

        if ( this.props.parentState.searchString.length ) {
          clearTimeout(this.delay.timeout)
          this.delay.timeout = setTimeout(()=>{
            this.doSearch(this.props.parentState.searchString)
          }, this.delay.ms)
        } else {
          this.props.clearSearchResults();
          clearTimeout(this.delay.timeout)
          this.props.setParentState({
            searchString: '',
            showResults: false
          });
        }

      })

    }

  }

  goToBooking = bookingId => {
    this.props.redirect(`/bookings/${bookingId}`);
    this.props.setParentState({
      searchString: '',
      showResults: false
    });
  };

  goToContact = contact => {
    const contactId = contact.id
    const cuiId = contact.project.customUserInterfaceId
    if ( this.props.user.type === 'admin' || !cuiId ) {
      this.props.getContact(contactId, false, true);
      this.props.redirect(`/contacts/${contactId}`);
    } else if ( cuiId === 2 ) {
      const path = `/ui/${cuiId}/call/${contactId}`
      if ( this.props.path !== path ) {
        this.props.resetCurrentCallType()
        this.props.redirect(path);
      }
    } else {
      this.props.getContact(contactId, true);
      this.props.redirect(`/ui/${cuiId}/contact/${contactId}`);
    }

    this.props.setParentState({
      searchString: '',
      showResults: false
    });
  };

  goToSearchResult = searchResult => {
    if (this.props.searchType === SEARCH_TYPES.BOOKING) {
      this.goToBooking(searchResult.id);
    } else if (this.props.searchType === SEARCH_TYPES.CONTACT) {
      this.goToContact(searchResult);
    }
  };

  doSearch(s, bypassCharMinimun){
    const clientId = this.props.user.type === 'teamLeader'
      && this.props.selectedClient?.teamLeaderSearchAllProjects
        ? this.props.selectedClient.id
        : null
    const projectId = this.props.user.type === 'booker' || this.props.user.type === 'teamLeader'
      ? this.props.selectedProject.id
      : null
    this.props.clearSearchResults();
    if ( s.length ) {
      if ( bypassCharMinimun || s.length > 3 ) {
        this.props.getSearchResults(
          s, 
          this.props.searchType,
          projectId,
          !this.doesNotHaveCustomUserInterface(),
          clientId
        );
        this.props.setParentState({
          showResults: true
        });
      } else {
        this.props.setParentState({
          showResults: false
        });
      }
    }
  }

  isDisabled(){
    return ( this.props.user.type === 'booker' || this.props.user.type === 'teamLeader' )
      && this.props.selectedProject.id == null
  }

  render() {
    let inputStyle = {
      background: "transparent"
    }
    if ( this.isDisabled() ) {
      inputStyle.cursor = "not-allowed"
    }
    return (
      <div className="search" style={{ position: 'relative' }} ref={this.wrapperRef}>
        <Glyphicon glyph="search"
          style={ inputStyle }
          onClick={event => {
            if ( !this.isDisabled() ) {
              this.doSearch(this.input.value)
            }
          }} />
        <input
          style={ inputStyle }
          disabled={ this.isDisabled() }
          onChange={event => this.handleChange(event)}
          placeholder={this.props.placeholder}
          onFocus={event => {
            this.doSearch(this.input.value);
          }}
          onClick={e => e.stopPropagation()}
          onKeyDown={event => {
            if (event.keyCode === 13 ) {
              clearTimeout(this.delay.timeout)
              this.doSearch(this.input.value, true)
            }
            event.stopPropagation()
          }}
          ref={element => {
            this.input = element;
          }}
        />
        { this.props.inProgress && 
          <div style={{ position: 'absolute', right: -4, bottom: 5, pointerEvents: 'none' }}>
            <div className="spinner-container">
              <span className="spinner">
                <span className="bottom" 
                  style={{ borderTopColor: '#a51890', padding: 4, borderWidth: 3 }}>
                </span>
              </span>
              <span className="loading"></span>
            </div>
          </div>
        }
        {this.props.parentState.showResults && (
          <div className="search-results" style={{ padding: 0, overflow: "auto", maxHeight: "50vh", minWidth: "150px", scrollbarWidth: "thin" }}>
            {this.props.searchResults.map((searchResult, i) => {
              return <>
                {
                  ( this.props.user.type === 'admin' || (
                  this.props.user.type === 'teamLeader' && 
                  this.props.selectedClient?.teamLeaderSearchAllProjects ) ) &&
                  this.props.searchType === SEARCH_TYPES.CONTACT && 
                  searchResult.projectId !== this.props.searchResults[i-1]?.projectId &&
                    <div style={{ 
                      backgroundColor: 'rgb(238, 238, 238)', 
                      padding: '5px 15px 5px 10px',
                      position: 'sticky',
                      top: 0
                    }}>
                        {searchResult.project.name}
                    </div>
                }
                <Button
                  title={ this.props.searchType === SEARCH_TYPES.CONTACT ? searchResult.status?.name : '' }
                  style={{ borderBottom: "1px solid #eee", padding: this.props.searchType === SEARCH_TYPES.BOOKING ? '5px' : 0 }}
                  bsStyle="link"
                  key={i}
                  onClick={event => {
                    event.preventDefault()
                    this.goToSearchResult(searchResult);
                    event.stopPropagation()
                  }}
                >
                  {this.props.searchType === SEARCH_TYPES.BOOKING && (
                    <div className="search-result">
                      <div>
                        {moment(searchResult.startsAt).format('D.M. H:mm')}
                      </div>
                      <div>{searchResult.customerName}</div>
                    </div>
                  )}
                  {this.props.searchType === SEARCH_TYPES.CONTACT && searchResult.project.customUserInterfaceId !== 1 &&
                    <div style={{ display: "flex", flexFlow: "row" }}>
                      <div style={{
                        minWidth: "5px",
                        width: "5px",
                        marginRight: "5px",
                        backgroundColor: contactStatusToColor(searchResult.status)
                      }}>
                      </div>
                      <div style={{ marginRight: "10px" }}>
                        <div style={{ fontWeight: "bold" }}>{ searchResult.name }</div>
                        <div style={{ fontSize: ".8em" }}>{ searchResult.phoneNumber }</div>
                        {searchResult.businessId && <div style={{ fontSize: ".8em" }}>{ searchResult.businessId }</div>}
                        {searchResult.firstDecisionMaker && <div style={{ fontSize: ".8em"}}>{ searchResult.firstDecisionMaker.name }</div>}
                        <div style={{ fontSize: ".8em" }}>{ searchResult.campaign.name }</div>
                      </div>
                    </div>
                  }
                  {this.props.searchType === SEARCH_TYPES.CONTACT && searchResult.project.customUserInterfaceId === 1 &&
                    <div style={{ display: "flex", flexFlow: "row" }}>
                      <div style={{
                        marginRight: "5px",
                      }}>
                      </div>
                      <div style={{ marginRight: "10px" }}>
                        <div style={{ fontWeight: "bold" }}>{ searchResult.name }</div>
                        <div style={{ fontSize: ".8em" }}>{ searchResult.phoneNumber }</div>
                        <div style={{ fontSize: ".8em" }}>{ searchResult.email }</div>
                      </div>
                    </div>
                  }
                </Button>
              </>
            })}
          </div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  searchType: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  booking: state.booking,
  call: state.call.call,
  searchResults: state.searchResults.result,
  selectedProject: state.call.selectedProject,
  user: state.user,
  path: state.router.location.pathname,
  inProgress: state.searchResults.state === true,
  selectedClient: state.call.selectedClient,
  projects: state.clients.projects
});

const mapDispatchToProps = dispatch => ({
  resetCurrentCallType: () => {
    dispatch(callListActions.setCurrentCallType(null));
  },
  clearSearchResults: () => {
    dispatch(searchActions.clearSearchResults());
  },
  getContactCard: contactId => {
    dispatch(contactActions.getContactCard({contactId}))
  },
  getContact: (contactId, singleQuery=false, useBusyIndicator=false) => {
    dispatch(
      contactActions.getContact({
        contactId, 
        inferServicePointIdIfNull: 1, 
        singleQuery, 
        useBusyIndicator
      })
    );
  },
  getSearchResults: (searchString, searchType, projectId=null, searchFromEmailField=false, clientId=null) => {
    dispatch(
      searchActions.getSearchResults({
        searchString,
        searchType,
        projectId,
        searchFromEmailField,
        clientId
      })
    );
  },
  redirect: url => {
    dispatch(push(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(Search)
);
