import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { BusyIndicator } from '../../components';
import { Button, Glyphicon } from 'react-bootstrap';

class AdminTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openInactives: false,
      search: ""
    }
  }

  render() {
    const chevronStyle = {
      float: "right"
    }
    const subheadingStyle = {
      cursor: "pointer",
      fontWeight: "bold",
      background: "linear-gradient(180deg, rgba(0,0,0,.01) 0%, rgba(255,255,255,1) 100%, rgba(0,0,0,1) 100%)",
      width: '100%',
      marginBottom: this.state.openInactives ? "0" : "20px"
    }
    const btnStyle = {
      color: "#ffffff",
      backgroundColor: "#a51890",
      borderColor: "#a51890",
      fontSize: "16px",
      marginRight: "10px",
    }
    const searchFilter = row => {
      try {
        if (this.state.search === "") {
          return true;
        }
        const split = this.state.search.split(" ")
        for (let key in row) {
          if (row[key] && typeof row[key] === "string") {
            const value = row[key].toLowerCase();
            if (split.every(word => value.includes(word.toLowerCase()))) {
              return true
            }
          }
        }  
      } catch ( e ) {
        console.error(e)
      }
      return false
    }

    const getTable = (columns, rows) => {
        return <Table>
            <thead>
            <tr>
                {columns.map(
                  column => <th key={column.key} style={{ borderBottom: 0 }}>{column.label}</th>
                )}
            </tr>
            </thead>
            <tbody>
            { 
                rows.map((row, i) =>
                <tr key={i} className={row._disabled ? "third-of-opacity" : ""}>                        
                    {
                        columns.map(
                            column => {
                                if ( column.onClick ) {
                                    return (
                                        <td key={column.key}>
                                            <Button
                                                style={{ fontSize: "15px" }}
                                                bsStyle="link"
                                                className="add-button"
                                                onClick={() => column.onClick(row)}
                                            >
                                                { row[column.key] }{ column.cellSuffix?.(row) }
                                            </Button>
                                        </td>
                                    )
                                }
                                return (
                                    <td key={column.key} style={{ verticalAlign: 'middle' }}>
                                        { row[column.key] }{ column.cellSuffix?.(row) }
                                    </td>
                                )
                            }
                        )
                    }
                </tr>
            )}
            </tbody>
        </Table>
    }

    return (
      <>
        {
          this.props.isLoading &&
            <div style={{ width: '100%' }}>
              <BusyIndicator />
            </div>
        }
        {
          !this.props.isLoading &&
          <div className="section">
            <div className="search" style={{ 
              display: 'flex', 
              alignItems: 'center', 
              flex: 1,
              margin: 5,
              marginTop: 10,
              marginBottom: 10,
            }}>
              <Glyphicon glyph="search"
                onClick={event => {
                }} />
                <input style={{ 
                  flex: 1,
                  borderBottom: 0,
                  backgroundColor: "#fafafa",
                  borderRadius: 20,
                  paddingLeft: 10,
                  paddingBottom: 4,
                }}
                autoFocus
                onChange={event => {
                  this.setState({search: event.target.value})
                }} />
            </div>
            { getTable(this.props.columns, this.props.rows.filter(row => !row._inactive).filter(searchFilter)) }
            { !this.props.noInactives &&
              <>
                <div className="subheading" onClick={ () => {this.setState({openInactives: !this.state.openInactives})} } style={ subheadingStyle }>
                  { this.props.t("inactives") } ({ this.props.rows.filter(row => row._inactive).filter(searchFilter).length })
                  <Glyphicon style={ chevronStyle } glyph={ this.state.openInactives ? "chevron-down" : "chevron-up" } />
                </div>
                { this.state.openInactives &&
                  <div className="section" style={{ paddingBottom: 0, display: "flex", flexFlow: "column", width: '100%', borderBottom: 0 }}>
                      { getTable(this.props.columns, this.props.rows.filter(row => row._inactive).filter(searchFilter)) }
                  </div>
                }
            </>              
            }
            {
              !this.props.isLoading && this.props.newBtn &&
                <Button
                  style={ btnStyle }
                  bsStyle="primary"
                  onClick={this.props.newBtn.onClick}
                >
                  <Glyphicon glyph="plus" />
                  <span>
                    { " " + this.props.newBtn.label }
                  </span>
                </Button>
              }
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(AdminTable)
);
