import { handleActions } from 'redux-actions';

import * as actions from './actions';

export default handleActions(
  {
    [actions.gotStatistics]: (state, action) => action.payload || state,
    [actions.gotRepresentativeStatistics]: (state, action) => {
      return Object.assign({}, state, {
        representativeStatistics: action.payload
      })
    },
  },
  {}

);
