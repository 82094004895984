import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const campaigns = handleAction(
  actions.gotCampaigns,
  (state, action) => action.payload || state,
  []
);

const projects = handleAction(
  actions.gotProjects,
  (state, action) => action.payload || state,
  []
);

const project = handleAction(
  actions.gotProject,
  (state, action) => action.payload || state,
  []
);

const postCampaign = handleAction(
  actions.postedCampaign,
  (state, action) => action.payload || state,
  []
);

const patchedProject = handleAction(
  actions.patchedProject,
  (state, action) => action.payload || state,
  []
);

const contactImportTemplates = handleAction(
  actions.gotContactImportTemplates,
  (state, action) => action.payload || state,
  []
);

const representatives = handleAction(
  actions.gotRepresentatives,
  (state, action) => action.payload || state,
  []
);

const representativesSubAreas = handleAction(
  actions.gotRepresentativesSubAreas,
  (state, action) => action.payload || state,
  []
);

const bookers = handleAction(
  actions.gotBookers,
  (state, action) => action.payload || state,
  []
);

const setBookersDone = handleAction(
  actions.setBookersDone,
  (state, action) => action.payload || state,
  []
);

const subAreas = handleAction(
  actions.gotSubAreas,
  (state, action) => action.payload || state,
  []
);

const locations = handleAction(
  actions.gotLocations,
  (state, action) => action.payload || state,
  []
);

const contactStatuses = handleAction(
  actions.gotContactStatuses,
  (state, action) => action.payload || state,
  []
);

const sentSMS = handleAction(
  actions.sentSMS,
  (state, action) => action.payload,
  {}
);

const sentSMSBatch = handleAction(
  actions.sentSMSBatch,
  (state, action) => action.payload,
  {}
);

const sentEmail = handleAction(
  actions.sentEmail,
  (state, action) => action.payload,
  {}
);

const additionalContactInfo = handleAction(
  actions.gotAdditionalContactInfo,
  (state, action) => action.payload || state,
  []
);

const setAdditionalContactInfoDone = handleAction(
  actions.setAdditionalContactInfoDone,
  (state, action) => action.payload || state,
  []
);

const bookerTarget = handleAction(
  actions.gotBookerTarget,
  (state, action) => action.payload || state,
  {}
);

const setBookerTargetDone = handleAction(
  actions.setBookerTargetDone,
  (state, action) => action.payload || state,
  {}
);

const projectsLoading = handleAction(
  actions.setProjectsLoading,
  (state, action) => action.payload,
  false
);

const campaignsLoading = handleAction(
  actions.setCampaignsLoading,
  (state, action) => action.payload,
  false
);

const decisionMakerTitles = handleAction(
  actions.gotDecisionMakerTitles,
  (state, action) => action.payload,
  []
);

const setContactStatusesDone = handleAction(
  actions.setContactStatusesDone,
  (state, action) => action.payload || state,
  []
);

const setDecisionMakerTitlesProjectDone = handleAction(
  actions.setDecisionMakerTitlesProjectDone,
  (state, action) => action.payload || state,
  []
);

export default combineReducers({
  campaigns,
  projects,
  project,
  patchedProject,
  postCampaign,
  contactImportTemplates,
  representatives,
  bookers,
  setBookersDone,
  subAreas,
  locations,
  representativesSubAreas,
  contactStatuses,
  sentSMS,
  sentSMSBatch,
  sentEmail,
  additionalContactInfo,
  setAdditionalContactInfoDone,
  bookerTarget,
  setBookerTargetDone,
  projectsLoading,
  campaignsLoading,
  decisionMakerTitles,
  setDecisionMakerTitlesProjectDone,
  setContactStatusesDone
});
