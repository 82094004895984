import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import * as actions from './actions';

const clients = handleAction(
  actions.gotClients,
  (state, action) => action.payload || state,
  []
);

const classes = handleAction(
  actions.gotClientClasses,
  (state, action) => action.payload || state,
  []
);

const postProject = handleAction(
  actions.postedProject,
  (state, action) => action.payload || state,
  []
);

const projects = handleAction(
  actions.gotProjects,
  (state, action) => action.payload || state,
  []
);

const representatives = handleAction(
  actions.gotRepresentatives,
  (state, action) => action.payload || state,
  []
);

const postRepresentative = handleAction(
  actions.postedRepresentative,
  (state, action) => action.payload || state,
  []
);

const client = handleAction(
  actions.gotClient,
  (state, action) => action.payload || state,
  []
);

const patchedClient = handleAction(
  actions.patchedClient,
  (state, action) => action.payload || state,
  []
);

const postedClient = handleAction(
  actions.postedClient,
  (state, action) => action.payload || state,
  []
);

const postClientAdmin = handleAction(
  actions.postedClientAdmin,
  (state, action) => action.payload || state,
  []
);

const clientAdmins = handleAction(
  actions.gotClientAdmins,
  (state, action) => action.payload || state,
  []
);

const subAreas = handleAction(
  actions.gotSubAreas,
  (state, action) => action.payload || state,
  []
);

const clientsLoading = handleAction(
  actions.setClientsLoading,
  (state, action) => action.payload,
  false
);

const projectsLoading = handleAction(
  actions.setProjectsLoading,
  (state, action) => action.payload,
  false
);

const representativesLoading = handleAction(
  actions.setRepresentativesLoading,
  (state, action) => action.payload,
  false
);

const clientAdminsLoading = handleAction(
  actions.setClientAdminsLoading,
  (state, action) => action.payload,
  false
);

export default combineReducers({
  client,
  classes,
  patchedClient,
  postedClient,
  clients,
  projects,
  postProject,
  representatives,
  postRepresentative,
  postClientAdmin,
  clientAdmins,
  subAreas,
  clientsLoading,
  projectsLoading,
  representativesLoading,
  clientAdminsLoading
});
