import { handleActions } from 'redux-actions';

import * as actions from './actions';

export default handleActions({
  [actions.setAdminBgprocRead]: (state, action) => ({
    ...state, admin: {
      ...state.admin, 
      bgprocRead: action.payload.bgprocRead
  }}),
  [actions.setAdminBgprocTypes]: (state, action) => ({
    ...state, admin: {
      ...state.admin, 
      bgprocType: action.payload.bgprocType
  }}),
  [actions.loggedIn]: (state, action) => ({ ...action.payload }),
  [actions.loggedOut]: () => ({}),
  [actions.register]: (state, action) => ({ ...action.payload }),
  [actions.registered]: (state, action) => ({ ...action.payload }),
  [actions.loginUnauthorized]: (state, action) => ({ ...action.payload }),
  [actions.acceptedTermsOfUse]: (state, action) => ({
    ...state, 
    acceptedTermsOfUseAt: action.payload.acceptedTermsOfUseAt
  })
}, {});
