import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';

import * as actions from './actions';

const contact = handleActions(
  {
    [actions.setContactSubArea]: (state, action) => {
      return Object.assign({}, state, {
        subAreaId: action.payload.subAreaId
      });
    },
    [actions.setContactServicePoint]: (state, action) => {
      return Object.assign({}, state, {
        servicePointId: action.payload.servicePointId
      });
    },
    [actions.setContactRepresentative]: (state, action) => {
      return Object.assign({}, state, {
        representativeId: action.payload.representativeId
      });
    },
    [actions.setContactNotesDone]: (state, action) => {
      return Object.assign({}, state, {
        notes: action.payload.notes
      });
    },
    [actions.patchContact]: (state, action) => {
      if ( action.payload.contactData ) {
        const i = state.contactData.findIndex(
          data => data.id === action.payload.contactData[0].id
        )
        let o = [...state.contactData]
        o[i] = {...o[i], ...action.payload.contactData[0]}
        state.contactData = o
        state = {...state}
      } else if ( action.payload.location ) {
        state.location = {...state.location, ...action.payload.location}
        state = {...state}
      } else {
        state = {...state, ...action.payload.contact}
      }
      return state
    },
    [actions.gotContactData]: (state, action) => {
      const newState = {...state}
      newState.contactData = action.payload.contactData
      return newState
    },
    [actions.gotContact]: (state, action) => action.payload,
    [actions.gotBookerForContact]: (state, action) => ({...state, assignedToBookerId: action.payload.bookerId}),
    [actions.resetContact]: () => {},
    [actions.setContactData]: (state, action) => {
      return Object.assign({}, state, {
        contactData: action.payload.contactData
      });
    }
  },
  {}
);

const postedContact = handleActions(
  {
    [actions.postedContact]: (state, action) => action.payload,
  },
  {}
);

const contactCard = handleActions(
  {
    [actions.gotContactCard]: (state, action) => action.payload,
    [actions.setRepresentative]: (state, action) => {
      return Object.assign({}, state, {
        representativeId: action.payload.representativeId
      });
    },
    [actions.setContactCardServicePoint]: (state, action) => {
      return Object.assign({}, state, {
        servicePointId: action.payload.servicePointId
      });
    },
    [actions.setContactCardSubArea]: (state, action) => {
      return Object.assign({}, state, {
        subAreaId: action.payload.subAreaId
      });
    },
    [actions.setContactCardModified]: state => {
      return Object.assign({}, state, {isModified: true});
    },
  },
  {}
);

const contactStatuses = handleAction(
  actions.gotContactStatuses,
  (state, action) => action.payload || state,
  []
);

const count = handleAction(
  actions.gotContacts,
  (state, action) => action.payload,
  0
);

const postalAreas = handleAction(
  actions.gotPostalAreas,
  (state, action) => action.payload,
  {}
);

const bundlePatched = handleAction(
  actions.setBundlePatched,
  (state, action) => action.payload,
  null
);

const contactsForRemoval = handleActions({
  [actions.removedContacts]: (state, action) => ({...state, removedContacts: action.payload}),
  [actions.clearContactsForRemoval]: (state, action) => ({contacts: null, columns: []}),
  [actions.gotContactsForRemoval]: (state, action) => {
      if ( !action.payload.page ) {
        return action.payload
      } else {
        return {
          ...action.payload,
          contacts: [...state.contacts, ...action.payload.contacts]
        }
      }
    }
  },
  {}
);

const contactsForReassignment = handleActions({
  [actions.reassignedContacts]: (state, action) => ({...state, reassignedContacts: action.payload}),
  [actions.clearContactsForReassignment]: (state, action) => {},
  [actions.gotContactsForReassignment]: (state, action) => ({rows: action.payload})
}, {});

const decisionMakerTitles = handleAction(
  actions.gotDecisionMakerTitles,
  (state, action) => action.payload,
  []
);

const decisionMakerPositions = handleAction(
  actions.gotDecisionMakerPositions,
  (state, action) => action.payload,
  []
);

const decisionMakers = handleAction(
  actions.gotDecisionMakers,
  (state, action) => ({
    ...state, 
    [action.payload.source]: action.payload.list
  }), {}
);

const decisionMakerCounts = handleActions({
  [actions.setDecisionMakerCounts]: (state, action) => ({...state, ...action.payload}),
  [actions.resetDecisionMakerCounts]: (state, action) => ({})
}, {});

const modifiedDecisionMaker = handleAction(
  actions.modifiedDecisionMaker,
  (state, action) => action.payload, 
  {}
);

const decisionMakerStatistics = handleAction(
  actions.gotDecisionMakerStatistics,
  (state, action) => action.payload, 
  {total:{count: 0, businessIds: 0}}
);

const requestState = handleAction(
  actions.setRequestState,
  (state, action) => ({
    ...state, 
    ...action.payload
  }), {
    getContacts: false
  }
)

export default combineReducers({
  contact,
  contactCard,
  contactStatuses,
  count,
  postalAreas,
  postedContact,
  contactsForRemoval,
  contactsForReassignment,
  bundlePatched,
  decisionMakerTitles,
  decisionMakerPositions,
  decisionMakers,
  decisionMakerCounts,
  modifiedDecisionMaker,
  decisionMakerStatistics,
  requestState
});
