import { createAction } from 'redux-actions';

export const deleteEvent = createAction('DELETE_EVENT');

export const getEvent = createAction('GET_EVENT');
export const setEvent = createAction('SET_EVENT');
export const gotEvent = createAction('GOT_EVENT');

export const getEvents = createAction('GET_EVENTS');
export const gotEvents = createAction('GOT_EVENTS');

export const gotBgEvents = createAction('GET_BG_EVENTS');

export const patchEvent = createAction('PATCH_EVENT');
export const postEvent = createAction('POST_EVENT');

export const deleteEventRecurrence = createAction('DELETE_EVENT_RECURRENCE');
export const patchEventRecurrence = createAction('PATCH_EVENT_RECURRENCE');
export const postEventRecurrence = createAction('POST_EVENT_RECURRENCE');

export const setCurrentDay = createAction('SET_CURRENT_DAY');
