import partial from 'lodash/fp/partial';
import { compose } from 'redux';

import * as actions from './redux/broadcast-channel/actions';

const createBroadcastChannelMiddleware = () => {

  let broadcastChannel;

  const initialize = ({ dispatch }) => {
    if ( window.BroadcastChannel ) {
      const dispatchAction = partial(compose, [dispatch]);
      broadcastChannel = new BroadcastChannel('bookerskalenteri')
      broadcastChannel.onmessage = dispatchAction(actions.message);
    }
  };

  return store => next => action => {
    switch (action.type) {
      case actions.initialize().type:
        initialize(store)
        next(action)
        break

      case actions.message().type:
        store.dispatch(action.payload.data)
        next(action)
        break

      case actions.postMessage().type:
        if ( broadcastChannel ) {
          broadcastChannel.postMessage(action.payload)
        }
        next(action);
        break;

      default:
        next(action);
    }
  };
};

export default createBroadcastChannelMiddleware();
