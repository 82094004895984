import { combineReducers } from 'redux';
import { handleActions, handleAction } from 'redux-actions';

import * as actions from './actions';

const result = handleActions(
  {
    [actions.clearSearchResults]: () => [],
    [actions.gotSearchResults]: (state, action) => action.payload || state
  },
  []
);

/*
  true: in progress
*/
const state = handleAction(
  actions.setSearchState,
  (state, action) => action.payload,
  false
);


export default combineReducers({
  result,
  state
});
