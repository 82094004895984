import moment from 'moment';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import { ALL_SELECTED } from '../../consts';
import * as statisticActions from '../../redux/statistics/actions';

moment.locale('fi');

export class WeekBar extends Component {

  constructor(props){
    super(props)
    this.getRepresentativeStatistics(props)
  }

  getRepresentativeStatistics(props) {
    if ( props.representative != null && 
        props.representative.id != null && 
        props.representative.id !== ALL_SELECTED && 
        Object.keys(props.suggestions || {}).length ) {
      const suggestionKeys = Object.keys(props.suggestions)
      this.props.getRepresentativeStatistics(
        props.representative.id,
        suggestionKeys[props.firstIndex],
        suggestionKeys[props.lastIndex] ||
          suggestionKeys[suggestionKeys.length - 1],
        props.projectId
      )
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.suggestions !== nextProps.suggestions || 
        this.props.firstIndex !== nextProps.firstIndex || 
        this.props.lastIndex !== nextProps.lastIndex ) {
      this.getRepresentativeStatistics(nextProps)
    }
  }

  isFirstDayOfWeek(i, key, suggestions) {
    return i === this.props.firstIndex 
      || moment(key).weekday() <= moment(Object.keys(suggestions)[ i - 1 ]).weekday()
  }

  getNumberOfBookings(date) {
    const o = this.props.statistics.n.find(o => o.week === moment(date).week())
    return o ? o.count : 0
  }

  render() {
    return (
        <div className="weekbar days">
            {Object.entries(this.props.suggestions).map(
                ([key, values], i) =>
                i >= this.props.firstIndex &&
                i <= this.props.lastIndex && (
                    <div className="day" style={{ 
                    borderLeft: this.isFirstDayOfWeek(i, key, this.props.suggestions) 
                        ? '1px solid #d6d6d6' 
                        : 'none'
                    }} key={i}>
                    { this.isFirstDayOfWeek(i, key, this.props.suggestions) && 
                        <span>
                        { `${this.props.t('week')} ${ moment(key).week() }` }
                        { this.props.representative && `: ${this.getNumberOfBookings(key)} / ${this.props.statistics.maxBookingCount}` }
                        </span> 
                    }
                    </div>
                )
            )}
        </div>
    );
  }
}

const mapStateToProps = state => ({
    projectId: (
      state.contacts.contact && state.contacts.contact.projectId
        ? state.contacts.contact.projectId 
        : state.call.selectedProject.id
      ),
    statistics: state.statistics.representativeStatistics 
      ? state.statistics.representativeStatistics 
      : {n: [{week: 0, count: 0}, {week: 1, count: 0}, {week: 2, count: 0}], maxBookingCount: 1}
    });  

const mapDispatchToProps = dispatch => ({
    getRepresentativeStatistics: (representativeId, date, endDate, projectId) => {
      dispatch(
        statisticActions.getRepresentativeStatistics({
          representativeId, date, endDate, projectId,
          cancelToken: 'WeekBar_getRepresentativeStatistics',
        })
      )
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(
    translate('translations')(WeekBar)
  );