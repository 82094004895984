import { createAction } from 'redux-actions';

export const getClients = createAction('GET_CLIENTS');
export const gotClients = createAction('GOT_CLIENTS');
export const setClientsLoading = createAction('SET_CLIENTS_LOADING');

export const getClient = createAction('GET_CLIENT');
export const gotClient = createAction('GOT_CLIENT');

export const patchClient = createAction('PATCH_CLIENT');
export const patchedClient = createAction('PATCHED_CLIENT');

export const postClient = createAction('POST_CLIENT');
export const postedClient = createAction('POSTED_CLIENT');

export const getRepresentatives = createAction('GET_CLIENT_REPRESENTATIVES');
export const gotRepresentatives = createAction('GOT_CLIENT_REPRESENTATIVES');
export const setRepresentativesLoading = createAction('SET_CLIENT_REPRESENTATIVES_LOADING');

export const getProjects = createAction('GET_CLIENT_PROJECTS');
export const gotProjects = createAction('GOT_CLIENT_PROJECTS');
export const setProjectsLoading = createAction('SET_CLIENT_PROJECTS_LOADING');

export const postProject = createAction('POST_PROJECT');
export const postedProject = createAction('POSTED_PROJECT');

export const postRepresentative = createAction('POST_CLIENT_REPRESENTATIVE');
export const postedRepresentative = createAction('POSTED_CLIENT_REPRESENTATIVE');

export const postClientAdmin = createAction('POST_CLIENT_ADMIN');
export const postedClientAdmin = createAction('POSTED_CLIENT_ADMIN');

export const getClientAdmins = createAction('GET_CLIENT_ADMINS');
export const gotClientAdmins = createAction('GOT_CLIENT_ADMINS');
export const setClientAdminsLoading = createAction('SET_CLIENT_ADMINS_LOADING');

export const getSubAreas = createAction('GET_SUB_AREAS');
export const gotSubAreas = createAction('GOT_SUB_AREAS');

export const attachOneDrive = createAction('ATTACH_ONEDRIVE');
export const detachOneDrive = createAction('DETACH_ONEDRIVE');

export const getClientClasses = createAction('GET_CLIENT_CLASSES');
export const gotClientClasses = createAction('GOT_CLIENT_CLASSES');