import { createAction } from 'redux-actions';

export const login = createAction('LOGIN');
export const tokenLogin = createAction('TOKEN_LOGIN');
export const loginInit = createAction('LOGIN_INIT');
export const loggedIn = createAction('LOGGED_IN');
export const logout = createAction('LOGOUT');
export const loggedOut = createAction('LOGGED_OUT');
export const register = createAction('REGISTER');
export const registered = createAction('REGISTERED');
export const clearState = createAction('CLEAR_STATE');
export const loginInitContinue = createAction('LOGIN_INIT_CONTINUE');
export const loginRepresentativeBooking = createAction('LOGIN_REPRESENTATIVE_BOOKING');

export const loginUnauthorized = createAction('LOGIN_UNAUTHORIZED');

export const acceptTermsOfUse = createAction('ACCEPT_TERMS_OF_USE');
export const acceptedTermsOfUse = createAction('ACCEPTED_TERMS_OF_USE');

export const verifyMfaCode = createAction('VERIFY_MFA_CODE');
export const sendNewMfaCode = createAction('SEND_NEW_MFA_CODE');

export const setAdminBgprocRead = createAction('SET_ADMIN_BGPROC_READ');
export const setAdminBgprocTypes = createAction('SET_ADMIN_BGPROC_TYPES');