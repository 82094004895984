import { createAction } from 'redux-actions';

export const getAdmins = createAction('GET_ADMINS');
export const gotAdmins = createAction('GOT_ADMINS');
export const setAdminsLoading = createAction('SET_ADMINS_LOADING');

export const getAdmin = createAction('GET_ADMIN');
export const gotAdmin = createAction('GOT_ADMIN');

export const patchAdmin = createAction('PATCH_ADMIN');
export const patchedAdmin = createAction('PATCHED_ADMIN');

export const postAdmin = createAction('POST_ADMIN');
export const postedAdmin = createAction('POSTED_ADMIN');