import { combineReducers } from 'redux';
import * as actions from './actions';
import { handleAction } from 'redux-actions';
  
const importedBusinesses = handleAction(
    actions.importedBusinesses, 
    (state, action) => action.payload,
    {count: 0}
  );

const removedBusinesses = handleAction(
    actions.removedBusinesses, 
    (state, action) => action.payload,
    {count: 0}
  );

const values = handleAction(
    actions.gotValues, 
    (state, action) => action.payload,
    {}
  );

const queries = handleAction(
    actions.gotQueries, 
    (state, action) => action.payload,
    []
  );

const savedQuery = handleAction(
  actions.savedQuery,
  (state, action) => action.payload,
  {}
)

const businessLines = handleAction(
  actions.gotBusinessLines,
  (state, action) => action.payload,
  []
)

const singleRequest = handleAction(
  actions.gotSingleRequest,
  (state, action) => action.payload,
  null
)

const singleRequestError = handleAction(
  actions.setSingleRequestError,
  (state, action) => action.payload,
  null
)

const requestState = handleAction(
  actions.setRequestState,
  (state, action) => ({
    ...state, 
    ...action.payload
  }), {
    values: false,
    import: false,
    delete: false,
    singleRequest: false
  }
)

export default combineReducers({
  importedBusinesses,
  removedBusinesses,
  values,
  queries,
  savedQuery,
  businessLines,
  requestState,
  singleRequest,
  singleRequestError
})