import { combineReducers } from 'redux';
import { handleActions, handleAction } from 'redux-actions';

import * as actions from './actions';

const lastCancelledOrDeleted = handleActions(
  {
    [actions.cancelledBooking]: (state, action) => action.payload,
    [actions.deletedBooking]: (state, action) => action.payload
  },
  {}
);

const booking = handleActions(
  {
    [actions.gotBooking]: (state, action) => action.payload,
    [actions.resetBooking]: () => null
  },
  {}
);

const followUp = handleActions(
  {
    [actions.saveFollowUpInfo]: (state, action) => action.payload
  },
  {}
);

const moving = handleActions(
  {
    [actions.saveMoveInfo]: (state, action) => action.payload
  },
  {}
);

const reservation = handleActions(
  {
    [actions.releasedReservedBooking]: () => null,
    [actions.reservedBooking]: (state, action) => action.payload,
    [actions.resetReservation]: () => null,
    [actions.savedBooking]: () => null
  },
  null
);

const statuses = handleAction(
  actions.gotBookingStatuses,
  (state, action) => action.payload || state,
  []
);

const patchedConfirmation = handleAction(
  actions.setPatchedConfirmation,
  (state, action) => action.payload || state,
  false
);

const bookings = handleAction(
  actions.gotBookings,
  (state, action) => action.payload || state,
  []
);

const bookingsLoading = handleAction(
  actions.setBookingsLoading,
  (state, action) => action.payload,
  false
);

const sentSMS = handleAction(
  actions.sentSMS,
  (state, action) => action.payload,
  {}
);

const disableNext = handleActions(
  {
    [actions.disableNext]: () => true,
    [actions.enableNext]: () => false
  },
  false
);

export default combineReducers({
  booking,
  bookings,
  followUp,
  moving,
  reservation,
  statuses,
  lastCancelledOrDeleted,
  sentSMS,
  patchedConfirmation,
  bookingsLoading,
  disableNext
});
