import { createAction } from 'redux-actions';

export const getBlocklistCount = createAction('GET_BLOCKLIST_COUNT');
export const gotBlocklistCount = createAction('GOT_BLOCKLIST_COUNT');

export const importBlocklist = createAction('IMPORT_BLOCKLIST');
export const importedBlocklist = createAction('IMPORTED_BLOCKLIST');

export const exportBlocklist = createAction('EXPORT_BLOCKLIST');

export const removeBlocklist = createAction('REMOVE_BLOCKLIST');
export const removedBlocklist = createAction('REMOVED_BLOCKLIST');