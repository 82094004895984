import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, } from '@fortawesome/free-solid-svg-icons';

import SokLogo from '../../assets/images/S_logo.min.svg'

const ContactHeaderName = ({ contact, showBusinessId, showLinkToContactPage }) => {
  return (
    <>
      <div style={{ display: 'flex', flexFlow: 'row', padding: 0, alignItems: 'center' }}>
          { (contact.contactBusinessType || {}).knownType === 'sok' &&
              <div style={{ display: 'inline-block', marginRight: 10, flex: 0, padding: 0 }}>
                  <img src={SokLogo} alt="SOK" style={{
                    width: 25,
                    height: 25,
                    borderRadius: '50%'
                  }}/>
              </div>
          }
          <div className="name" style={{ flex: 1, border: 0, padding: '5px 0 5px 0' }}>{contact.name}</div>
      </div>
      { !showBusinessId &&
        <div style={{ padding: 0, lineHeight: '10px', justifyContent: 'flex-start' }}>
            {contact.campaign?.name}
            {contact.originalRepresentative && ` (${contact.originalRepresentative.user.firstName} ${contact.originalRepresentative.user.lastName})`}
        </div>
      }
      { showBusinessId &&
        <div style={{ padding: 0, lineHeight: '10px', justifyContent: 'flex-start' }}>
            <span style={{ fontWeight: 'bold' }}>{contact.businessId}</span>
            {contact.originalRepresentative && ` (${contact.originalRepresentative.user.firstName} ${contact.originalRepresentative.user.lastName}, ${contact.campaign?.name || '-'})`}
        </div>
      }
    </>
  )
}

const mapStateToProps = state => ({
  contact: state.contacts.contact
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(ContactHeaderName)
);
