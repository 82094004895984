import { routerReducer } from 'react-router-redux';

import bookingReducer from './redux/booking/reducers'
import busyReducer from './redux/busy/reducers'
import callListsReducer from './redux/call-lists/reducers'
import callReducer from './redux/call/reducers'
import contactsReducer from './redux/contacts/reducers'
import errorHandlingReducer from './redux/error-handling/reducers'
import eventsReducer from './redux/events/reducers'
import projectsReducer from './redux/projects/reducers'
import reportsReducer from './redux/reports/reducers'
import searchReducer from './redux/search/reducers'
import servicePointsReducer from './redux/service-points/reducers'
import socketReducer from './redux/socket/reducers'
import statisticsReducer from './redux/statistics/reducers'
import userReducer from './redux/user/reducers'
import worktimeReducer from './redux/worktime/reducers'
import templatesReducer from './redux/templates/reducers'
import campaignsReducer from './redux/campaigns/reducers'
import clientsReducer from './redux/clients/reducers'
import bookersReducer from './redux/bookers/reducers'
import subAreasReducer from './redux/sub-areas/reducers'
import representativesReducer from './redux/representatives/reducers'
import calendarsReducer from './redux/calendars/reducers'
import resetPasswordReducer from './redux/reset-password/reducers'
import clientAdminsReducer from './redux/client-admins/reducers'
import adminsReducer from './redux/admins/reducers'
import blocklistReducer from './redux/blocklist/reducers'
import loginRedirectReducer from './redux/loginRedirect/reducers'
import uploadProgressReducer from './redux/uploadProgress/reducers'
import customUserInterfacesReducer from './redux/custom-user-interfaces/reducers'
import modalNotificationReducer from './redux/modal-notification/reducers'
import backgroundProcessLogReducer from './redux/background-process-log/reducers'
import businessDbReducer from './redux/business-db/reducers'
import apiAccessReducer from './redux/api-access/reducers'
import notificationReducer from './redux/notification/reducers'
import historyReducer from './redux/history/reducers'
import adminPanelReducer from './redux/admin-panel/reducers'

export const reducers = {
  booking: bookingReducer,
  busy: busyReducer,
  call: callReducer,
  callLists: callListsReducer,
  contacts: contactsReducer,
  error: errorHandlingReducer,
  events: eventsReducer,
  projects: projectsReducer,
  reports: reportsReducer,
  router: routerReducer,
  searchResults: searchReducer,
  servicePoints: servicePointsReducer,
  socket: socketReducer,
  statistics: statisticsReducer,
  templates: templatesReducer,
  user: userReducer,
  worktime: worktimeReducer,
  campaigns: campaignsReducer,
  clients: clientsReducer,
  bookers: bookersReducer,
  subAreas: subAreasReducer,
  representatives: representativesReducer,
  resetPassword: resetPasswordReducer,
  calendars: calendarsReducer,
  clientAdmins: clientAdminsReducer,
  admins: adminsReducer,
  blocklist: blocklistReducer,
  loginRedirect: loginRedirectReducer,
  uploadProgress: uploadProgressReducer,
  customUserInterfaces: customUserInterfacesReducer,
  modalNotification: modalNotificationReducer,
  backgroundProcessLog: backgroundProcessLogReducer,
  businessDb: businessDbReducer,
  apiAccess: apiAccessReducer,
  notification: notificationReducer,
  history: historyReducer,
  adminPanel: adminPanelReducer
};
