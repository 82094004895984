import React, { Component } from 'react';
import {
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as bookingActions from '../../redux/booking/actions';

export class BookingStatus extends Component {

    constructor(props) {
        super(props)
        if ( props.booking && props.booking.id != null 
            && (props.representativeId == null || props.representativeId === props.booking.representativeId) ) {
            props.getBookingStatuses(props.booking.id);
          }      
    }

    render() {

        const {
            booking,
            statuses
          } = this.props;

        return (
        <DropdownButton
            id="dropdown-button"
            noCaret
            onSelect={eventKey => {
                this.handleChangeStatus(eventKey);
            }}
            disabled={
              this.props.disabled || 
                (!this.getSelectedStatusRepresentativeCanChange() 
                && !['admin', 'teamLeader'].includes(this.props.user.type)) 
            }
            title={this.getSelectedStatusName() || ''}
        >
            {statuses.length > 0 &&
            statuses.filter(status => status.userSelectable)
              .filter(status => !booking || !booking.parentId || status.availableForFollowUp)
              .filter(status => !this.props.filter || this.props.filter(status))
              .map(status => (
                <MenuItem
                    active={booking ? (this.hasGeneratedFollowUp() 
                      ? status.superType === 'dealProposed' 
                      : status.id === booking.statusId) : false}
                    eventKey={status.id}
                    key={status.id}
                >
                    {status.name}
                </MenuItem>
            ))}
        </DropdownButton>
        )
    }

    UNSAFE_componentWillReceiveProps = nextProps => {
        // Update available statuses if booking or its status changes
        if (
          ( this.props.booking == null && nextProps.booking != null ) ||
          (nextProps.booking != null &&
            (nextProps.booking.id !== this.props.booking.id ||
              nextProps.booking.statusId !== this.props.booking.statusId))
        ) {
          this.props.getBookingStatuses(nextProps.booking.id);
        }
    }

    getSelectedStatusName = () => {
        let selectedStatus = undefined;
    
        if ( !this.props.booking ) return ''
        if (
          this.props.statuses !== undefined &&
          this.props.statuses.length > 0 &&
          this.props.booking.statusId !== undefined
        ) {
          selectedStatus = this.props.statuses.find(
            status => this.hasGeneratedFollowUp() 
              ? status.superType === 'dealProposed' 
              : status.id === this.props.booking.statusId
          );
    
          if (selectedStatus !== undefined) {
            return selectedStatus.name;
          }
          return '';
        }
    }

    getSelectedStatusRepresentativeCanChange = () => {
      let selectedStatus = undefined;

      if ( !this.props.booking ) return false
  
      if (
        this.props.statuses !== undefined &&
        this.props.statuses.length > 0 &&
        this.props.booking.statusId !== undefined
      ) {
        selectedStatus = this.props.statuses.find(
          status => status.id === this.props.booking.statusId
        );
  
        if (selectedStatus !== undefined) {
          return selectedStatus.representativeCanChange
        }
        return true;
      }
  }

  hasGeneratedFollowUp() {
    return this.props.booking.followUp 
      && this.props.booking.startsAt === this.props.booking.followUp.startsAt
      && this.props.booking.endsAt === this.props.booking.followUp.endsAt
  }

  handleChangeStatus = statusId => {
      this.props.setBookingStatus(
        this.props.booking.id, 
        statusId,
        this.hasGeneratedFollowUp()
      );
  };

}

const mapDispatchToProps = dispatch => ({
    setBookingStatus: (bookingId, statusId, getBooking) => {
      dispatch(bookingActions.setBookingStatus({ bookingId, statusId, getBooking }));
    },
    getBookingStatuses: bookingId => {
        dispatch(bookingActions.getBookingStatuses({ bookingId, all: true }));
    }
  });

const mapStateToProps = state => ({
    booking: state.booking.booking,
    statuses: state.booking.statuses,
    user: state.user
  });

  
export default connect(mapStateToProps, mapDispatchToProps)(
    translate('translations')(BookingStatus)
  );
  