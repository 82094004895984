import { handleActions } from 'redux-actions';

import * as actions from './actions';

const createError = ({ message }) => ({
  message,
  name: message.split(/\(|\)/).reverse()[1],
  timestamp: Math.floor(Date.now() / 1000)
});

export default handleActions(
  {
    [actions.clearError]: () => {},
    [actions.requestFailed]: (state, action) =>
      createError({ message: action.payload.message || action.payload }) || state
  },
  {}
);
